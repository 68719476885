import * as React from "react";

import { Checklist } from "@bokio/designsystem/components/Checklist/Checklist";

export type PromotionModalChecklistItem = React.ReactNode | string;
export interface PromotionModalChecklistProps {
	list: PromotionModalChecklistItem[];
}

export const PromotionModalChecklist: React.FC<PromotionModalChecklistProps> = props => {
	return props.list?.length ? <Checklist list={props.list} /> : null;
};
