import * as React from "react";

import { Box } from "../Box";
import LoadingBox from "./LoadingBox";
import RenderRequestError from "./RenderRequestError";

import type { LoadingProps } from "./LoadingProps";

export const LoadingPage = ({ request, requests, children, hideSoftErrors }: LoadingProps) => {
	return (
		<RenderRequestError request={request} requests={requests} showWhenLoaded={children} hideSoftErrors={hideSoftErrors}>
			<Box>
				<LoadingBox toolbar spacingTop />
			</Box>
		</RenderRequestError>
	);
};
