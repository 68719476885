import * as React from "react";

import { mergeClassNames } from "@bokio/utils/classes";

import * as styles from "./formGroup.scss";

interface FormGroupProps {
	children: React.ReactNode;
	className?: string;
	checkBoxOrRadio?: boolean;
	align?: "center";
	hidden?: boolean;
	maxWidth?: boolean;
	noMargin?: boolean;
}

export const FormGroup = ({
	children,
	className,
	checkBoxOrRadio,
	align,
	hidden,
	maxWidth,
	noMargin,
}: FormGroupProps) => (
	<div
		className={mergeClassNames(
			className,
			styles.formGroup,
			checkBoxOrRadio && styles.checkBoxOrRadio,
			align && styles[align],
			hidden && styles.hidden,
			maxWidth && styles.maxWidth,
			noMargin && styles.noMargin,
		)}
	>
		{children}
	</div>
);
