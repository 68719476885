import type { Action } from "../types";

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace Todo {
	export const TODO_ACTIVITY = "TODO_ACTIVITY";

	export function updateTodoCacheBust(): Action {
		return {
			type: TODO_ACTIVITY,
		};
	}
}
