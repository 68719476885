import * as React from "react";

import { IdleTimer } from "@bokio/elements/IdleTimer/IdleTimer";
import * as proxy from "@bokio/mobile-web-shared/services/api/proxy";
import { useShowLogin } from "@bokio/shared/containers/layout/withShowLogin";
import { pageUpdaterNotifier } from "@bokio/utils/pageUpdaterNotifier";

import LoginModal from "./LoginModal";

export const AuthenticationChecker: React.FC = ({ children }) => {
	const [idleLoggedOut, setIdleLoggedOut] = React.useState(false);
	const { showLogin, clearShowLogin, doShowLogin } = useShowLogin();

	const handleShowLogin = (idle: boolean) => {
		setIdleLoggedOut(idle);
		doShowLogin();
	};

	const checkAuthentication = async () => {
		const response = await proxy.Bokio.AccountController.IsAuthenticated.Get();
		if (response.Success && response.Data) {
			showLogin && clearShowLogin();
		} else {
			handleShowLogin(true);
		}
	};

	const handleLoginSuccess = async () => {
		// Deep reload the react tree
		await clearShowLogin();
		await pageUpdaterNotifier.notify();
	};

	return (
		<>
			<IdleTimer
				onActive={checkAuthentication} // When user becomes active again, check if authenticated
				timeout={1000 * 60} // User has to be idle for 60 sek
			/>
			{children}

			<LoginModal visible={showLogin} idleLoggedOut={idleLoggedOut} handleLoginSuccess={handleLoginSuccess} />
		</>
	);
};
