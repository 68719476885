import { Switch } from "react-router-dom";
import type { RouteComponentProps, StaticContext } from "react-router";
import { Redirect } from "react-router";
import * as React from "react";

import GuestRoute from "@bokio/components/Route/GuestRoute";
import { LookForDeepLink } from "@bokio/components/LookForDeepLink/LookForDeepLink";
import GenericNotFound from "@bokio/components/GenericNotFound/GenericNotFound";
import ErrorBoundary from "@bokio/components/ErrorBoundary/ErrorBoundary";

import { Scenes } from "./Scenes";

type LocationState = {
  // SS 2022-01-10
  // I couldn't find where is this used so the type is probably wrong in some way.
  error?: unknown;
  notFound?: boolean;
};

type RouteProps = RouteComponentProps<
    Record<string, string | undefined>,
    StaticContext,
    LocationState
  >;

class Routes extends React.Component<RouteProps> {
  previousLocation = this.props.location;

  componentDidUpdate(prevProps: RouteProps) {
    const { location } = this.props;

    if (prevProps.location && location && prevProps.location.pathname !== location.pathname) {
      this.forceUpdate();
    }

    if (prevProps.history.action !== "POP" && (!location.state || !location.state.error)) {
      this.previousLocation = location;
    }
  }

  render() {
    const { location } = this.props;
    const isError = !!(location.state && location.state.notFound && this.previousLocation !== location); // not initial render
    if (isError) {
      return <GuestRoute component={GenericNotFound} />;
    }

    const notFoundState: LocationState = { notFound: true };
    return (
      <ErrorBoundary source="Route">
        <Switch>
          {Scenes().props.children}
          <LookForDeepLink location={location} notFound={<Redirect to={{ state: notFoundState }} />} />
        </Switch>
      </ErrorBoundary>
    );
  }
}

export default Routes;
