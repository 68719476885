import * as React from "react";

import { BbaMigrationNotice } from "@bokio/components/BbaMigrationEntrypoint/BbaMigrationNotice";
import { Modal, ModalContent, ModalFooter } from "@bokio/components/Modal";
import { useCashbackContext } from "@bokio/contexts/CashbackContext/CashbackContext";
import { Button } from "@bokio/designsystem/components/Button";
import { SG } from "@bokio/designsystem/components/SpacingGroup/SpacingGroup";
import Markdown from "@bokio/elements/Markdown/Markdown";
import { BankLangFactory, GeneralLangFactory } from "@bokio/lang";
import { useCompanyInfo } from "@bokio/mobile-web-shared/core/contexts/CompanyInfoContext/CompanyInfoContext";
import { getRoute } from "@bokio/shared/route";
import { formatMessage } from "@bokio/shared/utils/format";

interface StartBbaMigrationBefore20230901ModalProps {
	resolve: () => void;
}

export const StartBbaMigrationBefore20230901Modal: React.FC<StartBbaMigrationBefore20230901ModalProps> = ({
	resolve,
}) => {
	const { companyInfo } = useCompanyInfo();
	const generalLang = GeneralLangFactory();
	const bankLang = BankLangFactory();
	const { defaultContract } = useCashbackContext();

	return (
		<Modal visible onClose={resolve} title={bankLang.StartBbaMigrationBefore20230901Modal_Title}>
			<ModalContent>
				<SG gap="16">
					<BbaMigrationNotice variant="supportMessageModal" />
					<Markdown
						useTypographicElements={{ compact: true }}
						markdownContent={
							bankLang.BokioBusinessAccountMigrationBlock_Description_Paragraph +
							"\n\n" +
							formatMessage(
								bankLang.BokioBusinessAccountMigrationBlock_Description_Benefits,
								defaultContract?.CardSpendCashbackPercentage ?? 0,
								defaultContract?.DepositedCapitalCashbackPercentage ?? 0,
							)
						}
					/>
				</SG>
			</ModalContent>
			<ModalFooter>
				<Button type="link" route={getRoute("bokioBusinessAccountMigration", { company: companyInfo.Id })}>
					{generalLang.GetStarted}
				</Button>
			</ModalFooter>
		</Modal>
	);
};
