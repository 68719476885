import * as React from "react";

import unsignedImg from "@bokio/assets/images/backoffice/unsigned.svg";
import RenderRequest from "@bokio/components/RenderRequest/RenderRequest";
import { Button } from "@bokio/designsystem/components/Button";
import { SG } from "@bokio/designsystem/components/SpacingGroup/SpacingGroup";
import { Heading, Section } from "@bokio/designsystem/components/TypographicElements/TypographicElements";
import { Badge } from "@bokio/elements/Badge/Badge";
import { Link } from "@bokio/elements/Link/Link";
import { LoadingContent } from "@bokio/elements/Loading";
import { BackOfficeLangFactory, GeneralLangFactory } from "@bokio/lang";
import * as m from "@bokio/mobile-web-shared/core/model/model";
import { useApi } from "@bokio/mobile-web-shared/hooks/useApi/useApi";
import * as proxy from "@bokio/mobile-web-shared/services/api/proxy";
import { getRoute } from "@bokio/shared/route";

import { getTiersInfo } from "./loyaltyProgramUtils";

import * as styles from "./MenuLoyaltyProgram.scss";

import ContractStatus = m.Entities.ContractStatus;
interface LoyaltyProgramContentProps {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	agencyStatus: any;
}

export const LoyaltyProgramContent: React.FC<LoyaltyProgramContentProps> = ({ agencyStatus }) => {
	const generalLang = GeneralLangFactory();
	const lang = BackOfficeLangFactory();
	const [tierInfo] = useApi(proxy.BackOffice.AgencyController.GetTiersInfo.Get, [agencyStatus?.Id]);

	const isSigned = agencyStatus.ContractStatus === ContractStatus.Signed;

	return (
		<RenderRequest request={tierInfo} whenLoading={<LoadingContent lines={2} padding />}>
			{tiersNumber => {
				const partnerProgramDetails = (
					<SG gap="8">
						<Badge color={isSigned ? "active" : "activeDark"} name={generalLang.LoyaltyProgram_LoyaltyProgram} />
						<Section>
							<Heading>
								{isSigned ? getTiersInfo(tiersNumber, generalLang)?.label : lang.PartnerContent_BecomePartner_Text}
							</Heading>
							{isSigned ? undefined : (
								<Button
									margin={["top", "bottom"]}
									type="link"
									route={getRoute("agencyPartnerInfo", { agencyId: agencyStatus.Id })}
									appearance="secondary"
									testId="LoyaltyProgramContent_Btn"
								>
									{lang.PartnerInfo_Banner_ButtonText}
								</Button>
							)}
						</Section>
					</SG>
				);

				return (
					<div className={isSigned ? styles.signedplan : styles.unsignedplan}>
						<>
							<Link
								style="none"
								testId="LoyaltyProgramContent_Link"
								route={
									!isSigned
										? getRoute("agencyPartnerInfo", { agencyId: agencyStatus.Id })
										: getRoute("agencyPartnerProgram", { agencyId: agencyStatus.Id })
								}
								className={styles.titleAndToggle}
							>
								{isSigned ? (
									<SG gap="16" horizontal>
										{partnerProgramDetails}
										<img className={styles.tierIcon} src={getTiersInfo(tiersNumber, generalLang)?.image} />
									</SG>
								) : (
									<SG gap="space-between" horizontal align="end">
										{partnerProgramDetails}
										<img src={unsignedImg} />
									</SG>
								)}
							</Link>
						</>
					</div>
				);
			}}
		</RenderRequest>
	);
};
