import * as React from "react";

import { useYoutubeApi } from "@bokio/hooks/useYoutubeApi/useYoutubeApi";
import { mergeClassNames } from "@bokio/utils/classes";

export interface VideoProps {
	youtubeEmbedLink: string;
	className?: string;
	onStateChange?: YT.PlayerEventHandler<YT.OnStateChangeEvent>;
}

const Video: React.FunctionComponent<VideoProps> = ({ youtubeEmbedLink, className, onStateChange }) => {
	const { iframeId, iframeSrc } = useYoutubeApi(youtubeEmbedLink, onStateChange);

	return (
		<span className={mergeClassNames("videoWrapper", className)}>
			<iframe id={iframeId} src={iframeSrc} allowFullScreen />
		</span>
	);
};

export default Video;
