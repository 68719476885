import * as React from "react";

import { Modal, ModalContent, ModalFooter } from "@bokio/components/Modal";
import { Button } from "@bokio/designsystem/components/Button/Button";
import { SG } from "@bokio/designsystem/components/SpacingGroup/SpacingGroup";
import Markdown from "@bokio/elements/Markdown/Markdown";
import { GeneralLangFactory, SettingsLangFactory } from "@bokio/lang";
import * as m from "@bokio/mobile-web-shared/core/model/model";
import { getRoute } from "@bokio/shared/route";
import { formatMessage } from "@bokio/shared/utils/format";
import { getPlanDescription, getPlanName } from "@bokio/utils/priceplanUtils";

import BokioPlan = m.Entities.BokioPlan;

interface UpgradePricePlanModalProps {
	companyId: string;
	upgradeToPlan: m.Entities.BokioPlan;
	visible: boolean;
	onClose: () => void;
	upgradeNudgeOverride?: string;
	planDescriptionOverride?: string;
}

export const UpgradePricePlanModal = ({
	upgradeToPlan,
	visible,
	onClose,
	upgradeNudgeOverride,
	planDescriptionOverride,
	companyId,
}: UpgradePricePlanModalProps) => {
	const generalLang = GeneralLangFactory();
	const settingsLang = SettingsLangFactory();

	const defaultUpgradeNudge = settingsLang.Plans_UpgradeModal_YouNeedToUpgrade;

	const defaultPlanDescription = getPlanDescription(upgradeToPlan);

	const getPlanFeaturesText = (plan: BokioPlan) => {
		switch (plan) {
			case BokioPlan.FreeShared: // This shouldn't happen bug we need to keep it for backwards compatibility
			case BokioPlan.Free:
			case BokioPlan.Beta:
			case BokioPlan.Balance:
			case BokioPlan.Business:
			case BokioPlan.Invalid:
				return "";
			case BokioPlan.Premium:
				return settingsLang.Plans_UpgradeModal_Premium_Features;
			case BokioPlan.Pro:
				return settingsLang.Plans_UpgradeModal_Pro_Features;
		}
	};
	const planFeatures = getPlanFeaturesText(upgradeToPlan);

	return (
		<Modal
			onClose={onClose}
			title={`${
				upgradeToPlan === BokioPlan.Premium
					? formatMessage(
							generalLang.UpgradeToX,
							`${getPlanName(BokioPlan.Premium)} ${generalLang._OR} ${getPlanName(BokioPlan.Pro)}`,
						)
					: formatMessage(generalLang.UpgradeToX, getPlanName(upgradeToPlan))
			}`}
			visible={visible}
		>
			<ModalContent>
				<SG section gap="16">
					<Markdown
						useTypographicElements
						markdownContent={`${upgradeNudgeOverride ?? defaultUpgradeNudge}\n\n${
							planDescriptionOverride ?? defaultPlanDescription
						}`}
					/>
					<SG gap="12" horizontalInDesktop>
						<Markdown useTypographicElements={{ compact: true }} markdownContent={planFeatures} />
					</SG>
					{upgradeToPlan == BokioPlan.Premium ? (
						<SG gap="12">
							<Markdown
								useTypographicElements={{ compact: true }}
								markdownContent={getPlanFeaturesText(BokioPlan.Pro)}
							/>
							<Markdown useTypographicElements markdownContent={getPlanDescription(BokioPlan.Pro)} />
						</SG>
					) : (
						""
					)}
				</SG>
			</ModalContent>
			<ModalFooter>
				<Button appearance="secondary" onClick={onClose}>
					{generalLang.Cancel}
				</Button>
				<Button type="link" route={getRoute("billing", { company: companyId })}>
					{generalLang.PricePlan_Upgrade}
				</Button>
			</ModalFooter>
		</Modal>
	);
};
