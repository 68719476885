import { connect as reduxConnect } from "react-redux";
import { createSelector } from "reselect";

import { useRedux } from "@bokio/hooks/useRedux/useRedux";
import { languageNotifier } from "@bokio/lang/languageNotifier";
import * as proxy from "@bokio/mobile-web-shared/services/api/proxy";
import ApiRequest from "@bokio/shared/services/request/ApiRequest";
import { getCurrentCompanyId } from "@bokio/shared/state/router/selectors";

import type * as m from "@bokio/mobile-web-shared/core/model/model";
import type { Infer } from "@bokio/shared/containers/connect";
import type { ApiRequestState } from "@bokio/shared/services/request/ApiRequestState";
import type { State } from "@bokio/shared/state/state";

type CompanyUser = m.Settings.Controllers.StatusDto;
type UserCompany = m.Core.CompanyInfo;

export type GetCompanyUserState = ApiRequestState<CompanyUser, string>;

export interface GetCompanyUserProps {
	company?: UserCompany;
	userStatus?: CompanyUser;
	isLoadingCompany?: boolean;
	companyUserPermissions?: m.Settings.Controllers.UserAccess;
}

export interface GetCompanyUserPropsFromDispatch {
	updateCompanyUserStatus: () => void;
}

export const GetCompanyUser = () => {
	const api = new ApiRequest<CompanyUser, string>("USER/GET_COMPANY_USER", state => state.requests.getCompanyUser);

	const getUserStatus = (state: State) => api.getResults(state);

	const getCompany = (state: State) => {
		const user = api.getResults(state);
		return user ? user.Company : undefined;
	};

	const calculatePermissions = (user?: CompanyUser): m.Settings.Controllers.UserAccess => {
		if (!user) {
			return {
				ViewBookkeeping: false,
				Bookkeep: false,
				UploadReceipt: false,
				UploadBank: false,
				Closures: false,
				Partner: false,
				Reports: false,
				SupportUser: false,
				Attest: false,
				Disbursements: false,
				UploadExpense: false,
				ImportantDates: false,
				Salaries: false,
				Invoices: false,
				AccountingSettings: false,
				InvoiceSettings: false,
				SalarySettings: false,
				CompanySettings: false,
				Inbox: false,
				AdminUsers: false,
				SupplierInvoices: false,
			};
		}

		return user.Access;
	};

	const getPermissions = createSelector((state: State) => api.getResults(state), calculatePermissions);

	const execute = () => {
		// eslint-disable-next-line @typescript-eslint/ban-types
		return (dispatch: Function, getState: () => State) => {
			const state = getState();
			const companyId = getCurrentCompanyId(state);

			if (!companyId) {
				return;
			}

			if (api.wasRequestedRecently(state)) {
				return;
			}

			return api.performRequest(dispatch, companyId, () => {
				const task = proxy.Settings.StatusController.Get.Get(companyId);

				// eslint-disable-next-line promise/catch-or-return, promise/always-return
				task.then(d => {
					/* Ugly hack to solve language switching. See index.tsx for languageNotifier implementation */
					languageNotifier.load(d.UserConfiguration.Lang);
				});

				return task;
			});
		};
	};

	const mapStateToProps = (state: State): GetCompanyUserProps => ({
		company: getCompany(state),
		userStatus: getUserStatus(state),
		isLoadingCompany: api.isLoading(state),
		companyUserPermissions: getPermissions(state),
	});

	// eslint-disable-next-line @typescript-eslint/ban-types
	const mapDispatchToProps = (dispatch: Function): GetCompanyUserPropsFromDispatch => ({
		updateCompanyUserStatus: () => {
			dispatch(execute());
		},
	});

	const connect = () => reduxConnect(mapStateToProps, mapDispatchToProps) as Infer<GetCompanyUserProps>;
	const useProps = () => useRedux(mapStateToProps, mapDispatchToProps, () => ({}));
	return {
		execute,
		connect,
		useProps,
		getReducer: () => api.getReducer(),
		getParameters: (state: State) => api.getParameters(state),
	};
};
