import * as React from "react";

import { useAgencyDetails } from "@bokio/hooks/useAgencyDetails/useAgencyDetails";
import { AgencyUserPreferenceType } from "@bokio/hooks/useUserPreference/useAgencyUserPreference";
import { UserPreferenceType, useUserPreferenceWithoutCompany } from "@bokio/hooks/useUserPreference/useUserPreference";
import { useLazyApi } from "@bokio/mobile-web-shared/hooks/useApi/useApi";
import * as proxy from "@bokio/mobile-web-shared/services/api/proxy";
import { useUmbracoApi } from "@bokio/shared/services/api/UmbracoApi";
import { useUserMemberships } from "@bokio/shared/state/requests";

import { releaseNotesContext } from "./ReleaseNotesContext";

import type { ReleaseNoteType } from "./ReleaseNotesContext";
import type * as m from "@bokio/mobile-web-shared/core/model/model";
import type { UmbracoReleaseNoteModel } from "@bokio/shared/models/UmbracoReleaseNotesModel";

type AgencyUserPreferencesDto = m.Bokio.Common.Contract.Services.UserPreferences.AgencyUserPreferencesDto;

interface whatsNewResult {
	hasNewNotes: boolean;
	shouldSetLastRead: boolean;
	latestWhatsNewDate: Date | undefined;
	noOfUpdates: number;
}

export const getWhatsNewInfo = (
	releaseNotes: UmbracoReleaseNoteModel[],
	lastReadWhatsNewDate: Date | undefined,
): whatsNewResult => {
	const firstPossibleNewsBadgeDate = new Date(2022, 3, 22);
	const newestReleaseNotePublished = () => {
		const date = releaseNotes.length ? new Date(releaseNotes[0].Published) : firstPossibleNewsBadgeDate;
		date.setMilliseconds(0);
		return date;
	};

	// lastRead defaults to firstPossibleNewsBadgeDate if nothing is read.
	const lastRead = lastReadWhatsNewDate ?? firstPossibleNewsBadgeDate;
	const getNoOfUpdates = releaseNotes.filter(r => new Date(r.Published) > lastRead).length;
	const getHasNewNotes = lastRead < newestReleaseNotePublished();
	const latestWhatsNewDateFromApi = releaseNotes.length > 0 ? new Date(releaseNotes[0].Published) : undefined;
	const getShouldSetLastRead = latestWhatsNewDateFromApi ? lastRead < latestWhatsNewDateFromApi : true;
	return {
		noOfUpdates: getNoOfUpdates,
		hasNewNotes: getHasNewNotes,
		shouldSetLastRead: getShouldSetLastRead,
		latestWhatsNewDate: latestWhatsNewDateFromApi,
	};
};

export const ReleaseNotesContextProvider: React.FC = ({ children }) => {
	const [whatsNewDataCompany, setWhatsNewDataCompany] = React.useState<UmbracoReleaseNoteModel[]>([]);
	const [whatsNewDataAgency, setWhatsNewDataAgency] = React.useState<UmbracoReleaseNoteModel[]>([]);

	const [hasNewNotesAgency, setHasNewNotesAgency] = React.useState<boolean>(false);
	const [hasNewNotesCompany, setHasNewNotesCompany] = React.useState<boolean>(false);
	const [noOfUpdatesAgency, setNoOfUpdatesAgency] = React.useState<number>(0);

	const { getWhatsNew } = useUmbracoApi();

	const { agencyId: agencyIdFromHook, isAgency: isAgencyFromHook } = useAgencyDetails();

	// AM 2024-03-21 In select company page there is no agencyId from the hook above.
	// We look at the memberships for the user to get an agencyId.
	// We are interested in getting any one agency connected to the user, not all.
	const { memberships } = useUserMemberships();
	const agencyIdsFromMemberships = memberships?.partners.map(x => x.PartnerId);
	const agencyIdFromMemberships =
		agencyIdsFromMemberships?.length !== undefined && agencyIdsFromMemberships.length > 0
			? agencyIdsFromMemberships[0]
			: undefined;

	const agencyId = agencyIdFromHook ?? agencyIdFromMemberships;
	const isAgency = isAgencyFromHook || agencyIdFromMemberships !== undefined;

	const { setPreference: setPreferenceWithoutCompany, getPreferenceRequest: getPreferenceWithoutCompanyRequest } =
		useUserPreferenceWithoutCompany(UserPreferenceType.LastReadWhatsNewDate);

	const [agencyUserGetPreference, getPreferenceRequest] = useLazyApi(
		proxy.BackOffice.AgencyUserPreferencesController.GetPreference.Get,
		{
			onSuccess: data => {
				const { hasNewNotes, noOfUpdates } = getWhatsNewInfo(
					whatsNewDataAgency,
					data.LastReadWhatsNewDateAgencyPreference?.Value,
				);
				setHasNewNotesAgency(hasNewNotes);
				setNoOfUpdatesAgency(noOfUpdates);
			},
		},
	);

	const getAgencyUserPreferences = React.useCallback(() => {
		agencyId && agencyUserGetPreference(agencyId, AgencyUserPreferenceType.LastReadWhatsNewDateAgency);
	}, [agencyId, agencyUserGetPreference]);

	const [setPreference] = useLazyApi(proxy.BackOffice.AgencyUserPreferencesController.SetPreference.Post, {
		onSuccess: getAgencyUserPreferences,
	});

	const setUserPreference = React.useCallback(async () => {
		const type: ReleaseNoteType = isAgency ? "Agency" : "Company";

		const lastReadDate =
			type === "Agency"
				? getPreferenceRequest.data?.Data?.LastReadWhatsNewDateAgencyPreference?.Value
				: getPreferenceWithoutCompanyRequest.data?.Data?.LastReadWhatsNewDatePreference?.Value;

		const { shouldSetLastRead, latestWhatsNewDate } = getWhatsNewInfo(
			isAgency ? whatsNewDataAgency : whatsNewDataCompany,
			lastReadDate,
		);

		if (!shouldSetLastRead || latestWhatsNewDate === undefined) {
			return;
		}

		if (type === "Agency") {
			const value: AgencyUserPreferencesDto = {
				LastReadWhatsNewDateAgencyPreference: { Value: latestWhatsNewDate },
			};
			await setPreference(agencyId, AgencyUserPreferenceType.LastReadWhatsNewDateAgency, value);
		} else {
			await setPreferenceWithoutCompany({
				value: { LastReadWhatsNewDatePreference: { Value: latestWhatsNewDate } },
			});
		}
	}, [
		isAgency,
		getPreferenceRequest.data?.Data?.LastReadWhatsNewDateAgencyPreference?.Value,
		getPreferenceWithoutCompanyRequest.data?.Data?.LastReadWhatsNewDatePreference?.Value,
		whatsNewDataAgency,
		whatsNewDataCompany,
		setPreference,
		agencyId,
		setPreferenceWithoutCompany,
	]);

	React.useEffect(() => {
		getWhatsNew()
			.then(notes => {
				setWhatsNewDataAgency(notes.ReleaseNotes.filter(r => r.ReleaseCategories.includes("Agency")));
				setWhatsNewDataCompany(notes.ReleaseNotes.filter(r => r.ReleaseCategories.includes("Company")));
				return;
			})
			.catch(() => {
				setWhatsNewDataAgency([]);
				setWhatsNewDataCompany([]);
			});
	}, [getWhatsNew]);

	React.useEffect(() => {
		getAgencyUserPreferences();
	}, [getAgencyUserPreferences, agencyId]);

	React.useEffect(() => {
		const { hasNewNotes } = getWhatsNewInfo(
			whatsNewDataCompany,
			getPreferenceWithoutCompanyRequest.data?.Data?.LastReadWhatsNewDatePreference?.Value,
		);
		setHasNewNotesCompany(hasNewNotes);
	}, [
		getPreferenceWithoutCompanyRequest.data?.Data?.LastReadWhatsNewDatePreference?.Value,
		getPreferenceWithoutCompanyRequest.data?.Data?.LastReadWhatsNewDatePreference,
		whatsNewDataCompany,
	]);

	return (
		<releaseNotesContext.Provider
			value={{
				whatsNewData: isAgency ? whatsNewDataAgency : whatsNewDataCompany,
				hasNewNotesAgency: hasNewNotesAgency,
				hasNewNotesCompany: hasNewNotesCompany,
				noOfUpdates: noOfUpdatesAgency,
				setLastReadPrefernce: setUserPreference,
			}}
		>
			{children}
		</releaseNotesContext.Provider>
	);
};
