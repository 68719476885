import * as React from "react";

import { Link } from "@bokio/elements/Link/Link";
import classes from "@bokio/utils/classes";

import * as styles from "./style.scss";

export interface TableDataProps {
	width?: string;
	center?: boolean;
	colspan?: number;
	type?: "sum" | "description" | "subHeading" | "label" | "hideMobile" | "inline";
	hideMobile?: boolean;
	isCheck?: boolean;
	isArrow?: boolean;
	children?: React.ReactNode;
	route?: string;
	external?: string;
	dataTh?: string | number;
	align?: "right" | "center";
	className?: string;
	testId?: string;
}

const TableData = (props: TableDataProps) => {
	const style = { width: props.width || "auto" };
	let classNames = classes(styles, "table__td", {
		table__td__check: props.isCheck,
		table__td__expand: props.isArrow,
		[`table__td__${props.type}`]: props.type,
		table__td__hideMobile: props.hideMobile || props.type === "hideMobile",
		table__td__link: props.route || props.external,
		[`table__td__${props.align}`]: props.align,
	});

	if (props.className) {
		classNames += " " + props.className;
	}

	return (
		<td data-th={props.dataTh} colSpan={props.colspan} className={classNames} style={style} data-testid={props.testId}>
			{props.route || props.external ? (
				<Link external={props.external} route={props.route} style="none">
					{props.children}
				</Link>
			) : (
				props.children
			)}
		</td>
	);
};

export default TableData;
