import * as React from "react";

import { useHelpContext } from "@bokio/contexts/HelpContext/HelpContext";
import { GeneralLangFactory } from "@bokio/lang";
import { mergeClassNames } from "@bokio/utils/classes";

import { Help } from "../../../Help/Help/Help";
import { HelpWindowMode } from "../../../Help/helpSupportUtils";
import { TopBarDropDownButton } from "../../../TopBar/TopBarDropDownButton";

import * as styles from "./menuHelpDropDown.scss";
import { useAgencyStatus } from "@bokio/shared/state/requests";
import { useReleaseNotesContext } from "@bokio/contexts/ReleaseNotesContext/ReleaseNotesContext";

export const MenuHelpDropDown: React.FC = () => {
	const { visible, toggleVisible, view } = useHelpContext();
	const { agencyStatus } = useAgencyStatus();

	const { whatsNewData, hasNewNotesAgency, hasNewNotesCompany, setLastReadPrefernce } = useReleaseNotesContext();

	const lang = GeneralLangFactory();

	const getClassName = () => {
		return visible ? mergeClassNames(styles.dropDownWrapper, styles.dropDownWrapperVisible) : styles.dropDownWrapper;
	};

	return (
		<div className={getClassName()}>
			<TopBarDropDownButton
				text={lang.Help_Action}
				onClick={toggleVisible}
				open={visible}
				onBackgroundClose={toggleVisible}
				disableBackgroundClick={view === HelpWindowMode.Article || view === HelpWindowMode.Message}
				testId="MenuHelpDropDown"
				badge={agencyStatus ? hasNewNotesAgency : hasNewNotesCompany}
			/>
			<Help
				direction="down"
				whatsNewData={whatsNewData}
				whatsNewBadge={agencyStatus ? hasNewNotesAgency : hasNewNotesCompany}
				onWhatsNewClick={() => {
					toggleVisible();
					setLastReadPrefernce();
				}}
			/>
		</div>
	);
};
