import * as React from "react";

import Icon from "@bokio/elements/Icon/Icon";
import { mergeClassNames } from "@bokio/utils/classes";

import { Badge } from "../Badge/Badge";

import * as styles from "./expandableSection.scss";

export interface ExpandableSectionHeaderProps {
	headerText?: React.ReactNode;
	expanded?: boolean;
	className?: string;
	color?: "normal" | "blue" | "white" | "dark" | "none";
	tabIndex?: number;
	onClick?: (currentState: boolean) => void;
	testId?: string;
	borderBottom?: boolean;
	betaBadge?: boolean;
}

export default class ExpandableSectionHeader extends React.Component<ExpandableSectionHeaderProps> {
	wrapperRef: HTMLDivElement | null = null;

	componentDidMount() {
		if (this.wrapperRef) {
			this.wrapperRef.addEventListener("keydown", (e: KeyboardEvent) => {
				if (e.keyCode === 13) {
					this.toggleSection();
				}
			});
		}
	}
	render() {
		const classes = mergeClassNames(
			this.getColorClass(this.props.color),
			this.props.className,
			this.props.borderBottom && this.props.expanded && styles.borderBottom,
			"clearfix",
		);
		return (
			<div
				className={classes}
				tabIndex={this.props.tabIndex || 0}
				ref={elem => (this.wrapperRef = elem)}
				onClick={this.toggleSection}
				data-testid={this.props.testId}
			>
				{this.props.headerText && <span className={styles.header}>{this.props.headerText}</span>}
				{this.props.children}
				<div className={styles.icon}>
					{this.props.betaBadge && <Badge color="attention" name="Beta" />}
					<Icon name={this.props.expanded ? "up-open-big" : "down-open-big"} size="18" />
				</div>
			</div>
		);
	}

	toggleSection = () => {
		if (this.props.onClick) {
			this.props.onClick(!!this.props.expanded);
		}
	};
	getColorClass = (color: "normal" | "blue" | "white" | "dark" | "none" | undefined) => {
		switch (color) {
			case "blue":
				return styles.expandableBlue;
			case "white":
				return styles.expandableWhite;
			case "dark":
				return styles.expandableDark;
			case "none":
				return styles.expandableNone;
			default:
				return styles.expandableNormal;
		}
	};
}
