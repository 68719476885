import { connect as reduxConnect } from "react-redux";

import { useRedux } from "@bokio/hooks/useRedux/useRedux";
import * as proxy from "@bokio/mobile-web-shared/services/api/proxy";
import ApiRequest from "@bokio/shared/services/request/ApiRequest";
import { getCurrentAgencyId } from "@bokio/shared/state/router/selectors";
import { noop } from "@bokio/shared/utils";

import type * as m from "@bokio/mobile-web-shared/core/model/model";
import type { Infer } from "@bokio/shared/containers/connect";
import type { ApiRequestState } from "@bokio/shared/services/request/ApiRequestState";
import type { State } from "@bokio/shared/state/state";

type AgencyCountViewModel = m.Bokio.BackOffice.AgencyCountViewModel;
export type GetAgencyCountState = ApiRequestState<m.Envelope<AgencyCountViewModel, m.SimpleError>, string>;

export interface GetAgencyCountProps {
	/**
	 * Warning! If used outside `AgencyRoute` this can property be `undefined`.
	 */
	agencyCount: AgencyCountViewModel;
	isLoadingAgency: boolean;
}

export const GetAgencyCount = () => {
	const api = new ApiRequest<m.Envelope<AgencyCountViewModel, m.SimpleError>, string>(
		"AGENCY/GET_COUNT",
		state => state.requests.getAgencyCount,
	);
	const execute = () => {
		// eslint-disable-next-line @typescript-eslint/ban-types
		return (dispatch: Function, getState: () => State) => {
			const state = getState();
			const agencyId = getCurrentAgencyId(state);

			if (!agencyId) {
				return;
			}

			if (api.wasRequestedRecently(state)) {
				return;
			}

			return api.performRequest(dispatch, agencyId, async () => {
				const task = await proxy.BackOffice.PartnerViewerController.GetCount.Get(agencyId);
				return task;
			});
		};
	};

	const getAgencyCount = (state: State): AgencyCountViewModel => {
		const result = api.getResults(state);
		return (
			result?.Data ?? {
				ClientRequestCount: 0,
				QuoteRequestCount: 0,
				TotalCount: 0,
			}
		);
	};

	const mapStateToProps = (state: State): GetAgencyCountProps => ({
		agencyCount: getAgencyCount(state),
		isLoadingAgency: api.isLoading(state),
	});

	// eslint-disable-next-line @typescript-eslint/ban-types
	const mapDispatchToProps = (dispatch: Function) => ({ reloadAgencyCount: () => dispatch(execute()) });

	const connect = () => reduxConnect(mapStateToProps, {}) as Infer<GetAgencyCountProps>;
	const useProps = () => useRedux(mapStateToProps, mapDispatchToProps, noop);

	return {
		execute,
		connect,
		useProps,
		getReducer: () => api.getReducer(),
		getParameters: (state: State) => api.getParameters(state),
	};
};
