/* eslint-disable */

import type { RecipesLang } from "./types/RecipesLang";
import { translateTempImpl } from "./TranslatableLanguage";

let data: RecipesLang | undefined;

export type { RecipesLang };

export default function RecipesLangFactory(): RecipesLang {
  if (!data) {
    throw new Error("Trying to load a language in the main bundle before the language bundle is loaded");
  }
  return data;
}

export let setRecipesLang = (newLang: string) => {
  if (newLang === "ky-KG") {
    setRecipesLang = (nl:string) => {
      const storedLangData = window.sessionStorage.getItem("bokioLang."+ nl + ".Recipes");
      const rawData = JSON.parse(storedLangData || "");
      rawData.translateTemp = (key: string, neutral: string, svSE?: string, enGB?: string) => translateTempImpl(key, neutral, svSE, enGB);
      data = rawData;
      return Promise.resolve();
    }
    import(/* webpackChunkName: "en-GB.locale" */ "./en-GB/RecipesLangDef").then(({ RecipesLangDef }) => {
      let dataToStore = RecipesLangDef();
      window.sessionStorage.setItem("bokioLang.en-GB.Recipes", JSON.stringify(dataToStore));
    });
    import(/* webpackChunkName: "sv-SE.locale" */ "./sv-SE/RecipesLangDef").then(({ RecipesLangDef }) => {
      let dataToStore = RecipesLangDef();
      window.sessionStorage.setItem("bokioLang.sv-SE.Recipes", JSON.stringify(dataToStore));
    });
    import(/* webpackChunkName: "en-SE.locale" */ "./en-SE/RecipesLangDef").then(({ RecipesLangDef }) => {
      let dataToStore = RecipesLangDef();
      window.sessionStorage.setItem("bokioLang.en-SE.Recipes", JSON.stringify(dataToStore));
    });
    import(/* webpackChunkName: "ky-KG.locale" */ "./ky-KG/RecipesLangDef").then(({ RecipesLangDef }) => {
      let dataToStore = RecipesLangDef();
      window.sessionStorage.setItem("bokioLang.ky-KG.Recipes", JSON.stringify(dataToStore));
    });
  }
  if (newLang === "en-GB") {
      return import(/* webpackChunkName: "en-GB.locale" */ "./en-GB/RecipesLangDef").then(({ RecipesLangDef }) => {
      data = RecipesLangDef();
    });
  } else if (newLang === "sv-SE") {
      return import(/* webpackChunkName: "sv-SE.locale" */ "./sv-SE/RecipesLangDef").then(({ RecipesLangDef }) => {
      data = RecipesLangDef();
    });
  } else if (newLang === "en-SE") {
      return import(/* webpackChunkName: "en-SE.locale" */ "./en-SE/RecipesLangDef").then(({ RecipesLangDef }) => {
      data = RecipesLangDef();
    });
  } else if (newLang === "ky-KG") {
      return import(/* webpackChunkName: "ky-KG.locale" */ "./ky-KG/RecipesLangDef").then(({ RecipesLangDef }) => {
      data = RecipesLangDef();
    });
  }
  else {
    throw new Error("Unsupported language/culture " + newLang);
  }
};