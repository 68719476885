import * as React from "react";
import { useDispatch } from "react-redux";

import { Link } from "@bokio/elements/Link/Link";
import { OnboardingLangFactory } from "@bokio/lang";
import * as m from "@bokio/mobile-web-shared/core/model/model";
import { useLazyApi } from "@bokio/mobile-web-shared/hooks/useApi/useApi";
import * as proxy from "@bokio/mobile-web-shared/services/api/proxy";
import { useUser } from "@bokio/shared/state/requests";
import { GetUser } from "@bokio/shared/state/requests/user/GetUser";

import * as styles from "../../help.scss";

import UserFeatures = m.Entities.UserFeatures;
interface ToggleOnboardingOptionProps {
	onToggle: () => void;
}

const ToggleOnboardingOption = ({ onToggle }: ToggleOnboardingOptionProps) => {
	const onboardingLang = OnboardingLangFactory();
	const { user } = useUser();
	const dispatch = useDispatch();
	const [request] = useLazyApi(
		({ feature, enabled }: { feature: UserFeatures; enabled: boolean }) =>
			proxy.Settings.UserFeatureToggleController.Toggle.Post(feature, enabled),
		{
			onSuccess: () => {
				onToggle();
				dispatch(GetUser().execute());
			},
		},
	);

	const featureEnabled =
		user && user.UserFeatureToggles.some(f => f.FeatureKey === UserFeatures.ShowGetStartedBoard && f.Enabled);

	return user && !featureEnabled ? ( //We want to show link only when a user is logged in and the feature is disabled. Clicking link will enable the feature.
		<Link
			style="none"
			className={styles.link}
			onClick={() => request({ feature: UserFeatures.ShowGetStartedBoard, enabled: true })}
		>
			{onboardingLang.HelpMenuItem_ShowOnboarding}
		</Link>
	) : null;
};

export default ToggleOnboardingOption;
