// extracted by mini-css-extract-plugin
export var error = "wm";
export var whiteIcon = "wb";
export var greenIcon = "xm";
export var redIcon = "ym";
export var blueIcon = "kq";
export var yellowIcon = "xy";
export var lightGreyIcon = "_28H5HC61g_rvgS6FAYpJHk";
export var greyIcon = "Pf";
export var darkGreyIcon = "_35yXxC3SHKj6GEpIBuWmC0";
export var warningIcon = "Ql";
export var burgundyIcon = "Vn";
export var clickHandler = "Ay";
export var noMargin = "yk";