import * as React from "react";

import * as utils from "@bokio/shared/utils";
import { formatDate, formatNumberCurrency } from "@bokio/shared/utils/format";
import { mergeClassNames } from "@bokio/utils/classes";

import SorterHeading from "../Sorter/SorterHeading";
import { generateSummaryRow } from "./generateSummaryRow";
import Table from "./Table";
import TableExpanderButton from "./TableExpanderButton";
import TBody from "./TBody";
import Td from "./Td";
import Th from "./Th";
import THead from "./THead";
import Tr from "./Tr";

import type { SorterConfigExtended } from "../Sorter/Sorter";
import type { DateType } from "@bokio/shared/utils/format";

import * as styles from "./table.scss";

export type KeyIn<T> = keyof T;

export type KeyInObject<T> = { [key in keyof T]?: string };

interface SimpleTableConfig<T> {
	data: T[];
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	columns: ColumnConfig<T, any>[];
	level: number;
	headless?: boolean;
	rowTestId?: string;
	rowClassName?: string;
	rowHoverStyleEnabled?: boolean;
	summaryRow?: boolean;
	summaryRowKeys?: KeyIn<T>[];
	summaryOverride?: KeyInObject<T>;
	onRowClick?: (row: T) => void;
	onBeforeRowRender?: (next: T, prev: T | undefined, colCount: number) => JSX.Element | string | undefined;
	expandedChildrenTableRender?: (item: T, level: number, colCount: number) => JSX.Element | undefined;
	sortConfig?: SorterConfigExtended;
}

interface SimpleTableProps<T> {
	spec: SimpleTableConfig<T>;
	expanded?: T[];
	className?: string;
	testId?: string;
	keyGenerationFunction?: (row: T) => string;
}

export class ColumnDefinition<T, K> implements ColumnConfig<T, K> {
	constructor(
		public label: string | undefined,
		public accessor: (item: T, index: number) => K,
		public testId?: string,
	) {
		this.renderer = val => utils.toString(val);
	}

	public renderer: (value: K, item: T, expanded: boolean) => string | JSX.Element;
	public setRenderer = (callback: (value: K, item: T, expanded: boolean, index?: number) => string | JSX.Element) => {
		this.renderer = callback;
		return this;
	};

	public align?: Align;
	public setAlign = (align: Align | undefined) => {
		this.align = align;
		return this;
	};

	public classApplier?: (value: K, item: T) => string | undefined;
	public setClassApplier = (callback: (value: K, item: T) => string | undefined) => {
		this.classApplier = callback;
		return this;
	};

	setTestId = (testId: string) => {
		this.testId = testId;
		return this;
	};

	public visibility?: Visibility[];
	public setVisibility = (visibility: Visibility[]) => {
		this.visibility = visibility;
		return this;
	};

	public sortKey?: string;
	public setSortKey = (key: string) => {
		this.sortKey = key;
	};

	public onToggle?: (item: T) => void;
}

export class SimpleTableSpec<T extends object> implements SimpleTableConfig<T> {
	level = 1;
	headless?: boolean;
	onRowClick?: (row: T) => void;
	rowClassName?: string;
	rowHoverStyleEnabled?: boolean;
	rowTestId?: string;
	summaryRow?: boolean;
	summaryRowKeys?: KeyIn<T>[];
	summaryOverride?: KeyInObject<T>;
	sortConfig?: SorterConfigExtended;

	expandedChildrenTableRender?: (item: T, level: number, colCount: number) => JSX.Element | undefined;
	constructor(public data: T[]) {}
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	columns: ColumnConfig<T, any>[] = [];
	onBeforeRowRender?: (next: T, prev: T | undefined, colCount: number) => JSX.Element | string | undefined;

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	public addColumn(column: ColumnConfig<T, any>) {
		this.columns.push(column);
	}

	public column<K>(label: string | undefined, accessor: (item: T) => K) {
		const col = new ColumnDefinition<T, K>(label, accessor);
		this.columns.push(col);
		return col;
	}

	public emptyColumn() {
		const col = new ColumnDefinition<T, string>("", () => "");
		this.columns.push(col);
		return col;
	}

	public expanderColumn(
		shouldShowExpander: (item: T) => boolean,
		onExpand: (item: T, expanded: boolean) => void,
		visibility?: Visibility[],
	) {
		const col = new ColumnDefinition<T, unknown>("", t => t);
		col.setClassApplier(() => styles.expanderColumn);
		col.setRenderer((val, item, expanded) =>
			shouldShowExpander(item) ? (
				<TableExpanderButton activeTrigger={expanded || false} onClick={() => onExpand && onExpand(item, !expanded)} />
			) : (
				""
			),
		);
		visibility && col.setVisibility(visibility);
		this.columns.push(col);
		return col;
	}

	public decimalColumn(
		label: string | undefined,
		accessor: (item: T) => number | undefined,
		currency: string,
		decimals = 2,
	) {
		const col = new ColumnDefinition<T, number | undefined>(label, accessor);
		this.columns.push(col);
		col.setRenderer(value => (value === undefined ? "" : formatNumberCurrency(value, currency, decimals)));
		col.setAlign("right");
		return col;
	}

	public dateColumn(label: string | undefined, accessor: (item: T) => DateType | undefined) {
		const col = new ColumnDefinition<T, DateType | undefined>(label, accessor);
		this.columns.push(col);
		col.setRenderer(value => (value === undefined ? "" : formatDate(value)));
		return col;
	}

	public actionColumn(label: string | undefined, renderer: (item: T) => JSX.Element) {
		const col = new ColumnDefinition<T, unknown>(label, () => ({}));
		this.columns.push(col);
		col.setRenderer((value, item) => renderer(item));
		col.setAlign("right");
		return col;
	}

	setBeforeRowRender = (
		callback: (next: T, prev: T | undefined, colCount: number) => JSX.Element | string | undefined,
	) => {
		this.onBeforeRowRender = callback;
		return this;
	};

	setRowClickHandler = (handler: (row: T) => void) => {
		this.onRowClick = handler;
	};

	setRowClassName = (className: string) => {
		this.rowClassName = className;
	};

	applyRowHoverStyle = (value: boolean) => {
		this.rowHoverStyleEnabled = value;
	};

	setRowTestId = (testId: string) => {
		this.rowTestId = testId;
	};

	setExpandedChildrenTableRender = (
		callback: (item: T, level: number, colCount: number) => JSX.Element | undefined,
	) => {
		this.expandedChildrenTableRender = callback;
		return this;
	};

	setHeadless = (value: boolean) => {
		this.headless = value;
		return this;
	};

	setSortConfig = (value: SorterConfigExtended) => {
		this.sortConfig = value;
		return this;
	};

	setLevel = (value: number) => {
		this.level = value;
		return this;
	};

	addSummaryRow = (value: boolean, summaryRowKeys: KeyIn<T>[], summaryOverride: KeyInObject<T>) => {
		this.summaryRow = value;
		this.summaryRowKeys = summaryRowKeys;
		this.summaryOverride = summaryOverride;
		return this;
	};
}

export type Visibility =
	| "visibleM"
	//  | "visibleLM"  todo implement this, but fix inconsistensy with containermobilemax in _base.scss first .visibleTP */
	| "visibleTP"
	| "visibleTL"
	| "visibleDN"
	| "visibleDL"
	| "hiddenM"
	//  | "hiddenLM"
	| "hiddenTP"
	| "hiddenTL"
	| "hiddenDN"
	| "hiddenDL";

export type Align = "left" | "center" | "right";

export interface ColumnConfig<T, K> {
	renderer: (value: K, item: T, expanded: boolean, index?: number) => string | JSX.Element;
	label: string | undefined;
	sortKey?: string;
	accessor: (item: T, index: number) => K;
	align?: Align;
	visibility?: Visibility[];
	classApplier?: (value: K, item: T) => string | undefined;
	testId?: string;
}

const SimpleTable = <T extends object>(props: SimpleTableProps<T>) => {
	const { spec, expanded } = props;
	let prev: T | undefined;

	const renderRow = (summary?: boolean) => (row: T, i: number) => {
		const isExpanded = (expanded && expanded.indexOf(row) !== -1) || false;
		const rows: JSX.Element[] = [];
		if (spec.onBeforeRowRender !== undefined) {
			const res = spec.onBeforeRowRender(row, prev, spec.columns.length);
			if (res) {
				if (typeof res === "string") {
					rows.push(
						<Tr key={"gh_" + (props.keyGenerationFunction ? props.keyGenerationFunction(row) : i)}>
							<Th isGroupHeading={true} colSpan={spec.columns.length}>
								{res}
							</Th>
						</Tr>,
					);
				} else {
					rows.push(res);
				}
			}
		}

		rows.push(
			<Tr
				level={spec.level}
				key={props.keyGenerationFunction ? props.keyGenerationFunction(row) : i}
				onClick={spec.onRowClick && (() => spec.onRowClick?.(row))}
				className={mergeClassNames(
					spec.rowClassName,
					spec.rowHoverStyleEnabled && styles.hover,
					summary && styles.summaryRow,
				)}
				testId={spec.rowTestId && `${spec.rowTestId}_${i}`}
			>
				{spec.columns.map((col, j) => {
					const value = col.accessor(row, i);
					const cls = col.classApplier ? col.classApplier(value, row) : undefined;

					return (
						<Td
							dataTh={spec.columns[j].label}
							align={col.align}
							className={mergeClassNames(cls, summary && styles.summaryColumn)}
							key={j}
							visibility={col.visibility}
							testId={`${col.testId}_${i}`}
						>
							{col.renderer(value, row, isExpanded, i)}
						</Td>
					);
				})}
			</Tr>,
		);

		if (spec.expandedChildrenTableRender && isExpanded) {
			const item = spec.expandedChildrenTableRender(row, spec.level + 1, spec.columns.length);
			if (item) {
				rows.push(item);
			}
		}

		prev = row;
		return rows;
	};

	const tableRows = spec.data.map(renderRow());

	if (spec.summaryRow) {
		const summaryRow = generateSummaryRow(spec.data, spec.summaryRowKeys, spec.summaryOverride);
		tableRows.push(renderRow(true)(summaryRow, tableRows.length));
	}

	if (spec.headless) {
		return (
			<Table className={props.className} testId={props.testId}>
				<TBody>{tableRows}</TBody>{" "}
			</Table>
		);
	}

	return (
		<Table className={props.className} testId={props.testId}>
			<THead>
				<Tr>
					{spec.columns.map((col, i) => (
						<React.Fragment key={i}>
							{spec.sortConfig !== undefined && col.sortKey !== undefined && (
								<SorterHeading
									config={spec.sortConfig}
									sortKey={col.sortKey}
									align={col.align}
									visibility={col.visibility}
								>
									{col.label}
								</SorterHeading>
							)}
							{(spec.sortConfig === undefined || col.sortKey === undefined) && (
								<Th align={col.align} visibility={col.visibility}>
									{col.label}
								</Th>
							)}
						</React.Fragment>
					))}
				</Tr>
			</THead>
			<TBody>{tableRows}</TBody>
		</Table>
	);
};

export default SimpleTable;
