import { connect } from "react-redux";

import { useRedux } from "@bokio/hooks/useRedux/useRedux";
import { Logins } from "@bokio/shared/state/logins/actions";

import type * as m from "@bokio/mobile-web-shared/core/model/model";
import type { Infer } from "@bokio/shared/containers/connect";
import type { LoginsState } from "@bokio/shared/state/logins/state";
import type { State } from "@bokio/shared/state/state";

export interface LoginsControlsProps extends LoginsActions, LoginsState {}

export interface LoginsActions {
	setUserEmail: (v: string) => void;
	setUserFirstName: (v: string) => void;
	setUserLastName: (v: string) => void;
	setUserLoginMethod: (v: m.Bokio.Common.Contract.LoginMethod) => void;
	setUserPersonalNumber: (v: string) => void;
}

export const mapStateToProps = (state: State): LoginsState => state.logins;

// eslint-disable-next-line @typescript-eslint/ban-types
export const mapDispatchToProps = (dispatch: Function): LoginsActions => ({
	setUserEmail: (v: string) => dispatch(Logins.setUserEmail(v)),
	setUserFirstName: (v: string) => dispatch(Logins.setUserFirstName(v)),
	setUserLastName: (v: string) => dispatch(Logins.setUserLastName(v)),
	setUserLoginMethod: (v: m.Bokio.Common.Contract.LoginMethod) => dispatch(Logins.setUserLoginMethod(v)),
	setUserPersonalNumber: (v: string) => dispatch(Logins.setUserPersonalNumber(v)),
});

const withLoginsControls = connect(mapStateToProps, mapDispatchToProps) as Infer<LoginsControlsProps>;

// eslint-disable-next-line @typescript-eslint/no-empty-function
export const useLoginsControls = () => useRedux(mapStateToProps, mapDispatchToProps, () => {});

export default withLoginsControls;
