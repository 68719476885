import * as React from "react";

import classes from "@bokio/utils/classes";

import type { Property } from "csstype";

import * as styles from "./style.scss";

interface ColumnProps {
	width?: string;
	flex?: number;
	children?: React.ReactNode;
	verticalAlign?: Property.AlignItems;
	horizontalAlign?: Property.JustifyContent;
	hide?: boolean;
	className?: string;
	testId?: string;
}

interface ColumnStyle {
	width?: string;
	flex?: number;
	display?: string;
	alignItems?: Property.AlignItems;
	justifyContent?: Property.JustifyContent;
}

const Column = (props: ColumnProps) => {
	const style: ColumnStyle = { width: props.width, flex: props.width ? undefined : props.flex || 1 };

	if (props.verticalAlign) {
		style.display = "flex";
		style.alignItems = props.verticalAlign;
	}

	if (props.horizontalAlign) {
		style.display = "flex";
		style.justifyContent = props.horizontalAlign;
	}

	let classNames = classes(styles, "column", {
		hidden: props.hide,
	});

	if (props.className) {
		classNames += ` ${props.className}`;
	}

	return (
		<div className={classNames} style={style} data-testid={props.testId}>
			{props.children}
		</div>
	);
};

export default Column;
