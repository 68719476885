import * as React from "react";

import { useRedux } from "@bokio/hooks/useRedux/useRedux";
import { companyInfoContext } from "@bokio/mobile-web-shared/core/contexts/CompanyInfoContext/CompanyInfoContext";
import { mapDispatchToProps, mapStateToProps } from "@bokio/shared/containers/company/withCompanyInfo";

export const CompanyInfoContextProvider: React.FC = ({ children }) => {
	const companyInfo = useRedux(mapStateToProps, mapDispatchToProps, () => ({}));
	return <companyInfoContext.Provider value={companyInfo}>{children}</companyInfoContext.Provider>;
};
