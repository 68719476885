import * as React from "react";

import * as styles from "./boxToolbar.scss";

interface BoxToolbarProps {
	noPadding?: boolean;
	children?: React.ReactNode;
}

const BoxToolbar = (props: BoxToolbarProps) => (
	<div className={props.noPadding ? styles.toolbar : styles.toolBarNoPadding}>{props.children}</div>
);

export default BoxToolbar;
