import * as React from "react";

import { useApi } from "@bokio/mobile-web-shared/hooks/useApi/useApi";

import RenderRequest from "../RenderRequest/RenderRequest";

import type { LoadingComponents, RenderRequestError } from "../RenderRequest/RenderRequest";
import type { NoticeProps } from "@bokio/elements/Notice/Notice";
import type { Envelope } from "@bokio/mobile-web-shared/core/model/types";
import type { RequestState } from "@bokio/mobile-web-shared/services/api/requestState";

interface RenderProps<TResult, TError> {
	data: TResult;
	refresh: () => void;
	request: RequestState<Envelope<TResult, TError>>;
}

interface SideEffects<TParameters, TResult, TError> {
	onSuccess?: (data: TResult, parameters: TParameters) => void;
	onError?: (error: TError, parameters: TParameters) => void;
}

interface RenderApiProps<TParameters extends unknown[], TResult, TError> {
	endpoint: (...parameters: TParameters) => Promise<Envelope<TResult, TError>>;
	parameters: TParameters;
	children: ({ data, refresh, request }: RenderProps<TResult, TError>) => React.ReactNode;
	whenLoading: LoadingComponents | ((parameters: TParameters) => React.ReactNode);
	whenError?: "useDefault" | ((error: RenderRequestError<TError>) => React.ReactNode);
	sideEffects?: SideEffects<TParameters, TResult, TError>;
	useCacheWhenLoading?: boolean;
	errorNoticeProps?: NoticeProps;
}

type Props<P extends unknown[], R, E> = React.PropsWithChildren<RenderApiProps<P, R, E>>;

/**
 * Combines `useApiHook` with `RenderRequest`
 *
 * @example
 * <RenderApi
 *  endpont={proxy.Settings.CompanyInfo.Get}
 *  parameters={[companyInfo.Id]}
 *  whenLoading="box"
 *  sideEffects={{
 *    onSuccess: data => console.log('Data with success envelope'),
 *    onError: error => console.log('Handles soft errors')
 *  }}>
 *  {({ data, refresh }) => <ViewComponent data={data} onClick={refresh} />}
 * </RenderApi>
 *
 * @param endpoint An async function that takes one argument and returns a result. This function should be pure.
 * @param parameters An object that describes the parameters sent to the endpoint.
 */
export const RenderApi = <TParameters extends unknown[], TResult, TError>({
	endpoint,
	parameters,
	children,
	sideEffects,
	whenLoading,
	whenError = "useDefault",
	useCacheWhenLoading = false,
	errorNoticeProps,
}: Props<TParameters, TResult, TError>) => {
	const [request, refresh] = useApi(endpoint, parameters, {
		onSuccess: data => sideEffects?.onSuccess && sideEffects.onSuccess(data, parameters),
		onError: error => sideEffects?.onError && sideEffects.onError(error, parameters),
	});

	const loadingComponent = typeof whenLoading === "function" ? whenLoading(parameters) : whenLoading;

	return (
		<RenderRequest
			errorNoticeProps={errorNoticeProps}
			whenError={whenError}
			whenLoading={loadingComponent}
			useCacheWhenLoading={useCacheWhenLoading}
			request={request}
		>
			{data => children({ data, refresh, request })}
		</RenderRequest>
	);
};
