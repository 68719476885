import * as React from "react";
import { useDispatch, useSelector } from "react-redux";

import type { State } from "@bokio/shared/state/state";
import type { Dispatch } from "redux";

interface Merger<StateProps, DispatchProps, MergeProps> {
	(stateProps: StateProps, dispatchProps: DispatchProps): MergeProps;
}

export function useRedux<StateProps, DispatchProps, MergeProps>(
	mapStateToProps: (state: State) => StateProps,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	mapDispatchToProps: (dispatch: Dispatch<any>) => DispatchProps,
	mapPropsToMergeProps: Merger<StateProps, DispatchProps, MergeProps>,
): StateProps & DispatchProps & MergeProps {
	const dispatch = useDispatch();
	const stateProps = useSelector(mapStateToProps);
	const dispatchProps = React.useMemo(() => mapDispatchToProps(dispatch), [dispatch, mapDispatchToProps]);
	const mergeProps = mapPropsToMergeProps(stateProps, dispatchProps);
	return {
		...dispatchProps,
		...stateProps,
		...mergeProps,
	};
}
