import * as React from "react";

import { Article } from "@bokio/designsystem/components/TypographicElements/TypographicElements";
import Icon from "@bokio/elements/Icon/Icon";
import { mergeClassNames } from "@bokio/utils/classes";

import KeyboardListener from "./KeyboardListener";

import * as styles from "./modal.scss";

interface ModalBaseProps {
	visible: boolean;
	width?: ModalWidth;
	className?: string;
	disableEasyClosing?: boolean;
	onBackgroundClick: () => void;
	onCloseButtonClick: () => void;
	onEscape: () => void;
	title?: React.ReactNode;
	children: React.ReactNode;
	testId?: string;
	autoFocus: boolean | string;
	alwaysMounted?: boolean;
	noHeaderTitle?: boolean;
}

const getWidth = (width: ModalWidth = "medium") => {
	switch (width) {
		case "verySmall":
			return styles.modalVisibleVerySmall;
		case "small":
			return styles.modalVisibleSmall;
		case "medium":
			return styles.modalVisibleMedium;
		case "wide":
			return styles.modalVisibleWide;
		case "extraWide":
			return styles.modalVisibleExtraWide;
	}
};

export type ModalWidth = "verySmall" | "small" | "medium" | "wide" | "extraWide";
export type MultiFocusableElement = HTMLInputElement | HTMLAreaElement | HTMLButtonElement | HTMLSelectElement;

const getModalClassName = (width: ModalWidth | undefined, modalClassName?: string) => {
	const definedWidth = width && getWidth(width);
	const modalVisibleStyleName = width ? definedWidth : styles.modalVisible;
	return mergeClassNames(modalVisibleStyleName, modalClassName);
};

const getTabbableElements = (
	ref: HTMLDivElement | null,
	visible: boolean,
	prevElement: MultiFocusableElement | null,
	elementToFocus?: string,
) => {
	if (ref && visible) {
		const focusableElementsList = [
			...new Set([
				...ref.querySelectorAll<MultiFocusableElement>(elementToFocus || "__not_exist_element"),
				...ref.querySelectorAll<MultiFocusableElement>(
					'a[href]:not([disabled]), button:not(.modalCloseBtn), textarea:not([disabled]), input[type="text"]:not([disabled]), input[type="radio"]:not([disabled]), input[type="checkbox"]:not([disabled]), select:not([disabled])',
				),
			]),
		];
		if (focusableElementsList.length !== 0) {
			const firstFocusableEl = focusableElementsList[0];
			const lastFocusableEl = focusableElementsList[focusableElementsList.length - 1];

			/**
			 * NOTE: MQ 2020-10-15
			 * We cannot focus element which is currently `display: none` or `visibility: hidden`
			 * our modals are opened with 350ms transition -> we have to wait > 350ms before setting focus to any inside elements
			 */
			setTimeout(() => !ref.contains(document.activeElement) && firstFocusableEl.focus(), 350);

			// Traps focus within modal until close
			const trapTabs = (e: KeyboardEvent) => {
				if (e.key === "Tab" || e.keyCode === 9) {
					// SHIFT TAB
					if (e.shiftKey) {
						if (document.activeElement === firstFocusableEl) {
							lastFocusableEl.focus();
							e.preventDefault();
						}
						// TAB
					} else {
						if (document.activeElement === lastFocusableEl) {
							firstFocusableEl.focus();
							e.preventDefault();
						}
					}
				}
			};
			ref.addEventListener("keydown", trapTabs);
			return () => {
				ref && ref.removeEventListener("keydown", trapTabs);
				prevElement && prevElement.focus();
			};
		}
	}
	return;
};

const ModalBase = (props: ModalBaseProps) => {
	const { alwaysMounted = true } = props;
	const modalVisibleStyleName = getModalClassName(props.width, props.className);
	const modalRef = React.useRef<HTMLDivElement | null>(null);
	const [previousFocusedElement, setPreviousFocusedElement] = React.useState<MultiFocusableElement | null>(null);

	const [shouldRender, setShouldRender] = React.useState(alwaysMounted);

	React.useEffect(() => {
		props.visible && setShouldRender(true);
	}, [props.visible]);

	const handleAnimationEnd = () => !props.visible && !alwaysMounted && setShouldRender(false);

	React.useEffect(() => {
		if (shouldRender && props.autoFocus) {
			const currentActive = document.activeElement as MultiFocusableElement;
			const elementToFocus = typeof props.autoFocus === "string" ? props.autoFocus : undefined;

			setPreviousFocusedElement(currentActive);
			return getTabbableElements(modalRef.current, props.visible, currentActive, elementToFocus);
		}
		return;
	}, [props.visible, props.autoFocus, shouldRender]);

	const handleClose = (handler: () => void, elementToFocus: MultiFocusableElement | null) => {
		handler();
		elementToFocus && elementToFocus.focus();
	};

	const handleMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
		if (props.disableEasyClosing) {
			return;
		}
		// Only close the modal if we clicked on the background
		if (e.target !== e.currentTarget) {
			return;
		}
		// Dont close on right mouse button
		if (e.button === 2) {
			return;
		}
		handleClose(props.onBackgroundClick, previousFocusedElement);
	};

	if (!shouldRender) {
		return null;
	}

	return (
		<KeyboardListener onEscape={() => handleClose(props.onEscape, previousFocusedElement)}>
			<div className={props.visible ? styles.wrapperVisible : styles.wrapper} data-testid={props.testId}>
				<Article resetHeadingLevelTo={1}>
					<div className={styles.wrapperContent}>
						<div data-testid="Modal2Background" className={styles.background} onMouseDown={handleMouseDown} />
						<div
							onAnimationEnd={handleAnimationEnd}
							ref={ref => (modalRef.current = ref)}
							className={mergeClassNames(modalVisibleStyleName, props.visible ? styles.animateIn : styles.animateOut)}
							key="modal"
						>
							{props.title && (
								<div className={styles.header}>
									{props.noHeaderTitle ? (
										<div className={styles.heading}>{props.title}</div>
									) : (
										<h1 className={styles.heading}>{props.title}</h1>
									)}

									{!props.disableEasyClosing && (
										<div className={styles.closeContainer}>
											<button
												onClick={() => handleClose(props.onCloseButtonClick, previousFocusedElement)}
												className={mergeClassNames("modalCloseBtn", styles.close)}
												type="button"
												data-testid={props.testId ? `${props.testId}_Close` : undefined}
											>
												<Icon name="cancel" size="24" />
											</button>
										</div>
									)}
								</div>
							)}

							{props.children}
						</div>
					</div>
				</Article>
			</div>
		</KeyboardListener>
	);
};

ModalBase.defaultProps = {
	autoFocus: true,
};

export default ModalBase;
