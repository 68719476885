import * as React from "react";

import { SG } from "@bokio/designsystem/components/SpacingGroup/SpacingGroup";
import Icon from "@bokio/elements/Icon/Icon";
import { mergeClassNames } from "@bokio/utils/classes";

import * as styles from "./releaseNotesToast.scss";

interface ReleaseNotesToastProps {
	noOfUpdates: number;
	message: string;
	className?: string;
	testId?: string;
	children?: React.ReactNode;
}

const ReleaseNotesToast = ({ noOfUpdates, message, className, testId, children }: ReleaseNotesToastProps) => (
	<div data-testid={testId} className={mergeClassNames(styles.container, className)}>
		<SG gap="40" horizontal align="center">
			<SG gap="8" horizontal align="center">
				{noOfUpdates > 0 && (
					<div className={styles.contentLeft}>
						<SG gap="4" horizontal align="center">
							<Icon name="whats-new" size="16" />
							{noOfUpdates}
						</SG>
					</div>
				)}
				<div className={styles.contentCenter}>{message}</div>
			</SG>
			<div>{children}</div>
		</SG>
	</div>
);
export default ReleaseNotesToast;
