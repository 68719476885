import * as React from "react";

import { Link } from "@bokio/elements/Link/Link";
import { mergeClassNames } from "@bokio/utils/classes";

import * as styles from "./topBarButton.scss";

interface TopBarButtonProps {
	onClick?: () => void;
	route?: string;
	onBlur?: () => void;
	children: React.ReactNode;
	active?: boolean;
	className?: string;
	testId?: string;
}

export const TopBarButton = ({ onClick, route, onBlur, children, active, className, testId }: TopBarButtonProps) => {
	const props = {
		className: mergeClassNames(styles.buttonClickable, active && styles.clickableActive, className),
	};

	return onClick ? (
		<button type="button" onClick={onClick} {...props} onBlur={onBlur} data-testid={testId}>
			{children}
		</button>
	) : (
		<Link route={route} {...props}>
			{children}
		</Link>
	);
};
