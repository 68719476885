import "whatwg-fetch";
import "core-js/stable";
import "@bokio/assets/scss/global.scss";
import "regenerator-runtime/runtime";

import * as React from "react";
import * as ReactDOM from "react-dom";

import { languageNotifier } from "@bokio/lang/languageNotifier";
import { setup as dateUtilsSetup, initFormatter } from "@bokio/mobile-web-shared/core/model/types";
import { setup as numberUtilsSetup } from "@bokio/mobile-web-shared/core/utils/numberUtils";
import * as proxy from "@bokio/mobile-web-shared/services/api/proxy";
import { Config } from "@bokio/shared/config";
import { store } from "@bokio/shared/store";
import { formatDate } from "@bokio/shared/utils/format";
import { trackTrace } from "@bokio/utils/t";

// vitePluginBokioWebpackInterop-uncomment import { viteBokioAheadOfTimeWarmup } from "@bokio/utils/viteBokioAheadOfTimeWarmup";

import { loadIntl } from "./loadPolyFills";

// This is overridden in vitePluginBokioWebpackInterop
const isWebpack = true;

// Setup environment like number, date regex ... based on our culture
Config.setup({
	lang: window.currentLang,
	apiUrl: process.env.API_URL || "",
});

window.setLang = function (lang: string) {
	languageNotifier.load(lang);
};

window.setDebugLang = function () {
	// This is Kyrgyzstan, we need to use a real culture because the time formats will be messed up otherwise.
	// Kyrgyzstan is random and can be changed, it was chosen because it's a language that we won't likely support
	// If you change this you need to look for all places this string is used.
	window.setLang("ky-KG");
};

window.toogleScreenshotMode = function () {
	const root = window.document.getElementById("root");
	if (root) {
		if (root.classList.contains("screenShotMode")) {
			root.classList.remove("screenShotMode");
		} else {
			root.classList.add("screenShotMode");
		}
	}
};

//Makes it so that we can call all of our proxy functions through, for example, the dev console by writing window.bokioProxy.module.function
window.bokioProxy = proxy;

initFormatter(formatDate);

const reload = (reason: string | undefined) => {
	trackTrace(`AppRefresh.${reason || "UnknownReason"}`);
	window.location.reload();
};
const updateLang = (lang: string) => {
	const html = document.querySelector("html");
	if (html) {
		html.lang = lang.substr(0, Math.max(lang.indexOf("-"), lang.length)); //Defensive to make sure we don't crash the page if the lang don't contain a dash
	}
};

const startApp = async () => {
	["location", "history", "localStorage", "sessionStorage", "setTimeout", "getSelection"].forEach(api => {
		try {
			if (!window[api]) {
				trackTrace(`Browser lacks support for window.${api}`);
			}
		} catch (e) {}
	});

	// NOTE: Load App component and related libraries after polyfills are loaded
	// eslint-disable-next-line @typescript-eslint/no-var-requires
	const App = require("./App").App; // This is replaced with await import in vitePluginBokioWebpackInterop.ts

	ReactDOM.render(
		<React.StrictMode>
			<App
				store={store}
				releaseInformation={window.releaseInfo}
				upgradeAppToNewVersion={reload}
				updateLanguage={updateLang}
				featureAvailability={window.featureAvailability}
			/>
		</React.StrictMode>,
		document.getElementById("root") as HTMLElement,
	);

	// vitePluginBokioWebpackInterop-uncomment viteBokioAheadOfTimeWarmup();
};

Promise.all([
	// eslint-disable-next-line promise/always-return
	loadIntl().then(() => {
		dateUtilsSetup({ lang: Config.env.lang });
		Config.subscribe(dateUtilsSetup);

		numberUtilsSetup({ lang: Config.env.lang });
		Config.subscribe(numberUtilsSetup);
	}),
	languageNotifier.load(Config.env.lang),
])
	.then(startApp)
	.catch(window.onerror);

if (isWebpack && module.hot) {
	module.hot.accept("./App", startApp);
}
