import { connect as reduxConnect } from "react-redux";

import { useRedux } from "@bokio/hooks/useRedux/useRedux";
import { languageNotifier } from "@bokio/lang/languageNotifier";
import * as proxy from "@bokio/mobile-web-shared/services/api/proxy";
import ApiRequest from "@bokio/shared/services/request/ApiRequest";

import type * as m from "@bokio/mobile-web-shared/core/model/model";
import type { Infer } from "@bokio/shared/containers/connect";
import type { ApiRequestState } from "@bokio/shared/services/request/ApiRequestState";
import type { State } from "@bokio/shared/state/state";

type User = m.Bokio.Backbone.Web.Controllers.Settings.Models.UserViewModel;
export type GetUserState = ApiRequestState<User>;

export interface GetUserProps {
	user?: User;
	isLoadingUser?: boolean;
}

export const GetUser = () => {
	const api = new ApiRequest<User>("USER/GET", state => state.requests.getUser);

	const execute = () => {
		// eslint-disable-next-line @typescript-eslint/ban-types
		return (dispatch: Function, getState: () => State) => {
			if (api.wasRequestedRecently(getState())) {
				return;
			}

			dispatch(api.loading());

			return (
				proxy.Settings.UserController.Index.Get()
					// eslint-disable-next-line promise/always-return
					.then(response => {
						// eslint-disable-next-line promise/catch-or-return, promise/no-nesting
						languageNotifier.load(response.Language).then(() => dispatch(api.success(response)));
					})
					.catch(err => {
						dispatch(api.failure(err));
					})
			);
		};
	};

	const mapStateToProps = (state: State): GetUserProps => ({
		user: api.getResults(state),
		isLoadingUser: api.isLoading(state),
	});

	const connect = () => reduxConnect(mapStateToProps, {}) as Infer<GetUserProps>;
	const useProps = () =>
		useRedux(
			mapStateToProps,
			() => ({}),
			() => ({}),
		);
	return { execute, connect, getReducer: () => api.getReducer(), useProps };
};
