import { connect as reduxConnect } from "react-redux";

import { useRedux } from "@bokio/hooks/useRedux/useRedux";
import * as proxy from "@bokio/mobile-web-shared/services/api/proxy";
import ApiRequest from "@bokio/shared/services/request/ApiRequest";
import { getCurrentCompanyId } from "@bokio/shared/state/router/selectors";

import type * as m from "@bokio/mobile-web-shared/core/model/model";
import type { Infer } from "@bokio/shared/containers/connect";
import type { ApiRequestState } from "@bokio/shared/services/request/ApiRequestState";
import type { State } from "@bokio/shared/state/state";

export type GetMembershipsState = ApiRequestState<Memberships>;

export interface Memberships {
	partners: m.Settings.Controllers.PartnerMembershipDto[];
	companies: m.SettingsNoConflict.ViewModels.CompanyMembershipDto[];
}

export interface GetMembershipsPropsFromState {
	memberships?: Memberships;
	isLoadingMemberships: boolean;
}

export type GetMembershipsProps = GetMembershipsPropsFromState;

export const GetMemberships = () => {
	const api = new ApiRequest<Memberships>("USER/GET_MEMBERSHIPS", state => state.requests.getMemberships);

	const execute = () => {
		// eslint-disable-next-line @typescript-eslint/ban-types
		return (dispatch: Function, getState: () => State) => {
			const state = getState();
			let companyId = getCurrentCompanyId(state);

			if (api.wasRequestedRecently(state)) {
				return;
			}

			return api.performRequest(dispatch, async (): Promise<Memberships> => {
				const partners = await proxy.Settings.StatusController.PartnerMemberships.Get();

				if (!companyId) {
					const user = await proxy.Settings.UserController.Index.Get();

					if (user && user.CompanyMemberships[0]) {
						companyId = user.CompanyMemberships[0].CompanyId;
					} else {
						return { partners, companies: [] };
					}
				}

				if (!companyId) {
					return {
						companies: [],
						partners,
					};
				}

				const companies = await proxy.Settings.StatusController.Memberships.Get(companyId);

				return {
					companies,
					partners,
				};
			});
		};
	};

	const mapStateToProps = (state: State): GetMembershipsPropsFromState => ({
		memberships: api.getResults(state),
		isLoadingMemberships: api.isLoading(state),
	});

	const connect = () => reduxConnect(mapStateToProps, {}) as Infer<GetMembershipsProps>;

	const useProps = () =>
		useRedux(
			mapStateToProps,
			() => ({}),
			() => ({}),
		);

	return {
		execute,
		connect,
		useProps,
		getReducer: () => api.getReducer(),
		getParameters: (state: State) => api.getParameters(state),
	};
};
