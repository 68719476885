import * as React from "react";

import { Button } from "@bokio/designsystem/components/Button";
import { Paragraph } from "@bokio/designsystem/components/TypographicElements/TypographicElements";
import { Link } from "@bokio/elements/Link/Link";
import { Notice } from "@bokio/elements/Notice/Notice";
import { BankLangFactory, GeneralLangFactory } from "@bokio/lang";
import { useCompanyInfo } from "@bokio/mobile-web-shared/core/contexts/CompanyInfoContext/CompanyInfoContext";
import * as m from "@bokio/mobile-web-shared/core/model/model";
import { getRoute } from "@bokio/shared/route";
import { formatMessage } from "@bokio/shared/utils/format";

import { ContactSupportLink } from "../ContactSupportLink/ContactSupportLink";

export type BbaMigrationVariant = "overview" | "supportMessageModal" | "migrationBlock" | "activationBlock" | "payment";

export interface BbaMigrationNoticeProps {
	variant: BbaMigrationVariant;
}

function toReadMoreLink(message: string) {
	return (
		<Link target="_blank" external="https://www.bokio.se/foretagskonto/nya/faq-uppgradering/">
			{message}
		</Link>
	);
}

function toSupportLink(message: string) {
	return <ContactSupportLink area={m.Contracts.SupportFormArea.Bank}>{message}</ContactSupportLink>;
}

export const BbaMigrationNotice: React.FC<BbaMigrationNoticeProps> = ({ variant }) => {
	const { companyInfo } = useCompanyInfo();

	const bankLang = BankLangFactory();
	const generalLang = GeneralLangFactory();

	return (
		<Notice
			margin={["bottom"]}
			color="warning"
			hasIcon
			title={
				variant === "overview"
					? bankLang.BbaMigrationNotice_Title_Overview
					: bankLang.BbaMigrationNotice_Title_MigrationBlock
			}
			collapsible={false}
			fixActionRenderer={
				variant === "overview" && (
					<Button
						appearance="secondary"
						type="link"
						route={getRoute("bokioBusinessAccountMigration", { company: companyInfo.Id })}
					>
						{generalLang.GetStarted}
					</Button>
				)
			}
		>
			<Paragraph>{formatMessage(bankLang.BbaMigrationNotice_Content, toReadMoreLink, toSupportLink)}</Paragraph>
		</Notice>
	);
};
