import * as React from "react";
import { Route } from "react-router";

import withRouterControls from "@bokio/shared/containers/router/withRouterControls";
import { store } from "@bokio/shared/store";

import type { RouterControlsProps } from "@bokio/shared/containers/router/withRouterControls";
import type { State } from "@bokio/shared/state/state";
import type { RouteProps } from "react-router";

export function createWrapper(
	shouldRedirect: (state: State) => boolean,
	redirectPath: () => string = () => "/",
	runOnMount: () => void = () => undefined,
) {
	class Wrapper extends React.Component<RouteProps & RouterControlsProps> {
		componentDidMount() {
			if (shouldRedirect(store.getState())) {
				this.props.redirect(redirectPath());
			}
			runOnMount();
		}

		render() {
			const { path, component, exact } = this.props;

			return <Route path={path} component={component} exact={exact} />;
		}
	}

	return withRouterControls(Wrapper);
}
