import type { Action } from "../types";
import type * as m from "@bokio/mobile-web-shared/core/model/model";

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace Logins {
	export const SET_USER_EMAIL = "LOGINS/SET_USER_EMAIL";
	export const SET_USER_FIRSTNAME = "LOGINS/SET_USER_FIRSTNAME";
	export const SET_USER_LASTNAME = "LOGINS/SET_USER_LASTNAME";
	export const SET_USER_LOGIN_METHOD = "LOGINS/SET_USER_LOGIN_METHOD";
	export const SET_USER_PERSONAL_NUMBER = "LOGINS/SET_USER_PERSONAL_NUMBER";

	const createAction =
		<T>(type: string) =>
		(data: T): Action => ({ type, data });

	export const setUserEmail = createAction<string>(SET_USER_EMAIL);
	export const setUserFirstName = createAction<string>(SET_USER_FIRSTNAME);
	export const setUserLastName = createAction<string>(SET_USER_LASTNAME);
	export const setUserLoginMethod = createAction<m.Bokio.Common.Contract.LoginMethod | undefined>(
		SET_USER_LOGIN_METHOD,
	);
	export const setUserPersonalNumber = createAction<string>(SET_USER_PERSONAL_NUMBER);
}
