// extracted by mini-css-extract-plugin
export var group = "u5";
export var inlineFlex = "Mp";
export var groupHorizontal = "v5";
export var groupHorizontalDesktop = "w5";
export var groupSpaceBetween = "x5";
export var groupSpaceBetweenInDesktop = "E5";
export var groupBorder = "Q8";
export var groupBorderRadius = "R8";
export var item = "v-";
export var wrap = "_-";
export var itemIgnored = "RZa";
export var borderBetweenVertical = "Fp";
export var borderBetweenHorizontal = "iq";
export var stretchChildren = "STa";
export var useVariableBackground = "_Ua";
export var groupPaddingTop0 = "C_";
export var groupPaddingRight0 = "H_";
export var groupPaddingBottom0 = "I_";
export var groupPaddingLeft0 = "V_";
export var groupDesktopPaddingTop0 = "W_";
export var groupDesktopPaddingRight0 = "Laa";
export var groupDesktopPaddingBottom0 = "Maa";
export var groupDesktopPaddingLeft0 = "gca";
export var groupPaddingTop4 = "aga";
export var groupPaddingRight4 = "bga";
export var groupPaddingBottom4 = "cga";
export var groupPaddingLeft4 = "dga";
export var groupDesktopPaddingTop4 = "Ega";
export var groupDesktopPaddingRight4 = "eha";
export var groupDesktopPaddingBottom4 = "oia";
export var groupDesktopPaddingLeft4 = "Lma";
export var groupPaddingTop8 = "Mma";
export var groupPaddingRight8 = "Nma";
export var groupPaddingBottom8 = "Oma";
export var groupPaddingLeft8 = "Pma";
export var groupDesktopPaddingTop8 = "Qma";
export var groupDesktopPaddingRight8 = "Fna";
export var groupDesktopPaddingBottom8 = "ZIa";
export var groupDesktopPaddingLeft8 = "rLa";
export var groupPaddingTop12 = "jMa";
export var groupPaddingRight12 = "QSa";
export var groupPaddingBottom12 = "RSa";
export var groupPaddingLeft12 = "SSa";
export var groupDesktopPaddingTop12 = "TSa";
export var groupDesktopPaddingRight12 = "USa";
export var groupDesktopPaddingBottom12 = "hZa";
export var groupDesktopPaddingLeft12 = "iZa";
export var groupPaddingTop16 = "jZa";
export var groupPaddingRight16 = "kZa";
export var groupPaddingBottom16 = "lZa";
export var groupPaddingLeft16 = "mZa";
export var groupDesktopPaddingTop16 = "nZa";
export var groupDesktopPaddingRight16 = "oZa";
export var groupDesktopPaddingBottom16 = "pZa";
export var groupDesktopPaddingLeft16 = "qZa";
export var groupPaddingTop24 = "rZa";
export var groupPaddingRight24 = "sZa";
export var groupPaddingBottom24 = "tZa";
export var groupPaddingLeft24 = "uZa";
export var groupDesktopPaddingTop24 = "vZa";
export var groupDesktopPaddingRight24 = "wZa";
export var groupDesktopPaddingBottom24 = "xZa";
export var groupDesktopPaddingLeft24 = "yZa";
export var groupPaddingTop32 = "zZa";
export var groupPaddingRight32 = "AZa";
export var groupPaddingBottom32 = "BZa";
export var groupPaddingLeft32 = "CZa";
export var groupDesktopPaddingTop32 = "DZa";
export var groupDesktopPaddingRight32 = "EZa";
export var groupDesktopPaddingBottom32 = "FZa";
export var groupDesktopPaddingLeft32 = "GZa";
export var groupPaddingTop40 = "HZa";
export var groupPaddingRight40 = "IZa";
export var groupPaddingBottom40 = "JZa";
export var groupPaddingLeft40 = "KZa";
export var groupDesktopPaddingTop40 = "LZa";
export var groupDesktopPaddingRight40 = "MZa";
export var groupDesktopPaddingBottom40 = "NZa";
export var groupDesktopPaddingLeft40 = "OZa";
export var gapVertical0 = "QZa";
export var gapHorizontal0 = "SZa";
export var groupHorizontalWithGap0 = "PZa";
export var groupDesktopHorizontalWithGap0 = "TZa";
export var gapDesktopVertical0 = "UZa";
export var gapDesktopHorizontal0 = "VZa";
export var gapVertical4 = "XZa";
export var gapHorizontal4 = "YZa";
export var groupHorizontalWithGap4 = "WZa";
export var groupDesktopHorizontalWithGap4 = "ZZa";
export var gapDesktopVertical4 = "_Za";
export var gapDesktopHorizontal4 = "a0a";
export var gapVertical8 = "c0a";
export var gapHorizontal8 = "d0a";
export var groupHorizontalWithGap8 = "b0a";
export var groupDesktopHorizontalWithGap8 = "e0a";
export var gapDesktopVertical8 = "f0a";
export var gapDesktopHorizontal8 = "g0a";
export var gapVertical12 = "i0a";
export var gapHorizontal12 = "j0a";
export var groupHorizontalWithGap12 = "h0a";
export var groupDesktopHorizontalWithGap12 = "k0a";
export var gapDesktopVertical12 = "l0a";
export var gapDesktopHorizontal12 = "m0a";
export var gapVertical16 = "o0a";
export var gapHorizontal16 = "p0a";
export var groupHorizontalWithGap16 = "n0a";
export var groupDesktopHorizontalWithGap16 = "q0a";
export var gapDesktopVertical16 = "r0a";
export var gapDesktopHorizontal16 = "s0a";
export var gapVertical24 = "u0a";
export var gapHorizontal24 = "v0a";
export var groupHorizontalWithGap24 = "t0a";
export var groupDesktopHorizontalWithGap24 = "w0a";
export var gapDesktopVertical24 = "x0a";
export var gapDesktopHorizontal24 = "y0a";
export var gapVertical32 = "A0a";
export var gapHorizontal32 = "B0a";
export var groupHorizontalWithGap32 = "z0a";
export var groupDesktopHorizontalWithGap32 = "C0a";
export var gapDesktopVertical32 = "D0a";
export var gapDesktopHorizontal32 = "E0a";
export var gapVertical40 = "G0a";
export var gapHorizontal40 = "H0a";
export var groupHorizontalWithGap40 = "F0a";
export var groupDesktopHorizontalWithGap40 = "I0a";
export var gapDesktopVertical40 = "J0a";
export var gapDesktopHorizontal40 = "K0a";
export var groupAlignItems_start = "lxA1NT_uNy3nXkPa1TwPn";
export var groupDesktopAlignItems_start = "_3Pw3qf0RaVTJYsF5vfracq";
export var groupAlignItems_center = "_3o6L_E0yrXfuy9CMkR0DBG";
export var groupDesktopAlignItems_center = "_1GsxrGhxtWZQykHvmGft3v";
export var groupAlignItems_end = "_1wye06-MzyZwBiLVFc7mq1";
export var groupDesktopAlignItems_end = "_1Bh_4RakRLWrnuhJzZtaK";
export var groupAlignItems_stretch = "_2IBeM_ywCB-54yX-0oVkp";
export var groupDesktopAlignItems_stretch = "_3I5eYTf_r7fNj72J_uElIO";
export var groupJustifyContent_start = "_176YfS20w_pEIYpcAVGIh-";
export var groupDesktopJustifyContent_start = "AkmDGNDBcq6fqte5TkcDU";
export var groupJustifyContent_center = "_1i0PD6ch_HucQBWhs0NeUc";
export var groupDesktopJustifyContent_center = "_2E3YcqOzWiKVZ4A98GrQtj";
export var groupJustifyContent_end = "_2plmr-YMLjPK7YbXLPcbsi";
export var groupDesktopJustifyContent_end = "_3n5MyWWAFay8YyhQDR1ucj";
export var groupJustifyContent_stretch = "_3ORdMEyGmpbgOma3F2Vtb3";
export var groupDesktopJustifyContent_stretch = "_1R0pG8i4knjaNqVkgjcENt";