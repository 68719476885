/* eslint-disable */

import type { SalaryLang } from "./types/SalaryLang";
import { translateTempImpl } from "./TranslatableLanguage";

let data: SalaryLang | undefined;

export type { SalaryLang };

export default function SalaryLangFactory(): SalaryLang {
  if (!data) {
    throw new Error("Trying to load a language in the main bundle before the language bundle is loaded");
  }
  return data;
}

export let setSalaryLang = (newLang: string) => {
  if (newLang === "ky-KG") {
    setSalaryLang = (nl:string) => {
      const storedLangData = window.sessionStorage.getItem("bokioLang."+ nl + ".Salary");
      const rawData = JSON.parse(storedLangData || "");
      rawData.translateTemp = (key: string, neutral: string, svSE?: string, enGB?: string) => translateTempImpl(key, neutral, svSE, enGB);
      data = rawData;
      return Promise.resolve();
    }
    import(/* webpackChunkName: "en-GB.locale" */ "./en-GB/SalaryLangDef").then(({ SalaryLangDef }) => {
      let dataToStore = SalaryLangDef();
      window.sessionStorage.setItem("bokioLang.en-GB.Salary", JSON.stringify(dataToStore));
    });
    import(/* webpackChunkName: "sv-SE.locale" */ "./sv-SE/SalaryLangDef").then(({ SalaryLangDef }) => {
      let dataToStore = SalaryLangDef();
      window.sessionStorage.setItem("bokioLang.sv-SE.Salary", JSON.stringify(dataToStore));
    });
    import(/* webpackChunkName: "en-SE.locale" */ "./en-SE/SalaryLangDef").then(({ SalaryLangDef }) => {
      let dataToStore = SalaryLangDef();
      window.sessionStorage.setItem("bokioLang.en-SE.Salary", JSON.stringify(dataToStore));
    });
    import(/* webpackChunkName: "ky-KG.locale" */ "./ky-KG/SalaryLangDef").then(({ SalaryLangDef }) => {
      let dataToStore = SalaryLangDef();
      window.sessionStorage.setItem("bokioLang.ky-KG.Salary", JSON.stringify(dataToStore));
    });
  }
  if (newLang === "en-GB") {
      return import(/* webpackChunkName: "en-GB.locale" */ "./en-GB/SalaryLangDef").then(({ SalaryLangDef }) => {
      data = SalaryLangDef();
    });
  } else if (newLang === "sv-SE") {
      return import(/* webpackChunkName: "sv-SE.locale" */ "./sv-SE/SalaryLangDef").then(({ SalaryLangDef }) => {
      data = SalaryLangDef();
    });
  } else if (newLang === "en-SE") {
      return import(/* webpackChunkName: "en-SE.locale" */ "./en-SE/SalaryLangDef").then(({ SalaryLangDef }) => {
      data = SalaryLangDef();
    });
  } else if (newLang === "ky-KG") {
      return import(/* webpackChunkName: "ky-KG.locale" */ "./ky-KG/SalaryLangDef").then(({ SalaryLangDef }) => {
      data = SalaryLangDef();
    });
  }
  else {
    throw new Error("Unsupported language/culture " + newLang);
  }
};