import * as React from "react";

import { ContactSupportLink } from "@bokio/components/ContactSupportLink/ContactSupportLink";
import { Notice } from "@bokio/elements/Notice/Notice";
import GeneralLangFactory from "@bokio/lang/GeneralLangFactory";
import * as m from "@bokio/mobile-web-shared/core/model/model";
import { withErrorMessageFromEnvelope } from "@bokio/mobile-web-shared/core/utils/loaderHelpers";
import { formatMessage } from "@bokio/shared/utils/format";
import htmlDecode from "@bokio/shared/utils/htmlDecode";

import type { LoadingProps } from "./LoadingProps";

import * as styles from "./loading.scss";

interface RenderRequestErrorProps extends LoadingProps {
	showWhenLoaded?: React.ReactNode;
	noNoticeMargin?: boolean;
	noticeMarginBottom?: boolean;
	testId?: string;
	appendToError?: React.ReactNode; // Can be used for adding links, tooltips or extra interactive elements to error messages from the backend
}

const RenderRequestError: React.FC<RenderRequestErrorProps> = ({
	request,
	requests,
	children,
	showWhenLoaded,
	noNoticeMargin,
	noticeMarginBottom,
	hideSoftErrors = false,
	testId,
	appendToError,
}) => {
	let allRequests = [...(requests || []), ...(request ? [request] : [])];

	const generalLang = GeneralLangFactory();

	if (!hideSoftErrors) {
		allRequests = allRequests.map(r => withErrorMessageFromEnvelope(r));
	}

	const isLoading = allRequests.length === 0 || allRequests.some(r => r.isLoading);
	const allDone = allRequests.every(r => !r.isLoading);
	return (
		<React.Fragment>
			{isLoading ? children : allDone && showWhenLoaded}
			{allRequests.map((r, index) => (
				<React.Fragment key={r.errorMessage ?? index}>
					{r &&
						// Previously we only had the [BE-1234-5678] error codes. And when we did, we always wanted a generic message.
						// Now we always have a referenceId in the same property, but the error message could be localized and ment to be shown.
						r.errorMessage &&
						(r.errorCode && r.errorCode.startsWith("[BE-") ? (
							<Notice
								key={r.errorMessage}
								color="error"
								margin={[!noNoticeMargin && "top", noticeMarginBottom && "bottom"]}
								testId={testId || "LoadingWithError_ErrorMessage"}
							>
								<>
									<div>
										{formatMessage(
											generalLang.ErrorMessage_GenericWithErrorCode,
											() => (
												<ContactSupportLink
													area={m.Contracts.SupportFormArea.NotSet}
													messagePrefill={"(" + generalLang.ErrorCode_InMessageToSupport + r.errorCode + ")"}
												>
													{generalLang.ErrorMessage_GenericWithErrorCode_ContactSupportPart}
												</ContactSupportLink>
											),
											() => (
												<strong className={styles.emphasizedText}>{r.errorCode}</strong>
											),
										)}
									</div>
									{appendToError}
								</>
							</Notice>
						) : (
							<Notice
								key={r.errorMessage}
								color="error"
								margin={[!noNoticeMargin && "top", noticeMarginBottom && "bottom"]}
								testId={testId || "LoadingWithError_ErrorMessage"}
							>
								{
									<>
										{/* eslint-disable-next-line react/no-danger */}
										<div dangerouslySetInnerHTML={{ __html: htmlDecode(r.errorMessage) }} />
										{appendToError}
									</>
								}
							</Notice>
						))}
				</React.Fragment>
			))}
		</React.Fragment>
	);
};

export default RenderRequestError;
