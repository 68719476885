import { LOCATION_CHANGE } from "connected-react-router";

import { Config } from "@bokio/shared/config";
import { getRoute } from "@bokio/shared/route";

import ApiRequest from "../services/request/ApiRequest";
import {
	getAgencyCount,
	getAgencyStatus,
	getCompanyUser,
	getTodoStatus,
	getUser,
	getUserMemberships,
} from "./requests";
import { getCurrentAgencyId, getCurrentCompanyId } from "./router/selectors";
import { SessionActions } from "./session/actions";

import type { State } from "@bokio/shared/state/state";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const userMiddleware = (store: any) => (next: any) => async (action: any) => {
	next(action);

	const state = store.getState() as State;

	const dispatchUser = () => {
		const request = state.requests.getUser;

		const isLoggedIn = true; // TODO store in state
		if (isLoggedIn && !request.isRequesting && !request.data) {
			store.dispatch(getUser.execute());
		}
	};

	const logOff = () => {
		// eslint-disable-next-line promise/catch-or-return
		fetch(Config.env.apiUrl + "/Account/LogOff", {
			method: "post",
			redirect: "manual",
			credentials: "same-origin",
		}).then(
			() => (window.location.href = getRoute("login")),
			() => (window.location.href = getRoute("login")),
		);
	};

	// If there is a location change and the company ID differs, reload the company
	switch (action.type) {
		case LOCATION_CHANGE:
			const companyId = getCurrentCompanyId(state);
			const companyUserParams = getCompanyUser.getParameters(state);

			const agencyId = getCurrentAgencyId(state);
			const agencyStatusParams = getAgencyStatus.getParameters(state);

			if (companyId !== companyUserParams || agencyId !== agencyStatusParams) {
				store.dispatch(ApiRequest.clearAll());
			}

			break;

		case ApiRequest.CLEAR_USER:
			store.dispatch(getUser.execute());
			store.dispatch(getUserMemberships.execute());
			break;

		case ApiRequest.CLEAR_ALL:
			store.dispatch(getUser.execute());
			store.dispatch(getUserMemberships.execute());
			// We have to await getCompanyUser because Feature toggles is dependant of it. And Feature toggle is used in getTodoStatus.
			// When old todo is removed we can remove this await.
			await store.dispatch(getCompanyUser.execute());
			store.dispatch(getAgencyStatus.execute());
			store.dispatch(getAgencyCount.execute());
			store.dispatch(getTodoStatus.execute());
			break;

		// If the session is cleared, redirect to log in
		case SessionActions.CLEAR:
			logOff();
			break;

		case "USER/GET_FAILURE":
			break;

		case "USER/GET_SUCCESS":
			dispatchUser();
			break;

		case "USER/GET_COMPANY_USER_SUCCESS":
			dispatchUser();
			break;
	}
};

export default userMiddleware;
