import * as React from "react";

import * as styles from "./buttonGroupRow.scss";

/**
 * Allows to fit a Button and a ComboButton as part of a ButtonGroup.
 * Without a wrapper, controlling that the ComboButton is always on the right of the Button would be hard.
 * It would also mean refactoring the default behavior of button, as right now they are going into 100% width in mobile view.
 * @example
 * <ButtonGroup>
 * 	<Button/>
 * 	<ButtonGroupRow>
 * 		<Button/>
 * 		<ComboButton/>
 * 	</ButtonGroupRow>
 * </ButtonGroup>
 */
export const ButtonGroupRow: React.FC = ({ children }) => {
	return <div className={styles.row}>{children}</div>;
};
