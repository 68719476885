import * as React from "react";

import logoInvertedSvg from "@bokio/assets/images/brandV2/bokio_logo_inverted.svg";
import logoSvg from "@bokio/assets/images/brandV2/bokio_logo.svg";
import { Link } from "@bokio/elements/Link/Link";
import GeneralLangFactory from "@bokio/lang/GeneralLangFactory";
import withActiveUserInfo from "@bokio/shared/containers/user/withActiveUserInfo";
import { getRoute } from "@bokio/shared/route";
import { mergeClassNames } from "@bokio/utils/classes";

import type { ActiveUserProps } from "@bokio/shared/containers/user/withActiveUserInfo";

import * as styles from "./bokioLogo.scss";

interface BokioLogoProps {
	large?: boolean;
	inverted?: boolean;
	className?: string;
	imageClassName?: string;
}

const BokioLogo = (props: BokioLogoProps & ActiveUserProps) => {
	const lang = GeneralLangFactory();

	return (
		<Link
			className={mergeClassNames(props.large ? styles.logoLarge : styles.logo, props.className)}
			external={props.companyId && getRoute("companyIndex", { company: props.companyId })}
			style="none"
		>
			<img
				src={props.inverted ? logoInvertedSvg : logoSvg}
				alt={lang.LogotypeAltText}
				className={mergeClassNames(props.large ? styles.imgLarge : styles.img, props.imageClassName)}
			/>
		</Link>
	);
};

export default withActiveUserInfo(BokioLogo);
