import * as React from "react";
import { Key } from "w3c-keys";

import { useAccessibleDropdown } from "@bokio/hooks/useAccessibleKeyboard/useAccessibleDropdown";
import { mergeClassNames } from "@bokio/utils/classes";

import { Button } from "../Button/Button";
import { getMarginClassName } from "../Button/button.helper";
import { IconButton } from "../IconButton/IconButton";
import { Popover } from "../Popover/Popover";
import { filterEnabledDropdownItem } from "./Dropdown.helper";
import { isDropdownTriggerPropsForButton, isDropdownTriggerPropsForDefault } from "./Dropdown.types";
import { DropdownMenu } from "./DropdownMenu/DropdownMenu";

import type { DropdownProps } from "./Dropdown.types";

import * as styles from "./dropdown.scss";

export const Dropdown: React.FC<DropdownProps> = ({
	items,
	groups,
	testId,
	disabled = false,
	align,
	direction,
	stretchOnMobile,
	margin,
	...props
}) => {
	const [isOpen, setOpen] = React.useState(false);
	const toggleOpen = () => setOpen(s => !s);

	React.useEffect(() => {
		if (disabled) {
			setOpen(false);
		}
	}, [disabled]);

	const triggerRef = React.useRef<HTMLButtonElement>(null);

	const allItems = [
		...(items ?? []).filter(filterEnabledDropdownItem),
		...(groups ?? []).flatMap(group => group.items.filter(filterEnabledDropdownItem)),
	];

	const { handleKeyDown, focusedIndex, setOptionRef, resetFocusedIndex } = useAccessibleDropdown({
		options: allItems,
		customBindings: [
			[Key.ArrowUp, () => !isOpen && setOpen(true)],
			[Key.ArrowDown, () => !isOpen && setOpen(true)],
			[
				Key.Tab,
				e => {
					if (isOpen) {
						// `e.target` will be the currently focused list item
						(e.target as HTMLElement).blur();
						resetFocusedIndex();
						setOpen(false);
					}
				},
			],
			[
				Key.Escape,
				e => {
					if (isOpen) {
						(e.target as HTMLElement).blur();
						// You apparently can't just call `.focus()` without blurring the currently focused element
						triggerRef.current?.focus();
						resetFocusedIndex();
						setOpen(false);
					}
				},
			],
			[
				Key.Enter,
				(_, __, focusedIndex) => {
					if (focusedIndex > -1 && isOpen) {
						resetFocusedIndex();
						setOpen(false);
					}
				},
			],
		],
	});

	const focusedOptionKey = focusedIndex > -1 ? allItems[focusedIndex].key : undefined;

	return (
		<div
			className={mergeClassNames(styles.dropdown, margin && getMarginClassName(margin))}
			onKeyDown={handleKeyDown}
			aria-label={props.type === "default" && typeof props.trigger === "string" ? props.trigger : undefined}
			data-testid={testId}
		>
			{props.type === "icon" ? (
				<IconButton
					disabled={disabled}
					onClick={toggleOpen}
					icon={props.icon ?? "ellipsis"}
					size={props.size}
					ref={triggerRef}
					aria-haspopup="menu"
					aria-expanded={isOpen}
					testId="Dropdown_Trigger_Icon"
				/>
			) : isDropdownTriggerPropsForButton(props) ? (
				<Button
					disabled={disabled}
					onClick={toggleOpen}
					appearance="secondary"
					iconAlign="right"
					icon="angle-down"
					size={props.size}
					ref={triggerRef}
					aria-haspopup="menu"
					aria-expanded={isOpen}
					testId="Dropdown_Trigger_Default"
				>
					{props.trigger}
				</Button>
			) : isDropdownTriggerPropsForDefault(props) ? (
				<>{props.trigger({ onClick: toggleOpen, ref: triggerRef, disabled }, { isOpen })}</>
			) : (
				// This should never happen, ping in frontend channel if you ran into this.
				(() => {
					throw new RangeError();
				})()
			)}
			<Popover
				align={align}
				direction={direction}
				isOpen={isOpen}
				onClose={() => setOpen(false)}
				testId="Dropdown_Popover"
				stretchOnMobile={stretchOnMobile}
			>
				<DropdownMenu
					items={items}
					groups={groups}
					testId="Dropdown_DropdownMenu"
					focusedItemKey={focusedOptionKey}
					setItemRef={setOptionRef}
					onItemClick={() => setOpen(false)}
				/>
			</Popover>
		</div>
	);
};
