import * as React from "react";

import { BackOfficeLangFactory } from "@bokio/lang";
import * as m from "@bokio/mobile-web-shared/core/model/model";

import { OptimisticStatusSelect } from "./OptimisticStatusSelect";

import type { StatusOption } from "../StatusIndicator/StatusIndicator";

import WorkStatusType = m.Entities.Partners.WorkStatusType;
export const getStatusOption = (value: WorkStatusType): StatusOption => {
	const backOfficeLang = BackOfficeLangFactory();

	switch (value) {
		case WorkStatusType.Waiting:
			return {
				statusId: "activeDark",
				label: backOfficeLang.WorkStatus_Waiting,
			};
		case WorkStatusType.New:
			return {
				statusId: "active",
				label: backOfficeLang.WorkStatus_New,
			};
		case WorkStatusType.Overdue:
			return {
				statusId: "overdue",
				label: backOfficeLang.WorkStatus_Overdue,
			};
		case WorkStatusType.Ongoing:
			return {
				statusId: "draft",
				label: backOfficeLang.WorkStatus_Ongoing,
			};
		case WorkStatusType.Incomplete:
			return {
				statusId: "attention",
				label: backOfficeLang.WorkStatus_Incomplete,
			};
		case WorkStatusType.Completed:
			return {
				statusId: "complete",
				label: backOfficeLang.WorkStatus_Completed,
			};
		case WorkStatusType.Archived:
			return {
				statusId: "archived",
				label: backOfficeLang.WorkStatus_Archived,
			};
	}
};

export const getPriorityOption = (value: m.Entities.Priority): StatusOption => {
	const backOfficeLang = BackOfficeLangFactory();

	switch (value) {
		case m.Entities.Priority.High:
			return {
				statusId: "overdue",
				label: backOfficeLang.Priority_High,
			};
		case m.Entities.Priority.Low:
		case m.Entities.Priority.NotSet:
			return {
				statusId: "draft",
				label: backOfficeLang.Priority_Low,
			};
		case m.Entities.Priority.Medium:
			return {
				statusId: "active",
				label: backOfficeLang.Priority_Medium,
			};
	}
};

interface OptimisticWorkStatusSelectProps {
	currentValue: WorkStatusType;
	setter: (status: WorkStatusType) => Promise<void>;
	onChanged: () => void;
}

export const OptimisticWorkStatusSelect = (props: OptimisticWorkStatusSelectProps) => {
	return (
		<OptimisticStatusSelect
			{...props}
			options={[
				WorkStatusType.Waiting,
				WorkStatusType.Overdue,
				WorkStatusType.New,
				WorkStatusType.Ongoing,
				WorkStatusType.Incomplete,
				WorkStatusType.Completed,
			]}
			getStatusOption={getStatusOption}
		/>
	);
};
