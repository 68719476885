// extracted by mini-css-extract-plugin
export var status = "r_";
export var statusNumber = "oca";
export var noMargin = "vca";
export var legendStatus = "Cca";
export var clickable = "jda";
export var clickableIcon = "qd";
export var active = "Uka";
export var activeDark = "_ka";
export var draft = "Gna";
export var credit = "Pna";
export var rejected = "Aoa";
export var errorLight = "wsa";
export var overdue = "Pva";
export var attention = "pwa";
export var archived = "axa";
export var amend = "bxa";
export var complete = "cxa";
export var locked = "xza";
export var campaign = "mGzg9I8vnPQvaNhaUtyq2";
export var base = "_38w40RXnXCV9jH8WSLB5";
export var removable = "yza";
export var ghost = "Xza";
export var link = "Yza";
export var legend = "xBa";
export var text = "DBa";
export var leftIcon = "EBa";
export var rightIcon = "fCa";