import * as React from "react";

import { useFeatureAvailability } from "@bokio/hooks/useFeatureAvailability/useFeatureAvailability";
import { useScript } from "@bokio/hooks/useScript/useScript";
import { fromEnvelope, withErrorMessageFromEnvelope } from "@bokio/mobile-web-shared/core/utils/loaderHelpers";
import { useLazyApi } from "@bokio/mobile-web-shared/hooks/useApi/useApi";
import * as proxy from "@bokio/mobile-web-shared/services/api/proxy";
import * as requests from "@bokio/mobile-web-shared/services/api/requestState";
import { useActiveUserInfo } from "@bokio/shared/containers/user/withActiveUserInfo";

import { PlaidLinkScriptContext } from "./PlaidLinkScriptContext";
import { runPlaidFlow } from "./utils/runPlaidFlow";

import type { PlaidLink } from "./PlaidLinkScriptContext";

export const PlaidLinkScriptProvider: React.FunctionComponent = props => {
	const { companyId } = useActiveUserInfo();
	const featureAvailability = useFeatureAvailability();
	const isInitialisingPlaidRef = React.useRef(false);
	const [getOptions, optionsRequest] = useLazyApi(proxy.BankFeed.PlaidOptionsController.GetOptions.Get);

	const options = optionsRequest.data?.Data;

	const scriptRequest = useScript(featureAvailability.Plaid && options?.AuthScriptSrc);

	const connect = React.useCallback(async () => {
		if (!options) {
			throw new Error();
		}

		const token = await proxy.BankFeed.BankFeedController.CreateLinkToken.Post(companyId).then(fromEnvelope);
		const { publicToken, metaData } = await runPlaidFlow(token);

		const result = await proxy.BankFeed.BankFeedController.AddConnection.Post(companyId, {
			PublicKey: publicToken,
			Metadata: metaData,
		}).then(fromEnvelope);
		return { result, metaData };
	}, [companyId, options]);

	const reconnect = React.useCallback(
		async (bankId: string) => {
			if (!options) {
				throw new Error();
			}

			// This call creates a Link token for the specified bank in Update mode
			const token = await proxy.BankFeed.BankFeedController.CreateUpdateToken.Post(companyId, bankId).then(
				fromEnvelope,
			);
			await runPlaidFlow(token);
			await proxy.BankFeed.BankFeedController.ResetConnectionNeedsUpdate.Post(companyId, bankId);
		},
		[companyId, options],
	);

	const linkLoader: requests.RequestState<PlaidLink> =
		optionsRequest.isLoading || !scriptRequest.data
			? requests.load<PlaidLink>(undefined)
			: scriptRequest.error || withErrorMessageFromEnvelope(optionsRequest).error
				? requests.error<PlaidLink>(
						"" + scriptRequest.errorMessage + withErrorMessageFromEnvelope(optionsRequest).errorMessage,
					)
				: scriptRequest.data && optionsRequest.data?.Data
					? requests.success<PlaidLink>({ connect, reconnect })
					: requests.empty();

	return (
		<PlaidLinkScriptContext.Provider
			value={{
				isPlaidReady: !!linkLoader.data,
				plaid: linkLoader.data,
				initialisePlaid: () => {
					if (isInitialisingPlaidRef.current) {
						return;
					}

					isInitialisingPlaidRef.current = true;

					if (featureAvailability.Plaid) {
						getOptions(companyId);
					}
				},
			}}
		>
			{props.children}
		</PlaidLinkScriptContext.Provider>
	);
};
