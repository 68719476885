import { Todo } from "./actions";

import type { Action } from "../types";
import type { TodoState } from "./state";

const initialState: TodoState = {
	TodoCacheBuster: 0,
};

export default function (state: TodoState = initialState, action: Action): TodoState {
	switch (action.type) {
		case Todo.TODO_ACTIVITY:
			return { TodoCacheBuster: state.TodoCacheBuster + 1 };
		default:
			return state;
	}
}
