/* eslint-disable */

import type { ClosuresLang } from "./types/ClosuresLang";
import { translateTempImpl } from "./TranslatableLanguage";

let data: ClosuresLang | undefined;

export type { ClosuresLang };

export default function ClosuresLangFactory(): ClosuresLang {
  if (!data) {
    throw new Error("Trying to load a language in the main bundle before the language bundle is loaded");
  }
  return data;
}

export let setClosuresLang = (newLang: string) => {
  if (newLang === "ky-KG") {
    setClosuresLang = (nl:string) => {
      const storedLangData = window.sessionStorage.getItem("bokioLang."+ nl + ".Closures");
      const rawData = JSON.parse(storedLangData || "");
      rawData.translateTemp = (key: string, neutral: string, svSE?: string, enGB?: string) => translateTempImpl(key, neutral, svSE, enGB);
      data = rawData;
      return Promise.resolve();
    }
    import(/* webpackChunkName: "en-GB.locale" */ "./en-GB/ClosuresLangDef").then(({ ClosuresLangDef }) => {
      let dataToStore = ClosuresLangDef();
      window.sessionStorage.setItem("bokioLang.en-GB.Closures", JSON.stringify(dataToStore));
    });
    import(/* webpackChunkName: "sv-SE.locale" */ "./sv-SE/ClosuresLangDef").then(({ ClosuresLangDef }) => {
      let dataToStore = ClosuresLangDef();
      window.sessionStorage.setItem("bokioLang.sv-SE.Closures", JSON.stringify(dataToStore));
    });
    import(/* webpackChunkName: "en-SE.locale" */ "./en-SE/ClosuresLangDef").then(({ ClosuresLangDef }) => {
      let dataToStore = ClosuresLangDef();
      window.sessionStorage.setItem("bokioLang.en-SE.Closures", JSON.stringify(dataToStore));
    });
    import(/* webpackChunkName: "ky-KG.locale" */ "./ky-KG/ClosuresLangDef").then(({ ClosuresLangDef }) => {
      let dataToStore = ClosuresLangDef();
      window.sessionStorage.setItem("bokioLang.ky-KG.Closures", JSON.stringify(dataToStore));
    });
  }
  if (newLang === "en-GB") {
      return import(/* webpackChunkName: "en-GB.locale" */ "./en-GB/ClosuresLangDef").then(({ ClosuresLangDef }) => {
      data = ClosuresLangDef();
    });
  } else if (newLang === "sv-SE") {
      return import(/* webpackChunkName: "sv-SE.locale" */ "./sv-SE/ClosuresLangDef").then(({ ClosuresLangDef }) => {
      data = ClosuresLangDef();
    });
  } else if (newLang === "en-SE") {
      return import(/* webpackChunkName: "en-SE.locale" */ "./en-SE/ClosuresLangDef").then(({ ClosuresLangDef }) => {
      data = ClosuresLangDef();
    });
  } else if (newLang === "ky-KG") {
      return import(/* webpackChunkName: "ky-KG.locale" */ "./ky-KG/ClosuresLangDef").then(({ ClosuresLangDef }) => {
      data = ClosuresLangDef();
    });
  }
  else {
    throw new Error("Unsupported language/culture " + newLang);
  }
};