/* eslint-disable */

import type { CountriesLang } from "./types/CountriesLang";
import { translateTempImpl } from "./TranslatableLanguage";

let data: CountriesLang | undefined;

export type { CountriesLang };

export default function CountriesLangFactory(): CountriesLang {
  if (!data) {
    throw new Error("Trying to load a language in the main bundle before the language bundle is loaded");
  }
  return data;
}

export let setCountriesLang = (newLang: string) => {
  if (newLang === "ky-KG") {
    setCountriesLang = (nl:string) => {
      const storedLangData = window.sessionStorage.getItem("bokioLang."+ nl + ".Countries");
      const rawData = JSON.parse(storedLangData || "");
      rawData.translateTemp = (key: string, neutral: string, svSE?: string, enGB?: string) => translateTempImpl(key, neutral, svSE, enGB);
      data = rawData;
      return Promise.resolve();
    }
    import(/* webpackChunkName: "en-GB.locale" */ "./en-GB/CountriesLangDef").then(({ CountriesLangDef }) => {
      let dataToStore = CountriesLangDef();
      window.sessionStorage.setItem("bokioLang.en-GB.Countries", JSON.stringify(dataToStore));
    });
    import(/* webpackChunkName: "sv-SE.locale" */ "./sv-SE/CountriesLangDef").then(({ CountriesLangDef }) => {
      let dataToStore = CountriesLangDef();
      window.sessionStorage.setItem("bokioLang.sv-SE.Countries", JSON.stringify(dataToStore));
    });
    import(/* webpackChunkName: "en-SE.locale" */ "./en-SE/CountriesLangDef").then(({ CountriesLangDef }) => {
      let dataToStore = CountriesLangDef();
      window.sessionStorage.setItem("bokioLang.en-SE.Countries", JSON.stringify(dataToStore));
    });
    import(/* webpackChunkName: "ky-KG.locale" */ "./ky-KG/CountriesLangDef").then(({ CountriesLangDef }) => {
      let dataToStore = CountriesLangDef();
      window.sessionStorage.setItem("bokioLang.ky-KG.Countries", JSON.stringify(dataToStore));
    });
  }
  if (newLang === "en-GB") {
      return import(/* webpackChunkName: "en-GB.locale" */ "./en-GB/CountriesLangDef").then(({ CountriesLangDef }) => {
      data = CountriesLangDef();
    });
  } else if (newLang === "sv-SE") {
      return import(/* webpackChunkName: "sv-SE.locale" */ "./sv-SE/CountriesLangDef").then(({ CountriesLangDef }) => {
      data = CountriesLangDef();
    });
  } else if (newLang === "en-SE") {
      return import(/* webpackChunkName: "en-SE.locale" */ "./en-SE/CountriesLangDef").then(({ CountriesLangDef }) => {
      data = CountriesLangDef();
    });
  } else if (newLang === "ky-KG") {
      return import(/* webpackChunkName: "ky-KG.locale" */ "./ky-KG/CountriesLangDef").then(({ CountriesLangDef }) => {
      data = CountriesLangDef();
    });
  }
  else {
    throw new Error("Unsupported language/culture " + newLang);
  }
};