import * as React from "react";

import * as m from "@bokio/mobile-web-shared/core/model/model";
import { useActiveUserInfo } from "@bokio/shared/containers/user/withActiveUserInfo";
import { useTodoStatus } from "@bokio/shared/state/requests";

import { AppMessageType, useAppContext } from "../AppContext/AppContext";
import { BankFeedActivityContext, bankFeedActivityHub, defaultContextValue } from "./BankFeedActivityContext";

import type { BankFeedActivityState } from "./BankFeedActivityContext";

export const BankFeedActivityProvider: React.FunctionComponent = props => {
	const app = useAppContext();
	const todo = useTodoStatus();
	const user = useActiveUserInfo();
	const [state, setState] = React.useState<BankFeedActivityState>(defaultContextValue);
	const addMessage = app.addMessage;
	const updateTodoStatus = todo.updateTodoStatus;

	const onMessage = React.useCallback(
		(message: m.Bokio.Integration.Plaid.BankFeedEventDto) => {
			setState(prevState => ({
				counter: (prevState.counter + 1) % (Number.MAX_SAFE_INTEGER - 1),
				lastSynced: new Date(),
			}));
			if (message.Type === m.Bokio.Integration.Plaid.PlaidQueueType.FirstSync && message.CompanyId == user.companyId) {
				addMessage({ type: AppMessageType.BankSyncNotification, message, persist: false });
			}
			updateTodoStatus();
		},
		[addMessage, updateTodoStatus, user.companyId],
	);

	React.useEffect(() => {
		bankFeedActivityHub.add(onMessage);

		return () => {
			bankFeedActivityHub.remove(onMessage);
		};
	}, [onMessage]);

	return <BankFeedActivityContext.Provider value={state}>{props.children}</BankFeedActivityContext.Provider>;
};
