import * as React from "react";

import * as styles from "./floatingHelpButton.scss";

export interface FloatingHelpButtonProps {
	isOpened: boolean;
	onClick: () => void;
}

export const FloatingHelpButton: React.FC<FloatingHelpButtonProps> = props => {
	const classNames = "ficon-help " + (props.isOpened ? styles.helpTriggerOpen : styles.helpTrigger);
	return (
		<a className={classNames} onClick={props.onClick}>
			<span className={styles.helpTriggerText}>Help</span>
		</a>
	);
};
