import * as React from "react";

import { SG } from "@bokio/designsystem/components/SpacingGroup/SpacingGroup";
import { Badge } from "@bokio/elements/Badge/Badge";
import Icon from "@bokio/elements/Icon/Icon";
import Info from "@bokio/elements/Info/Info";
import { InfoTooltip } from "@bokio/elements/Tooltip";
import { Tooltip } from "@bokio/elements/Tooltip/Tooltip";
import { FieldRuleLevel } from "@bokio/shared/validation/entityValidator";
import { mergeClassNames } from "@bokio/utils/classes";

import { Validation } from "../Validation/Validation";
import { Label } from "./Label";

import type { FontelloIcons } from "@bokio/assets/fontello";
import type { RuleValidationResult } from "@bokio/shared/validation/entityValidator";

import * as styles from "./labelFor.scss";

export const getErrorsInOrder = (errors: RuleValidationResult[]) => {
	const mustFixNow = (errors && errors.filter(e => e.rule.level === FieldRuleLevel.MustFixNow)) || [];
	const canFixLater = (errors && errors.filter(e => e.rule.level === FieldRuleLevel.MustFixBeforeSend)) || [];

	return mustFixNow.concat(canFixLater);
};

export interface LabelTooltip {
	message: React.ReactNode;
	iconName?: FontelloIcons;
	trackinfo?: string;
	openDirection?: "Bottom" | "Top" | "Left" | "Right";
}

export interface LabelBadge {
	name: string;
	onClick: () => void;
}

interface LabelForProps {
	label: React.ReactNode;
	hint?: React.ReactNode;
	info?: React.ReactNode;
	children?: React.ReactNode;
	tooltip?: LabelTooltip;
	labelTooltip?: LabelTooltip;
	errors?: RuleValidationResult[];
	className?: string;
	testId?: string;
	htmlFor?: string;
	innerLabelClassName?: string;
	badge?: LabelBadge | LabelBadge[];
	mandatory?: boolean;
}

const LabelFor = ({
	label,
	hint,
	children,
	errors,
	className,
	info,
	tooltip,
	labelTooltip,
	testId,
	htmlFor,
	innerLabelClassName,
	badge,
	mandatory = false,
}: LabelForProps) => (
	<label htmlFor={htmlFor} data-testid={testId} className={mergeClassNames(styles.label, className)}>
		{label && (
			<div className={styles.flex}>
				<Label label={label} hint={hint} className={innerLabelClassName} mandatory={mandatory} />
				{labelTooltip && (
					<InfoTooltip openDirection={labelTooltip.openDirection} contentGenerator={() => labelTooltip.message} />
				)}
			</div>
		)}
		{tooltip ? (
			<div className={styles.flex}>
				{children}
				{tooltip && (
					<Tooltip
						contentGenerator={() => tooltip.message}
						openDirection={tooltip.openDirection}
						trackinfo={tooltip.trackinfo}
					>
						<Icon className={styles.tooltipIcon} name={tooltip.iconName || "info"} size="24" color="grey" />
					</Tooltip>
				)}
			</div>
		) : (
			children
		)}
		{info && <Info>{info}</Info>}
		{badge && (
			<SG padding={[4, 0, 0]}>
				<SG horizontal wrap>
					{badge && !Array.isArray(badge) && <Badge color="ghost" name={badge.name} onClick={badge.onClick} />}
					{badge &&
						Array.isArray(badge) &&
						badge.map(({ name, onClick }, index) => <Badge key={index} color="ghost" name={name} onClick={onClick} />)}
				</SG>
				<Validation testId={`Validation_Inline_${testId}`} errors={errors} />
			</SG>
		)}
		{!badge && <Validation testId={`Validation_Inline_${testId}`} errors={errors} />}
	</label>
);

export default LabelFor;
