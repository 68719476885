import * as React from "react";

import { Select, SelectOption } from "@bokio/elements/Select";
import { mergeClassNames } from "@bokio/utils/classes";

import { StatusIndicator } from "../StatusIndicator/StatusIndicator";

import type { StatusOption } from "../StatusIndicator/StatusIndicator";

import * as styles from "./statusSelect.scss";

interface StatusSelectProps<T> {
	labelClassName?: string;
	btnClassName?: string;
	value: T;
	onChange: (value: T) => void;
	options: T[];
	getStatusOption: (v: T) => StatusOption;
	label?: string;
}

export const StatusSelect = <T extends string>(props: StatusSelectProps<T>) => {
	return (
		<Select
			labelClassName={props.labelClassName}
			btnClassName={mergeClassNames(styles.wrapper, props.btnClassName)}
			selectedValue={props.value}
			onChange={(e, status) => props.onChange(status)}
			label={props.label}
		>
			{props.options.map(opt => (
				<SelectOption
					key={opt}
					value={opt}
					labelInDropDown={<StatusIndicator status={opt} getStatusOption={props.getStatusOption} />}
					labelWhenSelected={<StatusIndicator status={opt} getStatusOption={props.getStatusOption} />}
				/>
			))}
		</Select>
	);
};
