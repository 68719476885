/* eslint-disable */

import type { BankLang } from "./types/BankLang";
import { translateTempImpl } from "./TranslatableLanguage";

let data: BankLang | undefined;

export type { BankLang };

export default function BankLangFactory(): BankLang {
  if (!data) {
    throw new Error("Trying to load a language in the main bundle before the language bundle is loaded");
  }
  return data;
}

export let setBankLang = (newLang: string) => {
  if (newLang === "ky-KG") {
    setBankLang = (nl:string) => {
      const storedLangData = window.sessionStorage.getItem("bokioLang."+ nl + ".Bank");
      const rawData = JSON.parse(storedLangData || "");
      rawData.translateTemp = (key: string, neutral: string, svSE?: string, enGB?: string) => translateTempImpl(key, neutral, svSE, enGB);
      data = rawData;
      return Promise.resolve();
    }
    import(/* webpackChunkName: "en-GB.locale" */ "./en-GB/BankLangDef").then(({ BankLangDef }) => {
      let dataToStore = BankLangDef();
      window.sessionStorage.setItem("bokioLang.en-GB.Bank", JSON.stringify(dataToStore));
    });
    import(/* webpackChunkName: "sv-SE.locale" */ "./sv-SE/BankLangDef").then(({ BankLangDef }) => {
      let dataToStore = BankLangDef();
      window.sessionStorage.setItem("bokioLang.sv-SE.Bank", JSON.stringify(dataToStore));
    });
    import(/* webpackChunkName: "en-SE.locale" */ "./en-SE/BankLangDef").then(({ BankLangDef }) => {
      let dataToStore = BankLangDef();
      window.sessionStorage.setItem("bokioLang.en-SE.Bank", JSON.stringify(dataToStore));
    });
    import(/* webpackChunkName: "ky-KG.locale" */ "./ky-KG/BankLangDef").then(({ BankLangDef }) => {
      let dataToStore = BankLangDef();
      window.sessionStorage.setItem("bokioLang.ky-KG.Bank", JSON.stringify(dataToStore));
    });
  }
  if (newLang === "en-GB") {
      return import(/* webpackChunkName: "en-GB.locale" */ "./en-GB/BankLangDef").then(({ BankLangDef }) => {
      data = BankLangDef();
    });
  } else if (newLang === "sv-SE") {
      return import(/* webpackChunkName: "sv-SE.locale" */ "./sv-SE/BankLangDef").then(({ BankLangDef }) => {
      data = BankLangDef();
    });
  } else if (newLang === "en-SE") {
      return import(/* webpackChunkName: "en-SE.locale" */ "./en-SE/BankLangDef").then(({ BankLangDef }) => {
      data = BankLangDef();
    });
  } else if (newLang === "ky-KG") {
      return import(/* webpackChunkName: "ky-KG.locale" */ "./ky-KG/BankLangDef").then(({ BankLangDef }) => {
      data = BankLangDef();
    });
  }
  else {
    throw new Error("Unsupported language/culture " + newLang);
  }
};