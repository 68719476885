import { Fragment } from "react";

import { BokioPlanFeatureButton } from "@bokio/components/BokioPlanFeatureButton/BokioPlanFeatureButton";
import { BokioBusinessAccountButton } from "@bokio/elements/BokioBusinessAccountButton/BokioBusinessAccountButton";
import { Dropdown as LegacyDropdown } from "@bokio/elements/Dropdown";

import { Button } from "../Button/Button";
import { ButtonGroupRow } from "../ButtonGroupRow/ButtonGroupRow";
import { Dropdown } from "../Dropdown";
import { IconButton } from "../IconButton";

import type { ButtonGroupAlign, ButtonGroupDirection } from "./buttonGroup.types";

import * as styles from "./buttonGroup.scss";

export type OneOrMore<T> = T | T[];
export type ButtonGroupChildren = React.ReactElement | React.ReactElement[] | null | undefined | boolean | "";

const VALID_BUTTON_COMPONENTS: unknown[] = [
	Button,
	IconButton,
	Dropdown,
	LegacyDropdown,
	BokioBusinessAccountButton,
	BokioPlanFeatureButton,
	ButtonGroupRow,
	// If you run into circular dependency,
	// other components may be registered from registerAsValidButtonComponent() instead.
];

/**
 * If adding component to {@link VALID_BUTTON_COMPONENTS} gives you circular dependency issue,
 * add the component using this method instead.
 */
export const registerAsValidButtonComponent = (component: unknown) => VALID_BUTTON_COMPONENTS.push(component);

export function buttonGroupChildrenAreValid(children: OneOrMore<ButtonGroupChildren>): boolean {
	const toCheck = Array.isArray(children) ? children : [children];

	const elementChildren = toCheck.filter(child => (Array.isArray(child) ? child.filter(Boolean) : Boolean(child)));

	const allChildrenValid = elementChildren.every(child => {
		if (Array.isArray(child)) {
			return child.every(c => VALID_BUTTON_COMPONENTS.some(validC => validC === c.type));
		}

		const singleChild = child as React.ReactElement<React.PropsWithChildren<unknown>>;

		if (VALID_BUTTON_COMPONENTS.some(validC => validC === singleChild.type)) {
			return true;
		}

		if (singleChild.type === Fragment) {
			return buttonGroupChildrenAreValid(singleChild.props.children as OneOrMore<ButtonGroupChildren>);
		}

		return false;
	});

	return allChildrenValid;
}

export function getButtonGroupDirectionClassName(direction: ButtonGroupDirection): string {
	switch (direction) {
		case "column":
			return styles.directionColumn;
		case "row":
			return styles.directionRow;
		default:
			return "";
	}
}

export function getButtonGroupAlignClassName(align: ButtonGroupAlign): string {
	switch (align) {
		case "start":
			return styles.alignStart;
		case "center":
			return styles.alignCenter;
		case "end":
			return styles.alignEnd;
		case "space-between":
			return styles.spaceBetween;
		default:
			return "";
	}
}
