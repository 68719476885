import * as React from "react";

import classes, { mergeClassNames } from "@bokio/utils/classes";

import type { Visibility } from "./SimpleTable";

import * as styles from "./table.scss";

export interface TdProps {
	className?: string;
	colSpan?: number;
	align?: "left" | "center" | "right";
	verticalAlign?: "top" | "middle" | "bottom";
	shrink?: boolean;
	dataTh?: string;
	/**
	 * visibleM = visible on mobile
	 * visibleLM = visible on large mobile (not implemented)
	 * visibleTP = visible on tablet portrait
	 * visibleTL = visible on tablet landscape
	 * visibleDN = visible on desktop normal
	 * visibleDL = visible on desktop large
	 */
	visibility?: Visibility[];
	noPadding?: boolean;
	testId?: string;
	children?: React.ReactNode;
	title?: string;
}

const Td = (props: TdProps) => {
	const { colSpan, className, align, verticalAlign, shrink, visibility, noPadding, dataTh, testId, title } = props;
	const cls = classes(styles, "td", align || "", verticalAlign || "");
	const visibilityClasses = visibility ? visibility.map(v => styles[v]) : [];

	return (
		<td
			data-testid={testId}
			colSpan={colSpan}
			data-th={dataTh}
			title={title}
			className={mergeClassNames(
				cls,
				className,
				shrink && styles.shrink,
				noPadding && styles.noPadding,
				...visibilityClasses,
			)}
		>
			{props.children}
		</td>
	);
};

export default Td;
