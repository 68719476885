import * as React from "react";

import { cssVariableForColor } from "@bokio/designsystem/theme";

import { Section } from "../TypographicElements/TypographicElements";
import { wrapChildrenWithSGChild } from "./SGChild";
import { SGChildClassNameContext } from "./SGChildClassNameContext";

import type { ChildrenIterationInfoPointer } from "./SGChild";
import type { AlignItemsValue, CssPaddingTuple, JustifyContentValue, SpacingGroupProps } from "./SpacingGroup.types";
import type { CssVariableColorNames } from "@bokio/designsystem/theme/theme";

import * as styles from "./spacingGroup.scss";

const mapAlignItemsValueToClassNames = (alignProp: AlignItemsValue | undefined, inDesktop: boolean): string => {
	if (!alignProp) {
		return "";
	}
	const base = inDesktop ? "groupDesktopAlignItems" : "groupAlignItems";
	// Maps to .groupAlignItems_ in the SCSS
	return styles[base + "_" + alignProp];
};

const mapJustifyContentValueToClassNames = (
	justifyProp: JustifyContentValue | undefined,
	inDesktop: boolean,
): string => {
	if (!justifyProp) {
		return "";
	}

	const base = inDesktop ? "groupDesktopJustifyContent" : "groupJustifyContent";
	// Maps to .groupJustifyContent_ in the SCSS
	return styles[base + "_" + justifyProp];
};

const mapPaddingTupleToClassNames = (paddingProp: CssPaddingTuple | undefined, inDesktop: boolean): string => {
	if (!paddingProp) {
		return "";
	}

	const base = inDesktop ? "groupDesktopPadding" : "groupPadding";

	if (typeof paddingProp === "string") {
		return (
			styles[`${base}Top${paddingProp}`] +
			" " +
			styles[`${base}Right${paddingProp}`] +
			" " +
			styles[`${base}Bottom${paddingProp}`] +
			" " +
			styles[`${base}Left${paddingProp}`]
		);
	}

	const paddingPropLength = paddingProp.length;

	return paddingPropLength === 2
		? styles[`${base}Top${paddingProp[0]}`] +
				" " +
				styles[`${base}Right${paddingProp[1]}`] +
				" " +
				styles[`${base}Bottom${paddingProp[0]}`] +
				" " +
				styles[`${base}Left${paddingProp[1]}`]
		: paddingPropLength === 3
			? styles[`${base}Top${paddingProp[0]}`] +
				" " +
				styles[`${base}Right${paddingProp[1]}`] +
				" " +
				styles[`${base}Bottom${paddingProp[2]}`] +
				" " +
				styles[`${base}Left${paddingProp[1]}`]
			: paddingPropLength === 4
				? styles[`${base}Top${paddingProp[0]}`] +
					" " +
					styles[`${base}Right${paddingProp[1]}`] +
					" " +
					styles[`${base}Bottom${paddingProp[2]}`] +
					" " +
					styles[`${base}Left${paddingProp[3]}`]
				: "";
};

// SS 2021-09-17
// If you are thinking of migrating this to flex-gap,
// be ware SGIgnore is not compatible with flex-gap.

/**
 * Standard "spacing group" (hence the name "SG") component for adding gap (margin) between children.
 *
 * Children will be separately wrapped into a span element,
 * therefore you don't have to wrap component in an extra div just to separate it from its siblings.
 *
 * The component name and prop names are intended to be as short as possible to not disturb code review
 * because this component is going to be widely used in many places.
 *
 * @example
 *  <SG gap="8">
 *     <WhatEverComponent />
 *     <WhatEverComponent />
 *  </SG>
 */
export const SG: React.FC<SpacingGroupProps> = props => {
	// SS 2021-09-17
	// This code path will be really hot so it's best to do all the micro optimisations here,
	// so we don't get our app killed by a thousand cut.
	// (As always, don't do any micro optimisations without benchmarking :P.)
	//
	// The difference between optimised/unoptimised version of this component is very noticible.
	// In the 25x25 performance test page (which is more than the size of making Bokio fully covered in SG in every level) in storybook,
	// for each button click that triggers a whole page re-render,
	// the performance cost of each state change (render duration in React Profiler from React DevTools) is like:
	// - Baseline (no SG usage): 15-20 ms
	// - Unoptimised: 45-70 ms
	// - Optimised: 28-40 ms
	// I consider the current optimised version to be smooth enough,
	// even though you can say it's 100% worse than the original version,
	// 40 ms at that scale is still toleratable.
	//
	// Known caveats:
	// - When the component tree is too deep,
	//   browser will crash with a stack overflow error,
	//   but I didn't even hit the error in the 1x900 benchmark page,
	//   and realistically you would never use such deep tree,
	//   thus this is not a real issue.

	// Props are assigned separately by reading only once from the props object since
	// - Deconstructing assignment is very slow.
	// - Repeated object accessing is slower than reading once (as accessing object goes through getter).
	const gap = props.gap || "0";
	const gapInDesktop = props.gapInDesktop || gap;
	const horizontal = props.horizontal;
	const horizontalInDesktop = props.horizontalInDesktop || horizontal;
	// Ensure that we always set the initial background value to transparent,
	// so we never accidentally inherit an unwanted colour from CSS variable.
	//
	// For example:
	// <SG background="warning-300"> <-- This introduces the background CSS variable to the decendants
	//   <div style={{ background: "black" }}
	//     <SG> // <-- This should be transparent (rendered as the black due to the div above) instead of inheriting the `warning-300` CSS variable value from ancestor.
	const backgroundColor: CssVariableColorNames = props.background || "reserved-transparent";
	const borderRadius = props.borderRadius;
	const border = props.border;
	const borderBetween = props.borderBetween;
	const wrap = props.wrap;
	const children = props.children;
	const section = props.section;
	const inline = props.inline;
	const stretchChildren = props.stretchChildren;
	const paddingClassNames = mapPaddingTupleToClassNames(props.padding, false);
	const paddingInDesktopClassNames = mapPaddingTupleToClassNames(props.paddingInDesktop || props.padding, true);
	const alignClassNames = mapAlignItemsValueToClassNames(props.align, false);
	const alignInDesktopClassNames = mapAlignItemsValueToClassNames(props.alignInDesktop || props.align, true);
	const justifyClassNames = mapJustifyContentValueToClassNames(props.justify, false);
	const justifyInDesktopClassNames = mapJustifyContentValueToClassNames(props.justifyInDesktop || props.justify, true);

	const childrenIterationInfoPointer: ChildrenIterationInfoPointer = { foundFragmentUsage: false };

	// Class names are joined by concating strings as it's faster than going through mergeClassNames()
	const childClassName =
		(inline ? styles.inlineFlex : "") +
		" " +
		// Ignore space-between because it should be applied from parent (wrapper).
		(gap && gap !== "space-between" ? styles[`gap${horizontal ? "Horizontal" : "Vertical"}${gap}`] : "") +
		" " +
		(gapInDesktop && gapInDesktop !== "space-between"
			? styles[`gapDesktop${horizontalInDesktop ? "Horizontal" : "Vertical"}${gapInDesktop}`]
			: "") +
		" " +
		(borderBetween ? styles[`borderBetween${horizontal ? "Horizontal" : "Vertical"}`] : "") +
		" " +
		(stretchChildren ? styles.stretchChildren : "");

	const groupClassName =
		styles.group +
		" " +
		(inline ? styles.inlineFlex : "") +
		" " +
		(gap === "space-between" ? styles.groupSpaceBetween : "") +
		" " +
		(gapInDesktop === "space-between" ? styles.groupSpaceBetweenInDesktop : "") +
		" " +
		(horizontal ? styles.groupHorizontal : "") +
		" " +
		(horizontalInDesktop && horizontalInDesktop !== horizontal ? styles.groupHorizontalDesktop : "") +
		" " +
		(gap && gap !== "space-between" && horizontal ? styles[`groupHorizontalWithGap${gap}`] : "") +
		" " +
		(gapInDesktop && gapInDesktop !== "space-between" && horizontalInDesktop
			? styles[`groupDesktopHorizontalWithGap${gapInDesktop}`]
			: "") +
		" " +
		(wrap ? styles.wrap : "") +
		" " +
		alignClassNames +
		" " +
		alignInDesktopClassNames +
		" " +
		justifyClassNames +
		" " +
		justifyInDesktopClassNames +
		" " +
		(border ? styles.groupBorder : "") +
		" " +
		(borderRadius ? styles.groupBorderRadius : "") +
		" " +
		paddingClassNames +
		" " +
		paddingInDesktopClassNames +
		" " +
		styles.useVariableBackground;

	const node = (
		<span
			data-testid={props.testId}
			className={groupClassName}
			style={cssVariableForColor(backgroundColor, "--spacing-group-background-prop-value")}
		>
			{wrapChildrenWithSGChild(children, childClassName, false, childrenIterationInfoPointer)}
		</span>
	);

	const content = section ? <Section>{node}</Section> : node;

	return childrenIterationInfoPointer.foundFragmentUsage ? (
		// We avoid using context as much as possible since creating element here yields extra cost.
		<SGChildClassNameContext.Provider value={childClassName}>{content}</SGChildClassNameContext.Provider>
	) : (
		content
	);
};
