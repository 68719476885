import * as React from "react";

import BokioLogo from "@bokio/components/BokioLogo/BokioLogo";
import CompanyList from "@bokio/components/CompanyList/CompanyList";
import { Button } from "@bokio/designsystem/components/Button";
import { Link } from "@bokio/elements/Link/Link";
import { GeneralLangFactory } from "@bokio/lang";
import * as m from "@bokio/mobile-web-shared/core/model/model";
import { useMenuContainer } from "@bokio/shared/containers/layout/withMenuContainer";
import { useRouter } from "@bokio/shared/containers/router/useRouter";
import { getRoute } from "@bokio/shared/route";
import { useUser, useUserMemberships } from "@bokio/shared/state/requests";

import * as styles from "./selectCompany.scss";

import CompanyDraftSource = m.Entities.CompanyDraftSource;
export interface SelectCompanyProps {
	currentOrganisationId?: string;
	onChange?: () => void;
}

export const SelectCompany: React.FC<SelectCompanyProps> = ({ onChange, currentOrganisationId }) => {
	const { redirect } = useRouter();
	const { user } = useUser();
	const { memberships, isLoadingMemberships } = useUserMemberships();
	const openCreate = () => {
		memberships && memberships.partners.length > 0
			? redirect(
					getRoute("preCreateClient", { step: "choose-type", source: "select-company" }, { agencyId: undefined }),
				)
			: redirect(
					getRoute("createCompany", { id: undefined, step: undefined }, { source: CompanyDraftSource.ExistingUser }),
				);
	};

	const { signOut } = useMenuContainer();

	const generalLang = GeneralLangFactory();

	return (
		<div className={styles.selectCompany}>
			<div className={styles.logo}>
				<BokioLogo inverted={false} />
			</div>
			<div className={styles.content}>
				<div className={styles.companyListBox}>
					<div className={styles.title}>{generalLang.CompanyChoose_Heading}:</div>
					<CompanyList currentOrganisationId={currentOrganisationId} onChange={onChange} />
					{!isLoadingMemberships && (
						<Button appearance="primary" testId="SelectCompany_CreateCompany_Btn" onClick={openCreate} margin={["top"]}>
							{generalLang.CreateCompany}
						</Button>
					)}
				</div>
				{signOut && !isLoadingMemberships && (
					<div className={styles.logOutBox}>
						{user && (
							<div className={styles.user}>
								<p className={styles.userName}>
									{user.FirstName ? `${user.FirstName} ${user.LastName}` : generalLang.MyAccount}
								</p>
								<em className={styles.userEmail}>{user.Email}</em>
								{memberships && memberships.companies.length === 0 && (
									<Link route={getRoute("deleteUserAccount")}>{generalLang.DeleteAccount}</Link>
								)}
							</div>
						)}
						<Button appearance="secondary" onClick={signOut}>
							{generalLang.LogOut}
						</Button>
					</div>
				)}
			</div>
		</div>
	);
};
