// Modified from https://codepen.io/patryklizon/pen/WEoNJd
import * as React from "react";

import Icon from "@bokio/elements/Icon/Icon";

import { CounterBadge } from "../CounterBadge/CounterBadge";

import * as styles from "../MenuAccountantTopBarButton/AccountantPanelFlyout.scss";

type AccountantPanelIconProps = {
	badgeCount: number;
	fillClassName: string;
	backOfficeMode?: boolean;
};

export const AccountantPanelIcon: React.FC<AccountantPanelIconProps> = ({
	badgeCount,
	fillClassName,
	backOfficeMode,
}) => (
	<div className={styles.container}>
		<Icon name="tasks-comments" size="28" color={backOfficeMode ? "lightGrey" : "darkGrey"} className={fillClassName} />

		{badgeCount > 0 && (
			<span className={styles.badgeWrapper}>
				<CounterBadge size="xsmall" border>
					{badgeCount > 9 ? "9+" : `${badgeCount}`}
				</CounterBadge>
			</span>
		)}
	</div>
);
