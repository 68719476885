import * as React from "react";

import { Modal, ModalContent } from "@bokio/components/Modal";
import { Button, ButtonGroup } from "@bokio/designsystem/components/Button";
import { AnimatedFeedbackGraphic } from "@bokio/elements/Feedback/AnimatedFeedbackGraphic";
import Markdown from "@bokio/elements/Markdown/Markdown";
import { GeneralLangFactory } from "@bokio/lang";

interface TrialExtendedModalProps {
	resolve: () => void;
	dismiss: () => void;
}

export const TrialExtendedModal: React.FC<TrialExtendedModalProps> = ({ resolve, dismiss }) => {
	const generalLang = GeneralLangFactory();
	return (
		<Modal
			visible={true}
			title={generalLang.TrialExtendedModal_Heading}
			disableBackgroundClick={true}
			onClose={dismiss}
		>
			<ModalContent>
				<AnimatedFeedbackGraphic />
				<Markdown markdownContent={generalLang.TrialExtendedModal_Message} />
				<ButtonGroup margin={["top"]} align="end">
					<Button appearance="primary" onClick={resolve} align="flex-end">
						{generalLang.TrialExtendedModal_CTA}
					</Button>
				</ButtonGroup>
			</ModalContent>
		</Modal>
	);
};
