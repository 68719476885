import * as React from "react";

import { useHelpContext } from "@bokio/contexts/HelpContext/HelpContext";

import { FloatingHelpButton } from "../Help/FloatingHelpButton/FloatinHelpButton";
import { Help } from "../Help/Help/Help";

import * as styles from "./floatingHelp.scss";

export const FloatingHelp = () => {
	const { visible, toggleVisible } = useHelpContext();

	return (
		<div className={styles.help}>
			<FloatingHelpButton isOpened={visible} onClick={toggleVisible} />
			<Help direction="up" />
		</div>
	);
};
