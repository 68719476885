import { connect as reduxConnect } from "react-redux";

import { useRedux } from "@bokio/hooks/useRedux/useRedux";
import * as proxy from "@bokio/mobile-web-shared/services/api/proxy";
import ApiRequest from "@bokio/shared/services/request/ApiRequest";
import { getCurrentCompanyId } from "@bokio/shared/state/router/selectors";
import { Todo } from "@bokio/shared/state/todo/actions";
import { noop } from "@bokio/shared/utils";

import type * as m from "@bokio/mobile-web-shared/core/model/model";
import type { Infer } from "@bokio/shared/containers/connect";
import type { ApiRequestState } from "@bokio/shared/services/request/ApiRequestState";
import type { State } from "@bokio/shared/state/state";

type ToDoCountViewModel = m.Common.ViewModels.ToDoCountViewModel;
export type GetTodoStatusState = ApiRequestState<m.Envelope<ToDoCountViewModel, m.SimpleError>>;

export interface GetTodoStatusPropsFromState {
	todoCount?: ToDoCountViewModel;
	isLoadingTodo?: boolean;
	todoCacheBuster: number;
}

export interface GetTodoStatusPropsFromDispatch {
	updateTodoStatus: () => void;
}

export interface GetTodoStatusProps extends GetTodoStatusPropsFromState, GetTodoStatusPropsFromDispatch {}

const api = new ApiRequest<m.Envelope<ToDoCountViewModel, m.SimpleError>>(
	"TODO/GET_STATUS",
	state => state.requests.getTodoStatus,
);

const execute = () => {
	// eslint-disable-next-line @typescript-eslint/ban-types
	return (dispatch: Function, getState: () => State) => {
		const state = getState();
		const companyId = getCurrentCompanyId(state);

		if (!companyId) {
			return;
		}

		if (api.wasRequestedRecently(state)) {
			return;
		}

		return api.performRequest(dispatch, () => proxy.Common.ToDoController.Count.Get(companyId));
	};
};

// eslint-disable-next-line @typescript-eslint/ban-types
const mapDispatchToProps = (dispatch: Function): GetTodoStatusPropsFromDispatch => ({
	updateTodoStatus: () => {
		dispatch(execute());
		dispatch(Todo.updateTodoCacheBust());
	},
});

export const GetTodoStatus = () => {
	const getCacheBust = (state: State): number => {
		return state.todo.TodoCacheBuster;
	};

	const getTodoCount = (state: State): ToDoCountViewModel | undefined => {
		const result = api.getResults(state);
		return result ? result.Data : undefined;
	};

	const mapStateToProps = (state: State): GetTodoStatusPropsFromState => ({
		todoCount: getTodoCount(state),
		isLoadingTodo: api.isLoading(state),
		todoCacheBuster: getCacheBust(state),
	});

	const connect = () => reduxConnect(mapStateToProps, mapDispatchToProps) as Infer<GetTodoStatusProps>;
	const useProps = () => useRedux(mapStateToProps, mapDispatchToProps, noop);

	return {
		execute,
		connect,
		useProps,
		getReducer: () => api.getReducer(),
		getParameters: (state: State) => api.getParameters(state),
	};
};
