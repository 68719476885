import type { Action, Reducer } from "../../state/types";
import type { RequestActionTypes } from "./ApiRequest";
import type { ApiRequestState } from "./ApiRequestState";

/**
 * Create a request reducer that handles HTTP request state changes
 * @param actions
 */
export function createRequestReducer<TResults, TArgs = undefined, TError = undefined>(
	actions: RequestActionTypes,
): Reducer<ApiRequestState<TResults, TArgs, TError>> {
	const initialState: ApiRequestState<TResults, TArgs, TError> = {
		data: undefined,
		isRequesting: false,
		error: undefined,
		timestamp: undefined,
		params: undefined,
	};

	return function (
		state: ApiRequestState<TResults, TArgs, TError> | undefined = initialState,
		action: Action,
	): ApiRequestState<TResults, TArgs, TError> {
		switch (action.type) {
			case actions.CLEAR:
				return {
					...initialState,
				};
			case actions.EXECUTE:
				return {
					data: state.data,
					isRequesting: true,
					error: state.error,
					timestamp: undefined,
					params: action.data,
				};

			case actions.SUCCESS:
				return {
					...state,
					data: action.data,
					isRequesting: false,
					error: undefined,
					timestamp: new Date().getTime(),
				};

			case actions.FAILURE:
				return {
					...state,
					data: undefined,
					isRequesting: false,
					error: action.data,
					timestamp: undefined,
				};

			default:
				return state;
		}
	};
}
