import * as React from "react";
import { createPortal } from "react-dom";

import { mergeClassNames } from "@bokio/utils/classes";
import { trackEvent } from "@bokio/utils/t";

import { reposition } from "./tooltipUtils";

import type { TooltipPlacement } from "./tooltipUtils";

import * as styles from "./tooltip.scss";

// AP 2023: pascal case enum values SMH
export type TooltipOpenDirection = "Bottom" | "Top" | "Left" | "Right";

interface TooltipProps {
	offset?: number;
	open?: boolean;
	onOpenChange?: (open: boolean) => void;
	contentGenerator: React.ReactNode | (() => React.ReactNode);
	children: React.ReactNode[] | React.ReactNode;
	color?: "black" | "white";
	/** @deprecated Use placement instead */
	openDirection?: TooltipOpenDirection;
	placement?: TooltipPlacement;
	showArrow?: boolean;
	noContentPadding?: boolean;
	trackinfo?: string;
	triggerTestId?: string;
}

export const Tooltip = ({
	open: controlledOpen,
	onOpenChange: setControlledOpen,
	trackinfo,
	openDirection,
	color,
	noContentPadding,
	contentGenerator,
	showArrow,
	children,
	placement = "bottom",
	triggerTestId: testId,
	offset = 8,
}: TooltipProps) => {
	// TODO: remove this as soon as we get rid of openDirection
	let tooltipPlacement: TooltipPlacement = placement;
	if (openDirection) {
		switch (openDirection) {
			case "Top":
				tooltipPlacement = "top";
				break;
			default:
				tooltipPlacement = "bottom";
				break;
		}
	}
	const [uncontrolledOpen, setUncontrolledOpen] = React.useState(false);

	// Turn this into a prop if you find the need for it
	const padding = 10;

	const open = controlledOpen ?? uncontrolledOpen;
	const onOpenChange = setControlledOpen ?? setUncontrolledOpen;

	// Weird hack to useEffect
	const openStateTracking = React.useRef(false);

	const popper = React.useRef(null);
	const reference = React.useRef<HTMLDivElement>(null);
	const arrow = React.useRef<HTMLDivElement>(null);
	const content = React.useRef<HTMLDivElement>(null);

	const positionTooltip = React.useCallback(() => {
		if (popper.current && reference.current) {
			// Set tooltip margin to resolve w/h in the viewport
			(popper.current as HTMLElement).style.setProperty("--tooltipMargin", `${padding}px`);
			reposition(reference.current, popper.current, {
				offset,
				padding,
				placement: tooltipPlacement,
				arrow: arrow.current ?? undefined,
			});
			// Increase pseudo-element boundaries to facilitate interacting with the tooltip
			(popper.current as HTMLElement).style.setProperty("--tooltipOffset", `-${offset + 2}px`);
			// If the user scrolls out-of-bounds, close the tooltip
		}
	}, [offset, tooltipPlacement]);

	function openTooltip() {
		positionTooltip();
		onOpenChange(true);
	}

	/** This is to make the tooltipContent scrollable. Without it, scrolling inside the tooltip would
	reposition/rerender the entire tooltip because of the onCapture flag on `addEventListener`.*/
	const trackScroll = React.useCallback(
		(ev: Event) => {
			if ((ev.target as HTMLElement) !== content.current) {
				positionTooltip();
			}
		},
		[positionTooltip],
	);

	// Capture all scroll events
	React.useEffect(() => {
		if (open && !openStateTracking.current) {
			document.addEventListener("scroll", trackScroll, true);
			trackinfo && trackEvent("Tooltip", "Open", trackinfo);
		} else if (!open && openStateTracking.current) {
			document.removeEventListener("scroll", trackScroll, true);
			trackinfo && trackEvent("Tooltip", "Close", trackinfo);
		}
		openStateTracking.current = open;
	}, [open, trackScroll, trackinfo]);

	return (
		<>
			<div
				ref={reference}
				className={styles.tooltipTrigger}
				onMouseOver={openTooltip}
				onMouseLeave={() => onOpenChange(false)}
				data-testid={testId}
			>
				{children}
			</div>
			{createPortal(
				<div
					className={mergeClassNames(styles.tooltip, color === "black" ? styles.black : styles.white)}
					ref={popper}
					data-open={open}
					onMouseEnter={open ? () => onOpenChange(true) : undefined}
					onMouseLeave={open ? () => onOpenChange(false) : undefined}
				>
					{showArrow && <div ref={arrow} className={styles.arrow}></div>}
					<div
						ref={content}
						className={mergeClassNames(styles.tooltipContent, noContentPadding ? styles.paddingOff : styles.paddingOn)}
					>
						{typeof contentGenerator === "function" ? contentGenerator() : contentGenerator}
					</div>
				</div>,
				document.getElementById("tooltip-root") ?? document.body,
			)}
		</>
	);
};

Tooltip.defaultProps = {
	showArrow: true,
};

export default Tooltip;
