import * as React from "react";

import Flex from "@bokio/elements/Flex";
import { Link } from "@bokio/elements/Link/Link";
import * as styles from "@bokio/elements/Sorter/sorterHeading.scss";
import { mergeClassNames } from "@bokio/utils/classes";

import Th from "../Table/Th";

import type { Visibility } from "../Table/SimpleTable";
import type { SorterConfigExtended } from "./Sorter";

interface SorterHeadingProps {
	sortKey: string;
	config: SorterConfigExtended;
	align?: "right" | "left" | "center";
	isFlex?: boolean;
	className?: string;
	shrink?: boolean;
	testId?: string;
	visibility?: Visibility[];
}

class SorterHeading extends React.Component<SorterHeadingProps> {
	render() {
		const { isFlex } = this.props;
		if (isFlex) {
			return this.renderFlex(this.props.children);
		} else {
			return this.renderTableHead(this.props.children);
		}
	}

	renderFlex = (children: React.ReactNode): React.ReactNode => {
		const { sortKey, config, className } = this.props;
		const activeClassName = styles[`active_${config.sortOrder}`];
		return (
			// Placeholder for when a flexBox sorting header is needed
			<Flex className={className} direction="column">
				<Link
					style="none"
					className={mergeClassNames(styles.th, config.sortProperty === sortKey && activeClassName)}
					onClick={config.setSort(sortKey)}
					testId={this.props.testId}
				>
					{children}
				</Link>
			</Flex>
		);
	};

	renderTableHead = (children: React.ReactNode): React.ReactNode => {
		const { sortKey, align, config, className, shrink, visibility } = this.props;
		const activeClassName = styles[`active_${config.sortOrder}`];
		return (
			<Th className={className} align={align} shrink={shrink} visibility={visibility}>
				<Link
					style="none"
					className={mergeClassNames(styles.th, config.sortProperty === sortKey && activeClassName)}
					onClick={config.setSort(sortKey)}
					testId={this.props.testId}
				>
					{children}
				</Link>
			</Th>
		);
	};
}

export default SorterHeading;
