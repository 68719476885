import * as React from "react";

import BokioLogo from "@bokio/components/BokioLogo/BokioLogo";

import { FloatingHelp } from "../FloatingHelp/FloatingHelp";

import * as styles from "./layoutFull.scss";

interface LayoutFullProps {
	noTopBarMode: boolean;
}

export const LayoutFull: React.FC<LayoutFullProps> = props => (
	<div className={styles.wrapper}>
		<div className={styles.logo}>
			<BokioLogo large={true} inverted={false} imageClassName={styles.logoImage} />
		</div>
		{props.children}
		{props.noTopBarMode && <FloatingHelp />}
	</div>
);
