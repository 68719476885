import * as React from "react";

import { mergeClassNames } from "@bokio/utils/classes";

import * as styles from "./emptyState.scss";

interface EmptyStateProps {
	className?: string;
	testId?: string;
}

/**
 * @deprecated Prefer EmptyState3 as it's documented in Figma.
 */
export const EmptyState: React.FC<EmptyStateProps> = props => (
	<div data-testid={props.testId} className={mergeClassNames(styles.emptyState, props.className)}>
		{props.children}
	</div>
);
