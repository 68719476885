import * as React from "react";

import { SelectField } from "@bokio/elements/Form";
import { SettingsLangFactory } from "@bokio/lang";

import * as styles from "./fiscalYearForm.scss";

interface FiscalYearTurnoverPickerProps {
	turnover: number;
	onChange: (val: number) => void;
}

export const FiscalYearTurnoverPicker: React.FC<FiscalYearTurnoverPickerProps> = props => {
	const settingsLang = SettingsLangFactory();

	const turnoverOptions: { value: number; label: string }[] = [
		{ value: 0, label: settingsLang.Turnover_0To1 },
		{ value: 1000000, label: settingsLang.Turnover_1To40 },
		{ value: 4000000, label: settingsLang.Turnover_40ToX },
	];

	// ME: This need to work with "random" input as old data was done with a number field
	const reversedOptions = turnoverOptions.filter(() => true).reverse();
	const currentOption = reversedOptions.filter(o => o.value <= props.turnover)[0] ?? turnoverOptions[0]; // Guard against negative turnover
	return (
		<>
			<SelectField
				label={settingsLang.FiscalYears_Turnover}
				options={turnoverOptions}
				value={currentOption.value}
				onChange={props.onChange}
			/>
			<p className={styles.inputInfo}>{settingsLang.FiscalYears_Turnover_Description}</p>
		</>
	);
};
