/* eslint-disable */

import type { OnboardingLang } from "./types/OnboardingLang";
import { translateTempImpl } from "./TranslatableLanguage";

let data: OnboardingLang | undefined;

export type { OnboardingLang };

export default function OnboardingLangFactory(): OnboardingLang {
  if (!data) {
    throw new Error("Trying to load a language in the main bundle before the language bundle is loaded");
  }
  return data;
}

export let setOnboardingLang = (newLang: string) => {
  if (newLang === "ky-KG") {
    setOnboardingLang = (nl:string) => {
      const storedLangData = window.sessionStorage.getItem("bokioLang."+ nl + ".Onboarding");
      const rawData = JSON.parse(storedLangData || "");
      rawData.translateTemp = (key: string, neutral: string, svSE?: string, enGB?: string) => translateTempImpl(key, neutral, svSE, enGB);
      data = rawData;
      return Promise.resolve();
    }
    import(/* webpackChunkName: "en-GB.locale" */ "./en-GB/OnboardingLangDef").then(({ OnboardingLangDef }) => {
      let dataToStore = OnboardingLangDef();
      window.sessionStorage.setItem("bokioLang.en-GB.Onboarding", JSON.stringify(dataToStore));
    });
    import(/* webpackChunkName: "sv-SE.locale" */ "./sv-SE/OnboardingLangDef").then(({ OnboardingLangDef }) => {
      let dataToStore = OnboardingLangDef();
      window.sessionStorage.setItem("bokioLang.sv-SE.Onboarding", JSON.stringify(dataToStore));
    });
    import(/* webpackChunkName: "en-SE.locale" */ "./en-SE/OnboardingLangDef").then(({ OnboardingLangDef }) => {
      let dataToStore = OnboardingLangDef();
      window.sessionStorage.setItem("bokioLang.en-SE.Onboarding", JSON.stringify(dataToStore));
    });
    import(/* webpackChunkName: "ky-KG.locale" */ "./ky-KG/OnboardingLangDef").then(({ OnboardingLangDef }) => {
      let dataToStore = OnboardingLangDef();
      window.sessionStorage.setItem("bokioLang.ky-KG.Onboarding", JSON.stringify(dataToStore));
    });
  }
  if (newLang === "en-GB") {
      return import(/* webpackChunkName: "en-GB.locale" */ "./en-GB/OnboardingLangDef").then(({ OnboardingLangDef }) => {
      data = OnboardingLangDef();
    });
  } else if (newLang === "sv-SE") {
      return import(/* webpackChunkName: "sv-SE.locale" */ "./sv-SE/OnboardingLangDef").then(({ OnboardingLangDef }) => {
      data = OnboardingLangDef();
    });
  } else if (newLang === "en-SE") {
      return import(/* webpackChunkName: "en-SE.locale" */ "./en-SE/OnboardingLangDef").then(({ OnboardingLangDef }) => {
      data = OnboardingLangDef();
    });
  } else if (newLang === "ky-KG") {
      return import(/* webpackChunkName: "ky-KG.locale" */ "./ky-KG/OnboardingLangDef").then(({ OnboardingLangDef }) => {
      data = OnboardingLangDef();
    });
  }
  else {
    throw new Error("Unsupported language/culture " + newLang);
  }
};