import * as React from "react";

import { FancyModal } from "@bokio/components/FancyModal/FancyModal";
import { Button, ButtonGroup } from "@bokio/designsystem/components/Button";
import Markdown from "@bokio/elements/Markdown/Markdown";
import { GeneralLangFactory } from "@bokio/lang";

interface SummerCampaignModalProps {
	title: string;
	markdown: string;
	resolve: () => void;
}

export const SummerCampaignModal: React.FC<SummerCampaignModalProps> = ({ title, resolve, markdown }) => {
	const generalLang = GeneralLangFactory();

	return (
		<FancyModal
			testId="SMM_SC_Modal"
			visible={true}
			title={title}
			onClose={resolve}
			graphic="halfCircleAndBox"
			backgroundColor="green"
		>
			<Markdown markdownContent={markdown} useTypographicElements={false} />
			<ButtonGroup margin={["top", "bottom"]}>
				<Button testId="SMM_SC_button" appearance="primary" type="button" onClick={resolve}>
					{generalLang.SummerCampaign_GetStarted}
				</Button>
			</ButtonGroup>
		</FancyModal>
	);
};
