import * as React from "react";

import { usePricePlanContext } from "@bokio/contexts/PricePlanContext/PricePlanContext";
import { Popover } from "@bokio/designsystem/components/Popover/Popover";
import { Article, Heading } from "@bokio/designsystem/components/TypographicElements/TypographicElements";
import { useDeviceQuery } from "@bokio/elements/DeviceQuery/useDeviceQuery";
import { BankLangFactory } from "@bokio/lang";
import { useBokioBusinessAccountOnboardingModal } from "@bokio/settings/src/scenes/BokioPricePlans/components/StartBokioBusinessAccountOnboardingModal/StartBokioBusinessAccountOnboardingModal";

import { Modal, ModalContent, ModalFooter } from "../Modal";
import { BbaOnboardingPopoverContent } from "./BbaOnboardingPopoverContent";
import { BbaOnboardingPopoverFooter } from "./BbaOnboardingPopoverFooter";
import { BbaOnboardingPopoverNotInterestedLink } from "./BbaOnboardingPopoverNotInterestedLink";

import type * as m from "@bokio/mobile-web-shared/core/model/model";
import type { UseBokioBusinessAccountOnboardingModalReturn } from "@bokio/settings/src/scenes/BokioPricePlans/components/StartBokioBusinessAccountOnboardingModal/StartBokioBusinessAccountOnboardingModal";

import * as styles from "./bbaOnboardingPopover.scss";

// TODO: See if it's possible to merge the styles with SignAndPayPopover.

type OnboardingStatusViewModel = m.Bokio.Bank.Contract.Mir.Onboarding.OnboardingStatusViewModel;

interface BbaOnboardingPopoverProps {
	visible: boolean;
	arrowAnchor: HTMLDivElement | null;
	onboardingStatus: OnboardingStatusViewModel;
	onNotInterest: () => void;
	onClose: () => void;
	testId?: string;
}

type BbaOnboardingPopoverInnerProps = BbaOnboardingPopoverProps & {
	bokioBusinessAccountModalHook: UseBokioBusinessAccountOnboardingModalReturn;
};

const BbaOnboardingPopoverInner: React.FC<BbaOnboardingPopoverInnerProps> = ({
	visible,
	arrowAnchor,
	onboardingStatus,
	bokioBusinessAccountModalHook,
	onNotInterest,
	onClose,
	testId,
}) => {
	const bankLang = BankLangFactory();

	return (
		<Popover
			isOpen={visible}
			align="middle"
			showArrow
			arrowAnchor={arrowAnchor}
			borderRadius="medium"
			direction="down"
			onClose={onClose}
			testId={testId}
		>
			<div className={styles.popoverRoot}>
				<Article>
					<div className={styles.popoverHeader}>
						<div>
							<Heading style="body-bold">{bankLang.BokioBusinessAccount}</Heading>
						</div>
						<div>
							<BbaOnboardingPopoverNotInterestedLink onClick={onNotInterest} />
						</div>
					</div>
					<BbaOnboardingPopoverContent variant="popover" />
					<BbaOnboardingPopoverFooter
						variant="popover"
						onboardingStatus={onboardingStatus}
						onClose={onClose}
						bokioBusinessAccountModalHook={bokioBusinessAccountModalHook}
					/>
				</Article>
			</div>
		</Popover>
	);
};

const BbaOnboardingPopoverInnerAsModal: React.FC<BbaOnboardingPopoverInnerProps> = ({
	visible,
	onboardingStatus,
	bokioBusinessAccountModalHook,
	onNotInterest,
	onClose,
	testId,
}) => {
	const bankLang = BankLangFactory();

	return (
		<Modal onClose={onClose} visible={visible} title={bankLang.BokioBusinessAccount} testId={testId}>
			<ModalContent>
				<BbaOnboardingPopoverContent variant="modal" onNotInterest={onNotInterest} />
			</ModalContent>
			<ModalFooter>
				<BbaOnboardingPopoverFooter
					variant="modal"
					onboardingStatus={onboardingStatus}
					onClose={onClose}
					bokioBusinessAccountModalHook={bokioBusinessAccountModalHook}
				/>
			</ModalFooter>
		</Modal>
	);
};

export const BbaOnboardingPopover: React.FC<BbaOnboardingPopoverProps> = props => {
	const { isMobile } = useDeviceQuery();
	const { plan } = usePricePlanContext();
	const bokioBusinessAccountModalHook = useBokioBusinessAccountOnboardingModal();
	const isChildModalVisible = bokioBusinessAccountModalHook.bbaOnboardingModal.isVisible;

	return (
		<>
			{isMobile ? (
				<BbaOnboardingPopoverInnerAsModal
					{...props}
					visible={props.visible || isChildModalVisible}
					bokioBusinessAccountModalHook={bokioBusinessAccountModalHook}
				/>
			) : (
				<BbaOnboardingPopoverInner
					{...props}
					visible={props.visible || isChildModalVisible}
					bokioBusinessAccountModalHook={bokioBusinessAccountModalHook}
				/>
			)}
			{plan && bokioBusinessAccountModalHook.bbaOnboardingModal.render(plan)}
		</>
	);
};
