import * as React from "react";

import { mergeClassNames } from "@bokio/utils/classes";

import Icon from "../Icon/Icon";

import * as styles from "./info.scss";

interface InfoProps {
	children: React.ReactNode;
	className?: string;
	testId?: string;
}

const Info = ({ children, className, testId }: InfoProps) => {
	return (
		<small className={mergeClassNames(styles.info, className)} data-testid={testId}>
			<Icon name="info" className={styles.infoIcon} size="14" />
			<span>{children}</span>
		</small>
	);
};

export default Info;
