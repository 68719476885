import { getRoute } from "@bokio/shared/route";

import { createWrapper } from "./RedirectWrapper";

import type { State } from "@bokio/shared/state/state";

/* ME: This logic is highly problematic because you can't do a soft redirect to a guest route if you
 * have any user data in the store. Which is basically if you have visited any company route.
 */

export default createWrapper(
	(state: State) => !!state.requests.getUser.data,
	() => getRoute("root"),
);
