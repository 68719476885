import * as React from "react";

import { InputField } from "./InputField";

import type { InputFieldProps } from "./InputField";

const onEmailInputFieldKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
	// Hack.
	// See https://bokiodev.visualstudio.com/Voder/_workitems/edit/6525
	// and https://github.com/facebook/react/issues/6368.
	if (/\s/.test(e.key)) {
		e.preventDefault();
	}
};

type ChangeHandler = (v: string) => void;

const removeWhiteSpaces = (onChange: ChangeHandler | undefined): ChangeHandler | undefined =>
	onChange ? val => onChange(val ? val.replace(/\s/g, "") : "") : undefined;

export const EmailField = (props: InputFieldProps) => (
	<InputField
		{...{
			...props,
			onChange: removeWhiteSpaces(props.onChange),
			fieldProps: {
				...props.fieldProps,
				type: "email",
				onKeyPress: onEmailInputFieldKeyPress,
			},
		}}
	/>
);
