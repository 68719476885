import * as React from "react";

import { Button } from "@bokio/designsystem/components/Button";
import { useDeviceQuery } from "@bokio/elements/DeviceQuery/useDeviceQuery";
import {
	ExpandableSection,
	ExpandableSectionContent,
	ExpandableSectionHeader,
} from "@bokio/elements/ExpandableSection";
import { GeneralLangFactory } from "@bokio/lang";
import * as m from "@bokio/mobile-web-shared/core/model/model";
import { getRoute } from "@bokio/shared/route";
import { useUserMemberships } from "@bokio/shared/state/requests";

import * as styles from "./testCompanyTopBar.scss";

import CompanyDraftSource = m.Entities.CompanyDraftSource;
export const TestCompanyTopBar = () => {
	const lang = GeneralLangFactory();
	const { isMobile } = useDeviceQuery();

	const [isExpanded, setExpanded] = React.useState(false);
	const toggleExpanded = () => setExpanded(!isExpanded);
	const { memberships } = useUserMemberships();

	const createCompanyRoute =
		memberships && memberships.partners.length > 0
			? getRoute("preCreateClient", { step: "choose-type", source: "select-company" }, { agencyId: undefined })
			: getRoute("createCompany", { id: undefined, step: undefined }, { source: CompanyDraftSource.TestCompanyBanner });

	const createCompanyButton = (
		<Button appearance="secondary" route={createCompanyRoute} type="link" stretch={isMobile}>
			{lang.TestCompanyTopBar_SetUp_Action}
		</Button>
	);

	if (isMobile) {
		return (
			<ExpandableSection expanded={isExpanded} onChange={toggleExpanded}>
				<ExpandableSectionHeader
					className={styles.textHeader}
					headerText={lang.TestCompanyTopBar_Header}
					color="dark"
				/>
				<ExpandableSectionContent>
					<div className={styles.testCompanyTopBar}>
						<div className={styles.textContent}>
							<span className={styles.description}>{lang.TestCompanyTopBar_Description}</span>
						</div>
						{createCompanyButton}
					</div>
				</ExpandableSectionContent>
			</ExpandableSection>
		);
	}

	return (
		<div className={styles.testCompanyTopBar} data-testid="TestCompanyTopBar">
			<div className={styles.textContent}>
				<span className={styles.textHeader}>{lang.TestCompanyTopBar_Header}</span>
				<span className={styles.description}>{lang.TestCompanyTopBar_Description}</span>
			</div>
			{createCompanyButton}
		</div>
	);
};
