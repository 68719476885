import * as React from "react";

import { mergeClassNames } from "@bokio/utils/classes";

import * as styles from "./expandableSection.scss";

export interface ExpandableSectionContentProps {
	expanded?: boolean;
	withPadding?: boolean;
}

export default class ExpandableSectionContent extends React.Component<ExpandableSectionContentProps> {
	render() {
		if (!this.props.expanded) {
			return null;
		}
		return (
			<div className={mergeClassNames(this.props.withPadding && styles.contentPadding)}>{this.props.children}</div>
		);
	}
}
