import { Config } from "@bokio/shared/config";
import { setAccountingLang } from "@bokio/lang/AccountingLangFactory";

import { setBackOfficeLang } from "./BackOfficeLangFactory";
import { setBankLang } from "./BankLangFactory";
import { setClosuresLang } from "./ClosuresLangFactory";
import { setCountriesLang } from "./CountriesLangFactory";
import { setExpensesLang } from "./ExpensesLangFactory";
import { setGeneralLang } from "./GeneralLangFactory";
import { setInvoicesLang } from "./InvoicesLangFactory";
import { setOnboardingLang } from "./OnboardingLangFactory";
import { setPartnersLang } from "./PartnersLangFactory";
import { setPayslipCalculationsLang } from "./PayslipCalculationsLangFactory";
import { setRecipesLang } from "./RecipesLangFactory";
import { setSalaryLang } from "./SalaryLangFactory";
import { setSettingsLang } from "./SettingsLangFactory";
import { setSignUpLang } from "./SignUpLangFactory";

/*
 * Language handling hack. This is to be able to update each language without creating a dependency on all of them that will load the whole tree.
 */
export type LangListener = (lang: string) => void;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type LangLoader = (lang: string) => Promise<any>;

class LanguageNotifier {
  listeners: LangListener[] = [];
  currentLang = "";

  load = (lang: string) => {
    if (this.currentLang !== lang) {
      this.currentLang = lang;

      return Promise.all([
        setAccountingLang(lang),
        setClosuresLang(lang),
        setExpensesLang(lang),
        setGeneralLang(lang),
        setInvoicesLang(lang),
        setPartnersLang(lang),
        setPayslipCalculationsLang(lang),
        setRecipesLang(lang),
        setSalaryLang(lang),
        setSettingsLang(lang),
        setSignUpLang(lang),
        setOnboardingLang(lang),
        setCountriesLang(lang),
        setBackOfficeLang(lang),
        setBankLang(lang),
      ]).then(data => {
        Config.setup({ lang });
        this.listeners.forEach(listener => listener(lang));

        return data;
      });
    }

    return Promise.resolve([]);
  };

  subscribe = (item: LangListener) => {
    this.listeners.push(item);
  };
}

export const languageNotifier = new LanguageNotifier();
