import * as React from "react";

import { FancyModal } from "@bokio/components/FancyModal/FancyModal";
import { Button, ButtonGroup } from "@bokio/designsystem/components/Button";
import { Div, Paragraph, TextSpan } from "@bokio/designsystem/components/TypographicElements/TypographicElements";
import { Link } from "@bokio/elements/Link/Link";
import { BankLangFactory, GeneralLangFactory } from "@bokio/lang";
import { useCompanyInfo } from "@bokio/mobile-web-shared/core/contexts/CompanyInfoContext/CompanyInfoContext";
import { getRoute } from "@bokio/shared/route";
import { formatMessage } from "@bokio/shared/utils/format";

interface BbaNotAvailableInFreePlanModalProps {
	resolve: () => void;
}

export const BbaNotAvailableInFreePlanModal: React.FC<BbaNotAvailableInFreePlanModalProps> = ({ resolve }) => {
	const { companyInfo } = useCompanyInfo();
	const generalLang = GeneralLangFactory();
	const bankLang = BankLangFactory();

	const paragraph1 = formatMessage(bankLang.BbaNotAvailableInFreePlanModal_Content_1, minimumPlan => (
		<TextSpan style="body-bold">{minimumPlan}</TextSpan>
	));
	const paragraph2 = formatMessage(
		bankLang.BbaNotAvailableInFreePlanModal_Content_2,
		dueDate => <TextSpan style="body-bold">{dueDate}</TextSpan>,
		readMore => (
			<Link external="https://www.bokio.se/priser/prisandring-fragor-svar/#foretagskonto" target="_blank">
				{readMore}
			</Link>
		),
	);

	return (
		<FancyModal
			visible={true}
			title={bankLang.BbaNotAvailableInFreePlanModal_Title}
			onClose={resolve}
			graphic="figuresWithLogo"
			width="extraWide"
		>
			<Paragraph>{paragraph1}</Paragraph>
			<Paragraph>{paragraph2}</Paragraph>
			<Div>
				<ButtonGroup>
					<Button
						appearance="primary"
						type="link"
						route={getRoute("billing", { company: companyInfo.Id }, { from: "bbaFreePlan" })}
						onNavigation={resolve}
					>
						{generalLang.UpgradePlan_CTA}
					</Button>
					<Button appearance="secondary" type="button" onClick={resolve}>
						{generalLang.MaybeLater}
					</Button>
				</ButtonGroup>
			</Div>
		</FancyModal>
	);
};
