import * as React from "react";
import { useLocation } from "react-router-dom";

import { usePricePlanContext } from "@bokio/contexts/PricePlanContext/PricePlanContext";
import { Button } from "@bokio/designsystem/components/Button";
import { useDeviceQuery } from "@bokio/elements/DeviceQuery/useDeviceQuery";
import Icon from "@bokio/elements/Icon/Icon";
import { Link } from "@bokio/elements/Link/Link";
import { LoadingContent } from "@bokio/elements/Loading";
import { useFeatureAvailability } from "@bokio/hooks/useFeatureAvailability/useFeatureAvailability";
import { useOptimisticSetter } from "@bokio/hooks/useOptimisticSetter/useOptimisticSetter";
import { useUserPreference } from "@bokio/hooks/useUserPreference/useUserPreference";
import { GeneralLangFactory } from "@bokio/lang";
import * as m from "@bokio/mobile-web-shared/core/model/model";
import { getRoute } from "@bokio/shared/route";
import { useCompanyUser } from "@bokio/shared/state/requests";
import { formatDate, formatDateWithShortMonthAndYear, formatMessage } from "@bokio/shared/utils/format";
import { mergeClassNames } from "@bokio/utils/classes";

import { getProgressBarProps } from "../../utils/MenuHelper";
import { PlanProgressBar } from "./PlanProgressBar/PlanProgressBar";

import * as styles from "./menuCurrentPlan.scss";

import UserPreferenceType = m.Entities.UserPreferenceType;
interface MenuCurrentPlanProps {
	companyId: string;
	hasActiveBusinessAccount: boolean;
	onLinkClick: () => void;
}

export const MenuCurrentPlan = ({ companyId, onLinkClick, hasActiveBusinessAccount }: MenuCurrentPlanProps) => {
	const { plan, planName, canPurchase, plannedChange, nextPlan, nextPlanName, request, inTrial } =
		usePricePlanContext();

	const { Plans, BokioBusinessAccount } = useFeatureAvailability();

	const generalLang = GeneralLangFactory();
	const { companyUserPermissions } = useCompanyUser();
	const userPreferences = useUserPreference(companyId, UserPreferenceType.MenuCurrentPlanCollapsed);
	const { isDesktop } = useDeviceQuery({ minDesktopWidth: 1024 }); // Use 1024 to match media queries in Menu

	const location = useLocation();
	const currentUrl = location.pathname;
	const isBillingPage = currentUrl === getRoute("billing", { company: companyId });

	const collapsed = useOptimisticSetter({
		currentValue: !!userPreferences.preferenceValue?.MenuCurrentPlanCollapsed?.Value,
		setter: async state => {
			await userPreferences.setPreference({
				isCompanySpecific: true,
				value: {
					MenuCurrentPlanCollapsed: { Value: state },
				},
			});
		},
	});

	if (!(companyUserPermissions?.CompanySettings && Plans)) {
		return null;
	}

	if (plan === m.Entities.BokioPlan.Free) {
		return null;
	}

	const isCollapsed = collapsed.value && isDesktop;
	const willDowngradeAfterTrialEnd = inTrial && nextPlan === m.Entities.BokioPlan.Free;

	const plannedChangeText = () => {
		return plannedChange && (willDowngradeAfterTrialEnd || nextPlan === m.Entities.BokioPlan.Free) ? (
			<span className={inTrial ? styles.trialEnd : styles.end} data-testid="MenuCurrentPlan_PlannedChange">
				{inTrial
					? formatMessage(
							generalLang.PricePlan_TrialUntil,
							formatDateWithShortMonthAndYear(m.Day.addDays(plannedChange, -1)),
						)
					: formatMessage(generalLang.PricePlan_EndsOn, formatDate(m.Day.addDays(plannedChange, -1)))}
			</span>
		) : null;
	};

	const trialText = () => {
		if (!inTrial || isCollapsed || !willDowngradeAfterTrialEnd) {
			return null;
		}
		const planInfoStyle = isCollapsed ? styles.planInfoDataCollapsed : styles.planInfoData;

		return (
			<span className={planInfoStyle} data-testid="MenuCurrentPlan_PricePlan_MenuText">
				{generalLang.PricePlan_MenuText}
			</span>
		);
	};

	const formattedPlanName = (
		plan: m.Entities.BokioPlan | undefined,
		planName: string | undefined,
	): string | React.ReactNode => {
		if (plan === m.Entities.BokioPlan.Pro) {
			return (
				<span data-testid="MenuCurrentPlan_PlanName_PremiumPlus">
					Premium <span className={styles.hightlightPlus}>Plus</span>
				</span>
			);
		}

		return planName;
	};

	const trialPlanName = () => {
		return willDowngradeAfterTrialEnd || (inTrial && !nextPlan) ? (
			<span className={styles.planName} data-testid={`MenuCurrentPlan_Status_Trial`}>
				{generalLang.PricePlanTrial}
			</span>
		) : (
			<span className={styles.planName} data-testid={"MenuCurrentPlan_PlanName"}>
				{formattedPlanName(nextPlan, nextPlanName)}
			</span>
		);
	};

	const bbaInfoText = () => {
		return BokioBusinessAccount && !willDowngradeAfterTrialEnd ? (
			hasActiveBusinessAccount ? (
				<span className={styles.bbaInfo}>{generalLang.PricePlan_WithBusinessAccount}</span>
			) : (
				<span className={styles.bbaInfo}>{generalLang.PricePlan_WithoutBusinessAccount}</span>
			)
		) : null;
	};
	const renderPlanInfo = () => {
		return (
			<div className={styles.planInfo}>
				{inTrial && trialPlanName()}
				{!inTrial && (
					<span className={styles.planName} data-testid={"MenuCurrentPlan_PlanName"}>
						{formattedPlanName(plan, planName)}
					</span>
				)}
				{plannedChangeText()}
				{trialText()}
				{bbaInfoText()}
			</div>
		);
	};

	// GF: If the progress bar shouldn't show yet then 'progressBarProps' will be null and the "classic" appearence will show.
	const progressBarProps = getProgressBarProps(plannedChange, inTrial);
	const accessExpiring = progressBarProps && (inTrial || nextPlan == m.Entities.BokioPlan.Free);

	const planMainClass = (plan: m.Entities.BokioPlan): string => {
		switch (plan) {
			case m.Entities.BokioPlan.Pro:
				return styles.premiumPlus;

			default:
				return styles.na;
		}
	};
	const trialClassName = inTrial && styles.inTrial;

	const planClass = plan || nextPlan;
	const planClassNames =
		planClass && !accessExpiring // GF: If accessExpiring then we style with Plus.
			? mergeClassNames(styles.plan, planMainClass(planClass), trialClassName)
			: mergeClassNames(styles.plan, trialClassName);

	const showPlanTitle = (!inTrial || !willDowngradeAfterTrialEnd) && !isCollapsed && !accessExpiring;
	return (
		<div className={planClassNames}>
			{request.isLoading || !userPreferences.getPreferenceRequest.data ? (
				<LoadingContent lines={1} padding={true} />
			) : (
				<>
					{showPlanTitle && (
						<div className={styles.title} data-testid={"MenuCurrentPlan_PlanHeading"}>
							{generalLang.MenuCurrentPlan_PricePlan_Title}
						</div>
					)}
					{!inTrial && isCollapsed && <span className={styles.planName}>{formattedPlanName(plan, planName)}</span>}
					{inTrial && isCollapsed && trialPlanName()}
					{!isCollapsed && (
						<>
							{!accessExpiring && renderPlanInfo()}
							{accessExpiring && (
								<PlanProgressBar
									daysRemanining={progressBarProps?.daysRemanining}
									percent={progressBarProps?.progressPercentage}
									headingText={progressBarProps?.heading}
									visibleNumbericalProgress={false}
									isLosingPlanAccess={!inTrial}
								/>
							)}
							<div className={styles.buttonContainer}>
								{accessExpiring ? (
									<Button
										route={getRoute("billing", { company: companyId }) + "?from=menu"}
										type="link"
										appearance="secondary"
										stretch
										testId="MenuCurrentPlan_ViewPlans"
										size="small"
										onNavigation={onLinkClick}
										disabled={isBillingPage}
									>
										{inTrial ? generalLang.PricePlan_SelectPlan : generalLang.PricePlan_ReactivatePlan}
									</Button>
								) : !canPurchase ? (
									<Button
										route={getRoute("billing", { company: companyId }) + "?from=menu"}
										type="link"
										appearance="secondary"
										stretch
										testId="MenuCurrentPlan_ViewPlans"
										size="small"
										onNavigation={onLinkClick}
									>
										{generalLang.PricePlan_ViewPlans}
									</Button>
								) : (
									(canPurchase || !hasActiveBusinessAccount) && (
										<Button
											route={getRoute("billing", { company: companyId }) + "?from=menu"}
											type="link"
											appearance="primary"
											stretch
											testId="MenuCurrentPlan_Upgrade"
											size="small"
											onNavigation={onLinkClick}
										>
											{generalLang.PricePlan_Upgrade}
										</Button>
									)
								)}
							</div>
						</>
					)}
					{!accessExpiring && (
						<Link // GF: Should not be collapsable when ProgressBar is shown.
							style="none"
							onClick={() => collapsed.setValue(!isCollapsed)}
							className={styles.toggle}
							testId="MenuCurrentPlan_collapseLink"
						>
							<Icon name={isCollapsed ? "up-open-big" : "down-open-big"} className={styles.arrow} />
						</Link>
					)}
				</>
			)}
		</div>
	);
};
