import * as React from "react";

import LabelFor from "@bokio/elements/Form/LabelFor/LabelFor";
import Icon from "@bokio/elements/Icon/Icon";
import classes, { mergeClassNames } from "@bokio/utils/classes";

import type { SelectOptionProps } from "@bokio/elements/Select/SelectOption";
import type { RuleValidationResult } from "@bokio/shared/validation/FieldRuleFactory";

import * as styles from "./select.scss";

export interface SelectProps<TValue> {
	selectedValue?: TValue;
	onChange: (e: Event, value: TValue) => void;
	label?: string;
	fullWidth?: boolean;
	className?: string;
	labelClassName?: string;
	selectedClassName?: string;
	btnClassName?: string;
	disabled?: boolean;
	testId?: string;
	placeholder?: string;
	comparisonFn?: (value: TValue, selectedValue?: TValue) => boolean;
	children: React.ReactNode;
	errors?: RuleValidationResult[];
}

const valuesAreEqual = <TValue,>(propValue: TValue, value?: TValue) => {
	if (
		value &&
		((typeof propValue === "string" && typeof value === "number") ||
			(typeof value === "string" && typeof propValue === "number"))
	) {
		return propValue.toString() === value.toString();
	}

	return propValue === value;
};

export const Select = <TValue,>({
	onChange,
	comparisonFn = valuesAreEqual,
	btnClassName,
	className,
	fullWidth,
	disabled,
	label,
	placeholder,
	labelClassName,
	selectedClassName,
	selectedValue,
	testId,
	children,
	errors,
}: SelectProps<TValue>) => {
	const [show, setShow] = React.useState(false);

	const toggleDropDown = () => {
		setShow(show => !show);
	};

	const handleBlur = () => {
		setShow(false);
	};

	const getSelectedLabel = (value?: TValue) => {
		const childrenArray = React.Children.toArray(children);
		const selectedOptions = childrenArray.filter(c =>
			comparisonFn((c as React.ReactElement<SelectOptionProps<TValue>>).props.value, value),
		);
		if (!selectedOptions.length) {
			return null;
		}
		return (selectedOptions[0] as React.ReactElement<SelectOptionProps<TValue>>).props.labelWhenSelected;
	};

	const selectedValueChanged = (e: Event, value: TValue) => {
		setShow(false);
		onChange(e, value);
	};

	const renderChildren = (children: React.ReactNode) => {
		return React.Children.map(children, child => {
			const c = child as JSX.Element;
			return React.cloneElement(c, {
				...c.props,
				onClick: selectedValueChanged,
			});
		});
	};

	const labelWhenSelected = getSelectedLabel(selectedValue);

	const selectClass = mergeClassNames(
		classes(styles, "selectContainer", { selectContainerFullwidth: fullWidth }),
		className,
	);

	return (
		<LabelFor label={label} className={labelClassName} errors={errors}>
			<div className={selectClass}>
				<button
					data-testid={testId}
					type="button"
					onClick={toggleDropDown}
					onBlur={handleBlur}
					className={mergeClassNames(styles.selectButton, btnClassName)}
					disabled={disabled}
				>
					{labelWhenSelected || selectedValue ? (
						<span className={selectedClassName}>{labelWhenSelected || selectedValue}</span>
					) : (
						<span className={styles.placeholder}>{placeholder}</span>
					)}
					{show ? (
						<Icon name="up-open-big" className={styles.selectButtonArrowIcon} size="18" />
					) : (
						<Icon name="down-open-big" className={styles.selectButtonArrowIcon} size="18" />
					)}
				</button>
				<div className={!show ? styles.selectDropDown : styles.selectDropDownVisible}>
					{show && renderChildren(children)}
				</div>
			</div>
		</LabelFor>
	);
};
