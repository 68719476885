import * as React from "react";

import { BokioPlanFeatureButton } from "@bokio/components/BokioPlanFeatureButton/BokioPlanFeatureButton";
import { HelpLink } from "@bokio/components/HelpLink/HelpLink";
import { UploadFileButton } from "@bokio/components/UploadFileButton/UploadFileButton";
import { Button } from "@bokio/designsystem/components/Button";
import { SG } from "@bokio/designsystem/components/SpacingGroup/SpacingGroup";
import { Heading, Paragraph } from "@bokio/designsystem/components/TypographicElements/TypographicElements";
import { Column, Grid } from "@bokio/elements/Grid";

import FeedbackGraphic from "../Feedback/FeedbackGraphic";
import { Link } from "../Link/Link";

import type { FeedbackGraphicType } from "../Feedback/FeedbackGraphic";
import type { BokioPlanFeatureButtonProps } from "@bokio/components/BokioPlanFeatureButton/useBokioPlanFeatureButton";
import type { HelpLinkProps } from "@bokio/components/HelpLink/HelpLink";
import type { ExternalLinkButtonProps, NormalButtonProps } from "@bokio/designsystem/components/Button/button.types";

import * as styles from "./emptyState3.scss";

interface LinkProps {
	route?: string;
	external?: string;
	target?: string;
	onClick?: () => void;
	text: string;
	testId?: string;
}

interface SubmitButtonProps {
	text: string;
	type: "submit";
	route?: undefined;
	onClick: () => void;
	testId?: string;
}

interface LinkButtonProps {
	text: string;
	type: "link";
	route: string;
	onClick?: undefined;
	testId?: string;
}

interface UpLoadFilesButtonProps {
	text: string;
	type: "upLoadFiles";
	onSelectedFilesChange: (file: File[]) => void;
	acceptFileType?: string;
	allowMultiple?: boolean | undefined;
	onClick?: undefined;
	testId?: string;
}

type SecondaryLinkProps = LinkProps & {
	discriminator: "link";
};

type SecondaryHelpLinkProps = HelpLinkProps & {
	discriminator: "helpLink";
};

type SecondaryButtonProps = (SubmitButtonProps | LinkButtonProps) & {
	discriminator: "button";
};

interface EmptyState3Props {
	title?: string;
	message: React.ReactNode;
	graphic?: FeedbackGraphicType;
	children?: never;
	primaryAction?: (
		| NormalButtonProps
		| LinkButtonProps
		| ExternalLinkButtonProps
		| SubmitButtonProps
		| UpLoadFilesButtonProps
	) &
		// eslint-disable-next-line @typescript-eslint/ban-types
		({} | BokioPlanFeatureButtonProps) & { text: string };
	secondaryAction?: SecondaryLinkProps | SecondaryButtonProps | SecondaryHelpLinkProps;
}

/**
 * https://www.figma.com/file/ltKx4EC1ytdkbN6PMp00bn/Bokio-Web-Components?node-id=1416%3A3949
 */
export const EmptyState3: React.FC<EmptyState3Props> = props => {
	const renderPrimaryButton = () => {
		if (!props.primaryAction) {
			return null;
		}

		if ("upLoadFiles" === props.primaryAction.type) {
			return (
				<UploadFileButton
					allowMultiple={props.primaryAction.allowMultiple}
					acceptFileType={props.primaryAction.acceptFileType}
					onSelectedFilesChange={props.primaryAction.onSelectedFilesChange}
				>
					{props.primaryAction.text}
				</UploadFileButton>
			);
		}

		if ("feature" in props.primaryAction) {
			return (
				<BokioPlanFeatureButton {...props.primaryAction} appearance="primary">
					{props.primaryAction.text}
				</BokioPlanFeatureButton>
			);
		}

		return (
			<Button {...props.primaryAction} appearance="primary">
				{props.primaryAction.text}
			</Button>
		);
	};

	const renderSecondaryAction = (): JSX.Element | null => {
		if (!props.secondaryAction) {
			return null;
		}

		switch (props.secondaryAction.discriminator) {
			case "link":
				return (
					<Link
						route={props.secondaryAction.route}
						external={props.secondaryAction.external}
						onClick={props.secondaryAction.onClick}
						target={props.secondaryAction.target}
						testId={props.secondaryAction.testId}
					>
						{props.secondaryAction.text}
					</Link>
				);
			case "button":
				return (
					<Button {...props.secondaryAction} appearance="secondary" stretch>
						{props.secondaryAction.text}
					</Button>
				);
			case "helpLink":
				return (
					<HelpLink
						helpArticle={props.secondaryAction.helpArticle}
						testId={props.secondaryAction.testId}
						onClick={props.secondaryAction.onClick}
					>
						{props.secondaryAction.children}
					</HelpLink>
				);
		}
	};

	return (
		<Grid medium="row" justify="center">
			<Column className={styles.col}>
				<SG section gap="16" align="center">
					<FeedbackGraphic type={props.graphic ?? "emptyState"} />
					<SG gap="8" align="center">
						{props.title && <Heading style="body-bold">{props.title}</Heading>}
						<Paragraph>{props.message}</Paragraph>
					</SG>
					{!!(props.primaryAction || props.secondaryAction) && (
						<SG gap="8" align="center">
							{renderPrimaryButton()}
							{renderSecondaryAction()}
						</SG>
					)}
				</SG>
			</Column>
		</Grid>
	);
};
