import * as React from "react";

import { SG } from "@bokio/designsystem/components/SpacingGroup/SpacingGroup";
import { Paragraph } from "@bokio/designsystem/components/TypographicElements/TypographicElements";
import { Feedback } from "@bokio/elements/Feedback";
import { LoadingSpinner } from "@bokio/elements/Loading";
import { GeneralLangFactory } from "@bokio/lang";

interface ProcessingBlockProps {
	message?: string;
  }

export function ProcessingBlock({ message = "" }: ProcessingBlockProps) {
	const generalLang = GeneralLangFactory();
	return (
		<Feedback>
			<SG gap="16" align="center">
				<LoadingSpinner color="blue" />
				<Paragraph testId="SveaOnboarding_Processing">{message ? message : `${generalLang.Processing}...`}</Paragraph>
			</SG>
		</Feedback>
	);
}

