import { trackException, trackTrace } from "@bokio/utils/t";

import type { State } from "./state";

// NE 2018-08-23
// Persists the redux state.
// See https://egghead.io/lessons/javascript-redux-persisting-the-state-to-the-local-storage

export const loadState = () => {
	if (!localStorage) {
		trackTrace("localstorage is not defined", {}, "warn");
		return;
	}
	try {
		const serializedState = localStorage.getItem("state");
		if (serializedState === null) {
			return undefined;
		}
		return JSON.parse(serializedState);
	} catch (err) {
		return undefined;
	}
};

export const saveState = (state: State) => {
	if (!localStorage) {
		trackTrace("localstorage is not defined", {}, "warn");
		return;
	}
	try {
		const stateToPersist = {
			// NE 2018-08-23
			// All state cannot be persisted because of the way our state is organized.
			// Most state needs to be cleared on every page load.
			// Manually add state that should be persisted below:
			logins: state.logins,
		};
		const serializedState = JSON.stringify(stateToPersist);
		localStorage.setItem("state", serializedState);
	} catch (err) {
		// Ignore write errors
		trackException(err, "persist.ts", {});
	}
};
