import * as React from "react";

import { Article, Heading, Section } from "@bokio/designsystem/components/TypographicElements/TypographicElements";
import Icon from "@bokio/elements/Icon/Icon";
import { noop } from "@bokio/shared/utils";
import { mergeClassNames } from "@bokio/utils/classes";

import ModalBase from "../Modal/ModalBase";
import { getBackgroundColorClass, getGraphic, getImageClassName, getSizeClassName } from "./fancyModal.utils";

import type {
	FancyModalBackgroundColor,
	FancyModalGraphic,
	FancyModalImageSize,
	FancyModalWidth,
} from "./fancyModal.utils";

import * as styles from "./fancyModal.scss";

interface FancyModalProps {
	title: string;
	visible: boolean;
	onClose: () => void;
	backgroundColor?: FancyModalBackgroundColor;
	graphic?: FancyModalGraphic;
	customGraphic?: string;
	imageSize?: FancyModalImageSize;
	width?: FancyModalWidth;
	testId?: string;
}

export const FancyModal: React.FC<FancyModalProps> = ({
	title,
	visible,
	onClose,
	children,
	width,
	testId,
	backgroundColor,
	graphic,
	customGraphic,
	imageSize,
}) => {
	return (
		<ModalBase
			testId={testId}
			title=""
			visible={visible}
			autoFocus={false}
			onBackgroundClick={noop}
			onCloseButtonClick={noop}
			onEscape={noop}
			className={mergeClassNames(styles.modal, getBackgroundColorClass(backgroundColor || "white"))}
			width={width}
			alwaysMounted={false}
		>
			<div className={styles.closeWrapper}>
				<button
					onClick={onClose}
					className={styles.closeButton}
					type="button"
					data-testid={testId && `${testId}_CloseButton`}
				>
					<Icon name="cancel" className={styles.closeIcon} />
				</button>
			</div>
			<Section>
				<div className={mergeClassNames(styles.content, getSizeClassName(width))}>
					<Article resetHeadingLevelTo={1}>
						<Heading style="large-heading">{title}</Heading>
						{children}
					</Article>
					<div className={styles.imageContainer}>
						<img
							src={customGraphic || getGraphic(graphic)}
							aria-hidden="true"
							className={getImageClassName(imageSize || "medium")}
						/>
					</div>
				</div>
			</Section>
		</ModalBase>
	);
};
