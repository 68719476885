import { goBack, goForward, push, replace } from "connected-react-router";
import { useRef } from "react";

import { useRedux } from "@bokio/hooks/useRedux/useRedux";
import { trackException } from "@bokio/utils/t";
import { withCurrentReturnUrl } from "@bokio/utils/url";

import type { State } from "@bokio/shared/state/state";
import type { Location } from "history";

// Explicitly declared route state types.
// Prefer optional types so we don't have to explicitly declare everything when we only care about one of the props.
export interface BokioRouterLocationState {
	isRedirectedFromNotification?: boolean;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	signUpSceneProps?: any;
}

interface RouterPropsFromDispatch {
	push: (path: string, state?: BokioRouterLocationState) => void;
	replace: (path: string, state?: BokioRouterLocationState) => void;
	goBack: () => void;
	goForward: () => void;
}

interface RouterPropsFromState {
	location?: Location<BokioRouterLocationState>;
}

interface RouterPropsFromMerge {
	redirect: (path: string, state?: BokioRouterLocationState) => void;
	redirectHard: (path: string) => void;
}

export type RouterProps = RouterPropsFromDispatch & RouterPropsFromState & RouterPropsFromMerge;

const generateLocation = (state: State): Location<BokioRouterLocationState> => {
	return (state.router.location as Location<BokioRouterLocationState>) || undefined;
};

/**
 * Tips for unit testing:
 * See the `history` prop from `renderApp(...)` for example of testing.
 */
export const useRouter = (): RouterProps => {
	const combined = useRedux(
		state => ({
			location: generateLocation(state),
		}),
		dispatch => ({
			push: (path: string, state?: BokioRouterLocationState) => dispatch(push(path, state)),
			replace: (path: string, state?: BokioRouterLocationState) => {
				try {
					dispatch(replace(path, state));
				} catch (err) {
					trackException(err, "withRouterControls", { location });
				}
			},
			goBack: () => {
				dispatch(goBack());
			},
			goForward: () => {
				dispatch(goForward());
			},
		}),
		(stateProps, dispatchProps) => ({
			redirect: (path: string, state?: BokioRouterLocationState) => {
				dispatchProps.push(withCurrentReturnUrl(stateProps.location, path), state);
			},
			redirectHard: (path: string) => {
				location.href = withCurrentReturnUrl(stateProps.location, path);
			},
		}),
	);

	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	const routerRef = useRef<RouterProps>({} as RouterProps);
	routerRef.current.goBack = combined.goBack;
	routerRef.current.goForward = combined.goForward;
	routerRef.current.location = combined.location;
	routerRef.current.push = combined.push;
	routerRef.current.redirect = combined.redirect;
	routerRef.current.redirectHard = combined.redirectHard;
	routerRef.current.replace = combined.replace;

	return routerRef.current;
};
