/* eslint-disable */

import type { ExpensesLang } from "./types/ExpensesLang";
import { translateTempImpl } from "./TranslatableLanguage";

let data: ExpensesLang | undefined;

export type { ExpensesLang };

export default function ExpensesLangFactory(): ExpensesLang {
  if (!data) {
    throw new Error("Trying to load a language in the main bundle before the language bundle is loaded");
  }
  return data;
}

export let setExpensesLang = (newLang: string) => {
  if (newLang === "ky-KG") {
    setExpensesLang = (nl:string) => {
      const storedLangData = window.sessionStorage.getItem("bokioLang."+ nl + ".Expenses");
      const rawData = JSON.parse(storedLangData || "");
      rawData.translateTemp = (key: string, neutral: string, svSE?: string, enGB?: string) => translateTempImpl(key, neutral, svSE, enGB);
      data = rawData;
      return Promise.resolve();
    }
    import(/* webpackChunkName: "en-GB.locale" */ "./en-GB/ExpensesLangDef").then(({ ExpensesLangDef }) => {
      let dataToStore = ExpensesLangDef();
      window.sessionStorage.setItem("bokioLang.en-GB.Expenses", JSON.stringify(dataToStore));
    });
    import(/* webpackChunkName: "sv-SE.locale" */ "./sv-SE/ExpensesLangDef").then(({ ExpensesLangDef }) => {
      let dataToStore = ExpensesLangDef();
      window.sessionStorage.setItem("bokioLang.sv-SE.Expenses", JSON.stringify(dataToStore));
    });
    import(/* webpackChunkName: "en-SE.locale" */ "./en-SE/ExpensesLangDef").then(({ ExpensesLangDef }) => {
      let dataToStore = ExpensesLangDef();
      window.sessionStorage.setItem("bokioLang.en-SE.Expenses", JSON.stringify(dataToStore));
    });
    import(/* webpackChunkName: "ky-KG.locale" */ "./ky-KG/ExpensesLangDef").then(({ ExpensesLangDef }) => {
      let dataToStore = ExpensesLangDef();
      window.sessionStorage.setItem("bokioLang.ky-KG.Expenses", JSON.stringify(dataToStore));
    });
  }
  if (newLang === "en-GB") {
      return import(/* webpackChunkName: "en-GB.locale" */ "./en-GB/ExpensesLangDef").then(({ ExpensesLangDef }) => {
      data = ExpensesLangDef();
    });
  } else if (newLang === "sv-SE") {
      return import(/* webpackChunkName: "sv-SE.locale" */ "./sv-SE/ExpensesLangDef").then(({ ExpensesLangDef }) => {
      data = ExpensesLangDef();
    });
  } else if (newLang === "en-SE") {
      return import(/* webpackChunkName: "en-SE.locale" */ "./en-SE/ExpensesLangDef").then(({ ExpensesLangDef }) => {
      data = ExpensesLangDef();
    });
  } else if (newLang === "ky-KG") {
      return import(/* webpackChunkName: "ky-KG.locale" */ "./ky-KG/ExpensesLangDef").then(({ ExpensesLangDef }) => {
      data = ExpensesLangDef();
    });
  }
  else {
    throw new Error("Unsupported language/culture " + newLang);
  }
};