import * as React from "react";
import { useSelector } from "react-redux";

import { getCurrentCompanyId, getCurrentPath } from "@bokio/shared/state/router/selectors";

import { useRouter } from "./useRouter";

import type { RouterProps } from "./useRouter";
import type { Infer } from "@bokio/shared/containers/connect";
import type { State } from "@bokio/shared/state/state";

export interface RouterControlsProps extends RouterProps {
	currentCompanyId?: string;
	currentPath?: string;
}

/**
 * @deprecated This is just a wrapper around the useRouter hook. Use the hook directly instead.
 */
const withRouterControls: Infer<RouterControlsProps> = <OwnProps>(
	component: React.ComponentType<OwnProps & RouterControlsProps>,
) => {
	const ConnectedComponent: React.ComponentType<OwnProps> = ownProps => {
		const routerProps = useRouter();
		const routeInfo = useSelector((state: State) => ({
			currentCompanyId: getCurrentCompanyId(state),
			currentPath: getCurrentPath(state),
		}));
		return React.createElement(component, { ...ownProps, ...routerProps, ...routeInfo });
	};
	return ConnectedComponent;
};

export default withRouterControls;
