import * as React from "react";

import Icon from "@bokio/elements/Icon/Icon";
import { mergeClassNames } from "@bokio/utils/classes";

import * as styles from "./toggleSwitch.scss";

interface ToggleSwitchProps {
	checked: boolean;
	onChange: (value: boolean) => void;
	size?: "medium" | "small";
	className?: string;
	testId?: string;
	disabled?: boolean;
}

const ToggleSwitch: React.FC<ToggleSwitchProps> = props => {
	const labelClass = mergeClassNames(
		styles.label,
		props.checked && styles.active,
		props.disabled && styles.disabled,
		props.size && styles[props.size],
	);

	const handleChange = () => {
		props.onChange(!props.checked);
	};

	return (
		<React.Fragment>
			<label className={mergeClassNames(labelClass, props.className)} data-testid={props.testId}>
				<input
					type="checkbox"
					className={styles.input}
					onChange={handleChange}
					checked={props.checked}
					disabled={props.disabled}
				/>
				{props.checked ? (
					<span className={styles.labelYes}>
						<Icon name="check" size={props.size === "small" ? "18" : "24"} />
					</span>
				) : (
					<span className={styles.labelNo}>
						<Icon name="cancel" size={props.size === "small" ? "18" : "24"} />
					</span>
				)}
			</label>
		</React.Fragment>
	);
};

ToggleSwitch.defaultProps = {
	size: "small",
};

export default ToggleSwitch;
