import type { State } from "../state";
import type { Location } from "history";

export const getCurrentRoute = (state: State): Location | undefined => {
	const location = state.router.location;
	return location || undefined;
};

export const getCurrentPath = (state: State): string | undefined => {
	const route = getCurrentRoute(state);
	return route ? route.pathname : undefined;
};

export const getCurrentCompanyId = (state: State): string | undefined => {
	if (!state.router.location) {
		return;
	}

	const location = state.router.location.pathname;
	const companyIdMatch = location
		.substr(1, location.length)
		.match(/^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/);

	if (companyIdMatch && companyIdMatch.length) {
		return companyIdMatch[0];
	}

	return;
};

export const getCurrentAgencyId = (state: State): string | undefined => {
	if (!state.router.location) {
		return;
	}

	const location = state.router.location.pathname;
	const agencyIdMatch = location
		.substr(1, location.length)
		.match(/^(?:backoffice|agencies)\/([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})/);

	if (agencyIdMatch && agencyIdMatch.length) {
		return agencyIdMatch[1];
	}

	return;
};

export const getCurrentUserId = (state: State): string | undefined => {
	const userIdFromGetUser = state.requests.getUser.data && state.requests.getUser.data.Id;
	if (userIdFromGetUser) {
		return userIdFromGetUser;
	}
	return state.requests.getCompanyUser.data && state.requests.getCompanyUser.data.UserId;
};

export const getCurrentEmployeeId = (state: State): string | undefined => {
	return state.requests.getCompanyUser.data && state.requests.getCompanyUser.data.EmployeeId;
};

export const getCurrentLang = (state: State): string | undefined => {
	return state.requests.getCompanyUser.data && state.requests.getCompanyUser.data.UserConfiguration.Lang;
};
