import * as React from "react";

import { InputField } from "./InputField";

import type { InputFieldProps } from "./InputField";

/**
 * Remove hyphen and pad 10 digits personnummer into 12 digits
 */
const formatter = (value: string): string => {
	let newValue = value.replace(/-/g, "").trim();

	if (/^[0-9]{10}$/.test(newValue)) {
		const prefix = Number(newValue.substring(0, 2)) < new Date().getFullYear() % 1000 ? "20" : "19";
		newValue = prefix + newValue;
	}

	return newValue;
};

export const PersonNrField: React.FC<InputFieldProps> = props => (
	<InputField placeholder="YYYYMMDDNNNN" inputMode="numeric" formatter={formatter} {...props} />
);
