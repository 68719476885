import * as React from "react";

import { DropdownMenuItem } from "../DropdownMenuItem/DropdownMenuItem";

import type { DropdownMenuProps } from "../Dropdown.types";

import * as styles from "./dropdownMenu.scss";

export const DropdownMenu: React.FC<DropdownMenuProps> = ({
	items,
	groups,
	testId,
	focusedItemKey,
	setItemRef,
	onItemClick,
}) => {
	return (
		<div className={styles.menu} role="menu" data-testid={testId}>
			<ul className={styles.list}>
				{(items ?? []).map(item => (
					<li key={item.key}>
						<DropdownMenuItem
							focused={item.key === focusedItemKey}
							setItemRef={setItemRef?.(item.key)}
							onClick={() => onItemClick?.(item)}
							item={item}
						/>
					</li>
				))}
				{(groups ?? []).map(group => (
					<li key={group.label} className={styles.group}>
						<span className={styles.groupLabel}>{group.label}</span>
						<ul className={styles.groupList}>
							{group.items.map(item => (
								<li key={item.key}>
									<DropdownMenuItem
										focused={item.key === focusedItemKey}
										setItemRef={setItemRef?.(item.key)}
										onClick={() => onItemClick?.(item)}
										item={item}
									/>
								</li>
							))}
						</ul>
					</li>
				))}
			</ul>
		</div>
	);
};
