import * as React from "react";

import { noop } from "@bokio/shared/utils";
import type { UmbracoReleaseNoteModel } from "@bokio/shared/models/UmbracoReleaseNotesModel";

export type ReleaseNotesContextValue = {
	whatsNewData: UmbracoReleaseNoteModel[];
	hasNewNotesCompany: boolean;
	hasNewNotesAgency: boolean;
	setLastReadPrefernce: () => void;
	noOfUpdates: number;
};
export type ReleaseNoteType = "Agency" | "Company";

export const releaseNotesContext = React.createContext<ReleaseNotesContextValue>({
	whatsNewData: [],
	hasNewNotesCompany: false,
	hasNewNotesAgency: false,
	setLastReadPrefernce: noop,
	noOfUpdates: 0,
});

export const useReleaseNotesContext = () => React.useContext(releaseNotesContext);
