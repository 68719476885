import { connect as reduxConnect } from "react-redux";

import { useRedux } from "@bokio/hooks/useRedux/useRedux";
import * as Api from "@bokio/shared/services/api";
import ApiRequest from "@bokio/shared/services/request/ApiRequest";
import { SessionActions } from "@bokio/shared/state/session/actions";

import type { Infer } from "@bokio/shared/containers/connect";
import type { ApiRequestState } from "@bokio/shared/services/request/ApiRequestState";
import type { State } from "@bokio/shared/state/state";

export type SignInState = ApiRequestState<boolean, string, string>;

export interface SignInProps {
	isSigningIn?: boolean;
	signInError?: string;
	signIn?: (username: string, password: string) => void;
}

export const SignIn = () => {
	const api = new ApiRequest<boolean, string, string>("USER/SIGN_IN", state => state.requests.signIn);

	const execute = (username: string, password: string) => {
		// eslint-disable-next-line @typescript-eslint/ban-types
		return (dispatch: Function) => {
			return api.performRequest(dispatch, username, async () => {
				const response = await Api.signIn(username, password);

				if (response.access_token) {
					dispatch(SessionActions.start(response.access_token));
					return true;
				}

				if (response.error_description) {
					throw response.error_description;
				}

				return false;
			});
		};
	};

	const mapStateToProps = (state: State): SignInProps => ({
		isSigningIn: api.isLoading(state),
		signInError: api.getError(state),
	});

	// eslint-disable-next-line @typescript-eslint/ban-types
	const mapDispatchToProps = (dispatch: Function): SignInProps => ({
		signIn: (username: string, password: string) => {
			dispatch(execute(username, password));
		},
	});

	const connect = () => reduxConnect(mapStateToProps, mapDispatchToProps) as Infer<SignInProps>;
	const useProps = () => useRedux(mapStateToProps, mapDispatchToProps, () => ({}));
	return {
		execute,
		connect,
		useProps,
		getReducer: () => api.getReducer(),
		getParameters: (state: State) => api.getParameters(state),
	};
};
