import * as React from "react";

import { Button } from "@bokio/designsystem/components/Button/Button";

import type { ButtonProps } from "@bokio/designsystem/components/Button/Button";
import type {
	Appearance,
	ExternalLinkButtonProps,
	LinkButtonProps,
	NormalButtonProps,
	SubmitButtonProps,
} from "@bokio/designsystem/components/Button/button.types";

interface BokioBusinessAccountButtonOwnProps {
	hideIcon?: boolean;
	appearance?: Appearance;
	children: React.ReactNode;
}

export type BokioBusinessAccountButtonProps = BokioBusinessAccountButtonOwnProps &
	ButtonProps &
	(NormalButtonProps | LinkButtonProps | ExternalLinkButtonProps | SubmitButtonProps);

export const BokioBusinessAccountButton: React.FC<BokioBusinessAccountButtonProps> = props => {
	return (
		<Button {...props} icon={!props.hideIcon ? "bba-filled" : undefined} iconAlign="right">
			{props.children}
		</Button>
	);
};
