import * as React from "react";

import {
	createPricePlanFeatureContextValue,
	pricePlanFeatureContext,
} from "@bokio/mobile-web-shared/core/contexts/PricePlanFeatureContext/PricePlanFeatureContext";
import { useLazyApi } from "@bokio/mobile-web-shared/hooks/useApi/useApi";
import * as proxy from "@bokio/mobile-web-shared/services/api/proxy";
import { useCompanyUser } from "@bokio/shared/state/requests";

/**
 * We have a similar one in the mobile app under the same name.
 * The provider logic here can be moved into mobile-web-shared
 * if we figure out some way to inject useApi() and the feature toggle check.
 */
export const PricePlanFeatureContextProvider: React.FC = props => {
	const { company } = useCompanyUser();
	const companyId = company?.Id;
	const [pricePlanFeaturesApi, pricePlanFeaturesRequest] = useLazyApi(
		proxy.Bokio.Common.Web.Plan.PricePlanFeatureController.GetAllFeaturesAvailableForCompany.Get,
	);
	const refreshPricePlanFeatures = async () => {
		if (companyId) {
			pricePlanFeaturesApi(companyId);
		}
	};

	React.useEffect(() => {
		if (companyId) {
			pricePlanFeaturesApi(companyId);
		}
	}, [companyId, pricePlanFeaturesApi]);

	const pricePlanFeatures = pricePlanFeaturesRequest.data?.Data ? pricePlanFeaturesRequest.data.Data : [];

	const contextValue = createPricePlanFeatureContextValue({
		refreshPricePlanFeatures,
		pricePlanFeatures,
	});

	return <pricePlanFeatureContext.Provider value={contextValue}>{props.children}</pricePlanFeatureContext.Provider>;
};
