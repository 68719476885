import * as React from "react";

import { useDeviceQuery } from "@bokio/elements/DeviceQuery/useDeviceQuery";
import Markdown from "@bokio/elements/Markdown/Markdown";
import { Notice } from "@bokio/elements/Notice/Notice";
import { GeneralLangFactory } from "@bokio/lang";
import * as m from "@bokio/mobile-web-shared/core/model/model";

import type { BankIdSecureStartLoginView } from "../BankIdSecureStart/BankIdSecureStart";

import BankIdHintCodeEnum = m.Bokio.Common.Contract.BankId.BankIdHintCode;
import BankIdCollectionStatus = m.Bokio.Common.Contract.BankId.BankIdCollectionStatus;

interface BankIdHintCodeProps {
	hintCode: BankIdHintCodeEnum;
	collectionStatus: BankIdCollectionStatus;
	triedToLaunchAppAutomatically: boolean;
	bankIdSecureStartLoginView: BankIdSecureStartLoginView;
}

export const BankIdHintCode = ({
	hintCode,
	collectionStatus,
	triedToLaunchAppAutomatically,
	bankIdSecureStartLoginView,
}: BankIdHintCodeProps) => {
	const generalLang = GeneralLangFactory();
	const { isMobile } = useDeviceQuery();

	function getHintCodeText(hintCode: BankIdHintCodeEnum): React.ReactNode {
		switch (hintCode) {
			case BankIdHintCodeEnum.Unknown:
				return collectionStatus == BankIdCollectionStatus.Failed ? (
					<Notice color="error">{generalLang.BankId_UserMessage_RFA22}</Notice>
				) : (
					generalLang.BankId_UserMessage_RFA21
				);
			case BankIdHintCodeEnum.OutstandingTransaction:
				if (bankIdSecureStartLoginView == "qr") {
					return <Markdown markdownContent={generalLang.BankId_UserMessage_RFA1_QR} useTypographicElements />;
				}

				return triedToLaunchAppAutomatically
					? generalLang.BankId_UserMessage_RFA13
					: generalLang.BankId_UserMessage_RFA1;
			case BankIdHintCodeEnum.NoClient:
				return bankIdSecureStartLoginView == "qr" ? (
					<Markdown markdownContent={generalLang.BankId_UserMessage_RFA1_QR} useTypographicElements />
				) : (
					generalLang.BankId_UserMessage_RFA1
				);
			case BankIdHintCodeEnum.Started:
				return isMobile ? generalLang.BankId_UserMessage_RFA15B : generalLang.BankId_UserMessage_RFA15A;
			case BankIdHintCodeEnum.UserSign:
				return generalLang.BankId_UserMessage_RFA9;
			case BankIdHintCodeEnum.ExpiredTransaction:
				return (
					<Notice color="error">
						<Markdown markdownContent={generalLang.BankId_UserMessage_RFA8} useTypographicElements />
					</Notice>
				);
			case BankIdHintCodeEnum.CertificateErr:
				return (
					<Notice color="error">
						<Markdown markdownContent={generalLang.BankId_UserMessage_RFA16} useTypographicElements />
					</Notice>
				);
			case BankIdHintCodeEnum.UserCancel:
				return (
					<Notice color="error">
						<Markdown markdownContent={generalLang.BankId_UserMessage_RFA6} useTypographicElements />
					</Notice>
				);
			case BankIdHintCodeEnum.Cancelled:
				return (
					<Notice color="error">
						<Markdown markdownContent={generalLang.BankId_UserMessage_RFA3} useTypographicElements />
					</Notice>
				);
			case BankIdHintCodeEnum.StartFailed:
				const markdownText =
					bankIdSecureStartLoginView == "qr"
						? generalLang.BankId_UserMessage_RFA17B
						: generalLang.BankId_UserMessage_RFA17A;

				return (
					<Notice color="error">
						<Markdown markdownContent={markdownText} useTypographicElements />
					</Notice>
				);
			case BankIdHintCodeEnum.NotSupportedByUserApp:
				return <Notice color="error">{generalLang.BankId_UserMessage_NotSupportedByUserApp}</Notice>;
		}

		return null;
	}

	return <div>{getHintCodeText(hintCode)}</div>;
};
