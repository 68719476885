import * as React from "react";

import * as styles from "./overlay.scss";

export interface OverlayProps {
	isVisible: boolean;
}

class Overlay extends React.Component<OverlayProps> {
	render() {
		const { isVisible } = this.props;
		return <div className={isVisible ? styles.backgroundVisible : styles.background}>{this.props.children}</div>;
	}
}

export default Overlay;
