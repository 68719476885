import * as React from "react";

import CheckBox from "@bokio/elements/CheckBox/CheckBox";
import classes from "@bokio/utils/classes";

import { ListColumn } from "../ListColumn/ListColumn";

import * as styles from "./listHeader.scss";

export interface ListHeaderProps {
	children?: React.ReactNode;
	check?: boolean;
	clickableItems?: boolean;
	checked?: boolean;
	onChange?: (checked: boolean) => void;
}

export class ListHeader extends React.Component<ListHeaderProps> {
	onChange(checked: boolean) {
		if (this.props.onChange) {
			this.props.onChange(checked);
		}
	}

	render() {
		return (
			<div className={classes(styles, "item", "header")}>
				{this.props.check && (
					<CheckBox
						onChange={(checked: boolean) => this.onChange(checked)}
						checked={this.props.checked}
						rightMargin={true}
					/>
				)}
				{this.props.children}
				{this.props.clickableItems && <ListColumn width="40px" />}
			</div>
		);
	}
}
