import { Logins } from "./actions";

import type { Action } from "../types";
import type { LoginsState } from "./state";

const initialState: LoginsState = {
	currentUserEmail: "",
	logins: {},
};

export default (state: LoginsState = initialState, action: Action): LoginsState => {
	if (!state.logins) {
		state.logins = {};
	}

	switch (action.type) {
		case Logins.SET_USER_EMAIL:
			const email = action.data;
			if (state.logins[email]) {
				return { ...state, currentUserEmail: email };
			} else {
				return {
					...state,
					currentUserEmail: email,
					logins: {
						...state.logins,
						[email]: { userEmail: email, userFirstName: "", userLastName: "", userLoginMethod: undefined },
					},
				};
			}
		case Logins.SET_USER_FIRSTNAME: {
			const logins = { ...state.logins };
			logins[state.currentUserEmail].userFirstName = action.data;
			return { ...state, logins: logins };
		}
		case Logins.SET_USER_LASTNAME: {
			const logins = { ...state.logins };
			logins[state.currentUserEmail].userLastName = action.data;
			return { ...state, logins: logins };
		}
		case Logins.SET_USER_LOGIN_METHOD: {
			const logins = { ...state.logins };
			logins[state.currentUserEmail].userLoginMethod = action.data;
			return { ...state, logins: logins };
		}
		case Logins.SET_USER_PERSONAL_NUMBER: {
			const logins = { ...state.logins };
			if (logins[state.currentUserEmail]) {
				logins[state.currentUserEmail].userPersonalNumber = action.data;
			}
			return { ...state, logins: logins };
		}
		default:
			return state;
	}
};
