import * as React from "react";
import { Route } from "react-router";

import ApiRequest from "@bokio/shared/services/request/ApiRequest";
import { store } from "@bokio/shared/store";

import Layout from "../Layout/Layout";
import TopBar, { TopBarMode } from "../TopBar/TopBar";

import type { RouteProps } from "react-router";

// import { getLoginBackUrl } from "../../utils/url";
// import { createWrapper } from "./RedirectWrapper";
// export default createWrapper(
// 	() => false,
// 	getLoginBackUrl,
// 	() => store.dispatch(ApiRequest.clearUser()),
// );

const UserRoute: React.FC<RouteProps> = props => {
	React.useEffect(() => {
		store.dispatch(ApiRequest.clearUser());
	}, []);

	const { path, component, exact } = props;

	return (
		<Layout
			isDemo={false}
			noSideBar={true}
			menu={menuProps => (
				<div>
					<TopBar mode={TopBarMode.User} toggleMenu={menuProps.toggleMenu} />
				</div>
			)}
			content={<Route path={path} component={component} exact={exact} />}
		/>
	);
};

export default UserRoute;
