import upperFirst from "lodash-es/upperFirst";
import * as React from "react";

import { mergeClassNames } from "@bokio/utils/classes";

import * as styles from "./counterBadge.scss";

export interface CounterBadgeProps {
	color?: "highlight" | "subdued";
	size?: "normal" | "small" | "xsmall";
	/**
	 * Add a $color-base border. Mainly used when rendering the counter on top of an icon to distinguish it from the base.
	 *
	 * This can't be wrapped from outside the component because we have a border-radius here.
	 */
	border?: boolean;
	/**
	 * An "updated" zoom in animation will be triggered if {@link updateCounter} is defined and the value changes.
	 */
	updateCounter?: string;
	testId?: string;
}

const CounterBadgeStatic: React.FC<CounterBadgeProps & { isAnimating?: boolean; onAnimationEnd?: () => void }> = ({
	border,
	children,
	color = "highlight",
	size = "normal",
	testId,
	isAnimating,
	onAnimationEnd,
}) => {
	return (
		<span
			className={mergeClassNames(
				styles.root,
				styles[`color${upperFirst(color)}`],
				styles[`size${upperFirst(size)}`],
				border && styles.border,
				isAnimating && styles.isAnimating,
			)}
			onAnimationEnd={onAnimationEnd}
			data-testid={testId}
		>
			{children}
		</span>
	);
};

const CounterBadgeAnimated: React.FC<CounterBadgeProps> = props => {
	const isFirstEffectExecutedRef = React.useRef(false);
	const [isAnimating, setIsAnimating] = React.useState(false);

	React.useEffect(() => {
		if (!isFirstEffectExecutedRef.current) {
			isFirstEffectExecutedRef.current = true;
			return;
		}

		if (props.updateCounter === undefined) {
			setIsAnimating(false);
			return;
		}

		setIsAnimating(true);
	}, [props.updateCounter]);

	return <CounterBadgeStatic {...props} isAnimating={isAnimating} onAnimationEnd={() => setIsAnimating(false)} />;
};

export const CounterBadge: React.FC<CounterBadgeProps> = props => {
	return props.updateCounter === undefined ? <CounterBadgeStatic {...props} /> : <CounterBadgeAnimated {...props} />;
};
