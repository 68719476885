import * as React from "react";

import Icon from "@bokio/elements/Icon/Icon";
import { Link } from "@bokio/elements/Link/Link";
import { LoadingText } from "@bokio/elements/Loading";
import { GeneralLangFactory } from "@bokio/lang";
import withMenuContainer from "@bokio/shared/containers/layout/withMenuContainer";
import withRouterControls from "@bokio/shared/containers/router/withRouterControls";
import { getRoute } from "@bokio/shared/route";
import { getUser } from "@bokio/shared/state/requests";
import { mergeClassNames } from "@bokio/utils/classes";

import { TopBarDropDownButton } from "../../../TopBar/TopBarDropDownButton";

import type { MenuDispatchProps, MenuStateProps } from "@bokio/shared/containers/layout/withMenuContainer";
import type { RouterControlsProps } from "@bokio/shared/containers/router/withRouterControls";
import type { GetUserProps } from "@bokio/shared/state/requests/user/GetUser";

// prettier-ignore
import * as styles from "./menuCurrentUser.scss"; // SS 2024-01-24 Our Prettier config breaks the style import order, and in some legacy code we depended on this import order a lot, a mix of tech debt here IMO, ignoring for now.

// prettier-ignore
import * as flagStyles from "./languageSwitcherModal.scss"; // SS 2024-01-24 Our Prettier config breaks the style import order, and in some legacy code we depended on this import order a lot, a mix of tech debt here IMO, ignoring for now.

export interface MenuCurrentUserProps extends GetUserProps {
	openLangModal?: () => void;
}

interface MenuCurrentUserState {
	open: boolean;
}

class MenuCurrentUser extends React.Component<
	MenuCurrentUserProps & MenuDispatchProps & MenuStateProps & RouterControlsProps,
	MenuCurrentUserState
> {
	constructor(props: MenuCurrentUserProps & MenuDispatchProps & MenuStateProps & RouterControlsProps) {
		super(props);
		this.state = {
			open: false,
		};
	}

	render() {
		const { isLoadingUser, user, signOut } = this.props;
		const lang = GeneralLangFactory();
		if (isLoadingUser) {
			return (
				<div className={styles.dropdown}>
					<LoadingText width="70%" />
				</div>
			);
		}

		if (!user) {
			return <div className={styles.dropdown}>No user</div>;
		}

		return (
			<div className={styles.link}>
				<TopBarDropDownButton
					text={user.FirstName || lang.MyAccount}
					onClick={this.toggleuserSettings}
					onBlur={this.handleBlur}
					onBackgroundClose={this.handleBlur}
					open={this.state.open}
					testId="MenuCurrentUser"
				/>
				<div className={mergeClassNames(styles.userSettings, this.state.open && styles.showUserSettings)}>
					{
						<div className={styles.loggedInAs}>
							<p className={styles.name}>
								{user.FirstName && user.LastName
									? `${user.FirstName} ${user.LastName}`
									: user.FirstName
										? `${user.FirstName}`
										: lang.MyAccount}
							</p>
							<em className={styles.email}>{user.Email}</em>
						</div>
					}
					<Link
						style="none"
						className={styles.closeButton}
						onClick={() => {
							this.setState({ open: false });
						}}
					>
						<Icon name="cancel" size="22" />
					</Link>
					<Link
						className={styles.linkItem}
						style="none"
						route={getRoute("personalSettings", { returnUrl: window.location.pathname })}
					>
						{lang.PersonalSettings}
					</Link>
					{user.Languages.length > 1 && (
						<Link
							className={mergeClassNames(
								styles.linkItem,
								flagStyles.flag,
								flagStyles[`flag${user.Language.substring(0, 2)}`],
							)}
							style="none"
							onClick={this.props.openLangModal}
						>
							{lang.ChangeLanguage_action}
						</Link>
					)}
					<Link className={styles.linkItem} style="none" route={getRoute("deleteUserAccount")}>
						{lang.DeleteAccount}
					</Link>
					<Link
						className={mergeClassNames(styles.linkItem, styles.logout)}
						testId="logoutButton"
						style="none"
						onClick={e => {
							e.preventDefault();
							signOut();
						}}
					>
						{lang.LogOut}
					</Link>
				</div>
			</div>
		);
	}

	handleBlur = () => {
		if (this.state.open) {
			setTimeout(() => {
				// fixes blur firing before onClick
				this.setState({ open: false });
			}, 200);
		}
	};

	toggleuserSettings = () => {
		this.setState({
			open: !this.state.open,
		});
	};
}

export default getUser.connect()(withMenuContainer(withRouterControls(MenuCurrentUser)));
