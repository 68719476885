import { connect } from "react-redux";

import { useRedux } from "@bokio/hooks/useRedux/useRedux";
import { noop } from "@bokio/shared/utils";

import {
	getCurrentCompanyId,
	getCurrentEmployeeId,
	getCurrentLang,
	getCurrentUserId,
} from "../../state/router/selectors";

import type { State } from "../../state/state";
import type { Infer } from "../connect";

export interface ActiveUserProps {
	activeUser?: {
		userId: string;
		lang: string;
		employeeId?: string;
	};
	/**
	 * @deprecated If this is empty in unit test, use `companyInfo` from `useCompanyInfo()` or `withCompanyInfo` instead.
	 */
	companyId: string;
}

const mapStateToProps = (state: State): ActiveUserProps => {
	const userId = getCurrentUserId(state);

	return {
		companyId: getCurrentCompanyId(state) || "",
		activeUser:
			userId === undefined
				? undefined
				: {
						userId,
						// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
						lang: getCurrentLang(state)!,
						employeeId: getCurrentEmployeeId(state),
					},
	};
};

/**
 * @deprecated use `useCompanyUser` or `useUser` hooks.
 */
// TODO MQ 2019-02-15 I don't know to fix this type's issue, using react's context
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const withActiveUserInfo: Infer<ActiveUserProps> = connect(mapStateToProps, {}) as any;

/**
 * @deprecated use `useCompanyInfo`, `useCompanyUser` or `useUser` hooks.
 */
export const useActiveUserInfo = () => useRedux(mapStateToProps, noop, noop);

export default withActiveUserInfo;
