import * as React from "react";

import { mergeClassNames } from "@bokio/utils/classes";

import * as styles from "./box.scss";

interface BoxProps {
	children?: React.ReactNode;
	className?: string;
	wide?: boolean;
	fullWidth?: boolean;
}

export const Box = ({ children, className, wide, fullWidth }: BoxProps) => {
	const boxClass = mergeClassNames(
		fullWidth ? styles.boxFullWidth : wide ? styles.boxWide : styles.box,
		wide,
		className,
	);

	return <div className={boxClass}>{children}</div>;
};
