import * as React from "react";

import { Button } from "@bokio/designsystem/components/Button";
import { GeneralLangFactory } from "@bokio/lang";

import Icon from "../Icon/Icon";

import * as styles from "./ratingToast.scss";

interface StepContainterProps {
	title: string;
	description: string;
	canProceed: boolean;
	handleClose: () => void;
	handleSubmit: () => Promise<void>;
}

export const StepContainer: React.FC<StepContainterProps> = ({
	title,
	description,
	canProceed,
	handleClose,
	handleSubmit,
	children,
}) => {
	const generalLang = GeneralLangFactory();

	return (
		<>
			<header className={styles.header}>
				<div className={styles.headerRow}>
					<span className={styles.title}>{title}</span>
					<Icon
						name="cancel"
						onClick={handleClose}
						size="20"
						className={styles.closeButton}
						testId="StepContainer_Close"
					/>
				</div>
				<span className={styles.description}>{description}</span>
			</header>
			<article className={styles.content}>{children}</article>
			<footer className={styles.footer}>
				<Button onClick={handleSubmit} disabled={!canProceed} testId="Rating_Submit">
					{generalLang.Submit}
				</Button>
			</footer>
		</>
	);
};
