import * as React from "react";
import { useDispatch } from "react-redux";

import { ModalContent, ModalFooter } from "@bokio/components/Modal";
import { Modal } from "@bokio/components/Modal/Modal";
import { Button } from "@bokio/designsystem/components/Button";
import { RadioField } from "@bokio/elements/Form/RadioField/RadioField";
import { LoadingProgress } from "@bokio/elements/Loading";
import { GeneralLangFactory } from "@bokio/lang";
import { useLazyApi } from "@bokio/mobile-web-shared/hooks/useApi/useApi";
import * as proxy from "@bokio/mobile-web-shared/services/api/proxy";
import { getUser, useUser } from "@bokio/shared/state/requests";
import { getLanguageFromLocale } from "@bokio/shared/utils/format";
import { mergeClassNames } from "@bokio/utils/classes";

import type { SelectFieldOption } from "@bokio/elements/Form/SelectField";
import type * as m from "@bokio/mobile-web-shared/core/model/model";

import * as styles from "./languageSwitcherModal.scss";

interface LanguageSwitcherModalProps {
	visible: boolean;
	onClose: () => void;
}

const getLangString = (value: string, langString: string, currentSet: string) => {
	const lang = GeneralLangFactory();
	if (value === currentSet) {
		return `${langString}${lang.CurrentChoicePostFix}`;
	} else {
		return langString;
	}
};

const cultureToStyle = (culture: m.Core.Culture.CultureChoice): string => {
	const language = getLanguageFromLocale(culture.Name);
	switch (language) {
		case "sv":
			return styles.flagsv;
		case "en":
			return styles.flagen;
		default:
			return "";
	}
};

const cultureToOption = (culture: m.Core.Culture.CultureChoice, currentLang: string): SelectFieldOption => {
	const style = cultureToStyle(culture);
	return {
		value: culture.Name,
		label: getLangString(culture.Name, culture.DisplayName, currentLang),
		className: mergeClassNames(styles.flag, style),
	};
};

const LanguageSwitcherModal: React.FC<LanguageSwitcherModalProps> = ({ onClose, visible }) => {
	const lang = GeneralLangFactory();
	const { user } = useUser();
	const [selectedLanguage, setSelectedLanguage] = React.useState(user ? getLanguageFromLocale(user.Language) : "");
	const [execute, request] = useLazyApi(proxy.Settings.UserController.Edit.Post);
	const dispatch = useDispatch();

	// Cleans up the selection if the user doesn't click on `Change language`
	const onCancel = (currentLang: string) => {
		setSelectedLanguage(currentLang);
		onClose();
	};

	const updateLang = async (user: m.Bokio.Backbone.Web.Controllers.Settings.Models.UserViewModel) => {
		await execute({
			FirstName: user.FirstName,
			LastName: user.LastName,
			Language: selectedLanguage,
			PhoneNumber: user.PhoneNumber,
			UserResearchGroup: user.UserResearchGroup,
		});
		if (!request.error) {
			onClose();
			dispatch(getUser.execute());
		}
	};

	if (!user) {
		return null;
	}
	const currentLang = getLanguageFromLocale(user.Language);
	const isUnchanged = selectedLanguage === currentLang;
	const languages: SelectFieldOption[] = user.Languages.map(culture => cultureToOption(culture, currentLang));

	return (
		<Modal
			alwaysMounted={true}
			title={lang.ChangeLanguage_action}
			visible={visible}
			onClose={() => onCancel(currentLang)}
		>
			<ModalContent>
				{/* VM 2020-04-30: the element below needs to be a form, because this modal is created twice
				on mobile. This makes the RadioFields conflict because they have the same `name`. */}
				<form className={styles.radioWrapper}>
					<RadioField
						name="language"
						value={selectedLanguage}
						layout="vertical"
						onChange={val => setSelectedLanguage(val)}
						options={languages}
					/>
				</form>
			</ModalContent>
			<LoadingProgress request={request} />
			<ModalFooter>
				<Button appearance="primary" onClick={isUnchanged ? onClose : () => updateLang(user)}>
					{lang.ChangeLanguage_action}
				</Button>
			</ModalFooter>
		</Modal>
	);
};

export default LanguageSwitcherModal;
