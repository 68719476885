import AccountingLangFactory from "./AccountingLangFactory";
import BackOfficeLangFactory from "./BackOfficeLangFactory";
import BankLangFactory from "./BankLangFactory";
import ClosuresLangFactory from "./ClosuresLangFactory";
import CountriesLangFactory from "./CountriesLangFactory";
import ExpensesLangFactory from "./ExpensesLangFactory";
import GeneralLangFactory from "./GeneralLangFactory";
import InvoicesLangFactory from "./InvoicesLangFactory";
import OnboardingLangFactory from "./OnboardingLangFactory";
import PartnersLangFactory from "./PartnersLangFactory";
import PayslipCalculationsLangFactory from "./PayslipCalculationsLangFactory";
import ReceipesLang from "./RecipesLangFactory";
import SalaryLangFactory from "./SalaryLangFactory";
import SettingsLangFactory from "./SettingsLangFactory";
import SignUpLangFactory from "./SignUpLangFactory";

export {
  BackOfficeLangFactory,
  BankLangFactory,
  CountriesLangFactory,
  GeneralLangFactory,
  AccountingLangFactory,
  ClosuresLangFactory,
  ExpensesLangFactory,
  InvoicesLangFactory,
  PartnersLangFactory,
  PayslipCalculationsLangFactory,
  ReceipesLang,
  SalaryLangFactory,
  SettingsLangFactory,
  SignUpLangFactory,
  OnboardingLangFactory,
};
