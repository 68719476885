import * as React from "react";

import { useFeatureAvailability } from "@bokio/hooks/useFeatureAvailability/useFeatureAvailability";
import { Day } from "@bokio/mobile-web-shared/core/model/model";
import * as m from "@bokio/mobile-web-shared/core/model/model";
import { useLazyApi } from "@bokio/mobile-web-shared/hooks/useApi/useApi";
import * as proxy from "@bokio/mobile-web-shared/services/api/proxy";
import { useCompanyUser } from "@bokio/shared/state/requests";
import { getPlanName } from "@bokio/utils/priceplanUtils";

import { pricePlanContext } from "./PricePlanContext";

import BokioPlan = m.Entities.BokioPlan;
export const PricePlanContextProvider: React.FC = ({ children }) => {
	const { company } = useCompanyUser();
	const companyId = company?.Id;
	const [getPlan, currentPlanRequest] = useLazyApi(
		proxy.Bokio.Common.Web.Settings.SubscriptionController.CurrentBokioPlan.Get,
	);

	React.useEffect(() => {
		if (companyId) {
			getPlan(companyId);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [companyId]);

	const planInfo = currentPlanRequest.data?.Data?.PlanInfo;
	// Last day (end of trial info) should be take as 1 day left
	const dayDiffUntilPlanChange = (currentPlanRequest.data?.Data?.PlannedChange?.Date.dayDiff(Day.today()) ?? -1) + 1;
	const daysUntilTrialEnded = planInfo?.IsTrialing && dayDiffUntilPlanChange > 0 ? dayDiffUntilPlanChange : undefined;

	const features = useFeatureAvailability();
	const isPlusAvailable = features.Country !== m.CountryCode.GB;
	const highestPlanPossible = isPlusAvailable
		? BokioPlan.Pro // The enum value for "Premium Plus" is still called Pro
		: BokioPlan.Premium;

	const canPurchase = planInfo?.Plan !== highestPlanPossible;

	return (
		<pricePlanContext.Provider
			value={{
				plan: currentPlanRequest.isLoading ? undefined : planInfo?.Plan,
				planInfo: currentPlanRequest.isLoading ? undefined : planInfo,
				inTrial: planInfo?.IsTrialing ?? false,
				daysUntilTrialEnded: currentPlanRequest.isLoading ? undefined : daysUntilTrialEnded,
				planIconStatus: "active",
				planName: planInfo?.Plan ? getPlanName(planInfo?.Plan) : "",
				plannedChange: currentPlanRequest.data?.Data?.PlannedChange?.Date,
				nextPlan: currentPlanRequest.data?.Data?.PlannedChange?.Plan,
				nextPlanName: currentPlanRequest.data?.Data?.PlannedChange?.Plan
					? getPlanName(currentPlanRequest.data?.Data?.PlannedChange?.Plan)
					: undefined,
				hasPurchasedPlan:
					(!planInfo?.IsTrialing && planInfo?.Plan !== BokioPlan.Free) ||
					(planInfo?.IsTrialing && currentPlanRequest.data?.Data?.PlannedChange?.Plan !== BokioPlan.Free),
				canPurchase,
				request: currentPlanRequest,
				refresh: async () => {
					if (companyId) {
						getPlan(companyId);
					}
				},
			}}
		>
			{children}
		</pricePlanContext.Provider>
	);
};
