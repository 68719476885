import * as React from "react";

import EmailDeliveryContext from "./EmailDeliveryContext";
import { emitter } from "./EmailDeliveryHub";

import type * as m from "@bokio/mobile-web-shared/core/model/model";

type EmailActivityDto = m.Core.ViewData.EmailActivityDto;

const EmailDeliveryProvider: React.FunctionComponent = ({ children }) => {
	const [emailActivities, setEmailActivities] = React.useState<EmailActivityDto[]>([]);
	const onMessage = (a: EmailActivityDto) => setEmailActivities(state => [...state, a]);

	React.useEffect(() => {
		emitter.add(onMessage);
		return () => emitter.remove(onMessage);
	});

	const value = { emailActivities };
	return <EmailDeliveryContext.Provider value={value}>{children}</EmailDeliveryContext.Provider>;
};

export default EmailDeliveryProvider;
