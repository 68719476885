import * as React from "react";

import classes from "@bokio/utils/classes";

import * as styles from "./listColumn.scss";

export interface ListColumnProps {
	width?: string;
	center?: boolean;
	children?: React.ReactNode;
}

export const ListColumn = (props: ListColumnProps) => {
	const style = {
		width: props.width || "auto",
	};

	const classNames = classes(styles, {
		flex: !props.width,
		centered: props.center,
	});

	return (
		<div className={classNames} style={style}>
			{props.children}
		</div>
	);
};
