import * as React from "react";

import {
	getPriorityOption,
	getStatusOption,
} from "@bokio/backoffice/src/components/StatusSelect/OptimisticWorkStatusSelect";
import { StatusSelect } from "@bokio/backoffice/src/components/StatusSelect/StatusSelect";
import { Modal, ModalContent, ModalFooter } from "@bokio/components/Modal";
import { RenderApi } from "@bokio/components/RenderApi/RenderApi";
import { Button } from "@bokio/designsystem/components/Button";
import { SG } from "@bokio/designsystem/components/SpacingGroup/SpacingGroup";
import { Caption } from "@bokio/designsystem/components/TypographicElements/TypographicElements";
import CheckBox from "@bokio/elements/CheckBox/CheckBox";
import DayInput from "@bokio/elements/DayInput/DayInput";
import { FormGroup, FormWithValidation, TextAreaField } from "@bokio/elements/Form";
import { asValidatorFunction } from "@bokio/elements/Form/FormWithValidation/FormWithValidation";
import { RadioField } from "@bokio/elements/Form/RadioField/RadioField";
import { RenderRequestError } from "@bokio/elements/Loading";
import { Notice } from "@bokio/elements/Notice/Notice";
import { SearchableSelect } from "@bokio/elements/SearchableSelect";
import { BackOfficeLangFactory, GeneralLangFactory } from "@bokio/lang";
import { Day } from "@bokio/mobile-web-shared/core/model/model";
import * as m from "@bokio/mobile-web-shared/core/model/model";
import { useApi, useLazyApi } from "@bokio/mobile-web-shared/hooks/useApi/useApi";
import * as proxy from "@bokio/mobile-web-shared/services/api/proxy";
import { useAgencyStatus, useUser } from "@bokio/shared/state/requests";
import { formatMessage } from "@bokio/shared/utils/format";

import { getAssigneeOptions, getButtonText, getTranslation, renderTaskTitle } from "./helper";
import { TaskModalValidator } from "./TaskModalValidator";

import type { AssigneeDataItem } from "./helper";
import type { AddEditTaskFormData } from "./TaskModalValidator";
import type { SelectOption } from "@bokio/elements/SearchableSelect";
import type { ValidatorResult } from "@bokio/shared/validation/entityValidator";

import * as styles from "./addEditTaskModal.scss";

import WorkStatusType = m.Entities.Partners.WorkStatusType;
interface AddEditTaskModalProps {
	visible: boolean;
	taskStateDto?: m.Core.Services.Notes.TasksStateDto;
	partnerId: string;
	companyDetailsId?: string;
	companyDetailsName?: string;
	onClose: () => void;
	onSuccess: () => void;
	quoteRequestId?: string;
	noteId?: string;
}

export const AddEditTaskModal = ({
	visible,
	taskStateDto,
	partnerId,
	companyDetailsId,
	companyDetailsName,
	onClose,
	onSuccess,
	quoteRequestId,
	noteId,
}: AddEditTaskModalProps) => {
	const lang = GeneralLangFactory();
	const backofficeLang = BackOfficeLangFactory();
	const { user } = useUser();
	const descriptionLabel = backofficeLang.Task_TextField_Label;
	const isEdit = !!taskStateDto;
	const { agencyStatus } = useAgencyStatus();

	const [usersRequest] = useApi(proxy.BackOffice.UsersController.MentionableAccountants.Get, [
		partnerId,
		quoteRequestId
			? m.Entities.PartnerMembershipType.ManageQuoteRequests
			: m.Entities.PartnerMembershipType.ManageCompanies,
	]);

	const accountants = usersRequest.data?.Data?.Accountants;

	const getInitialState = (): AddEditTaskFormData => {
		return {
			Text: taskStateDto ? taskStateDto.Text : "",
			TaskStatus: taskStateDto ? taskStateDto.TaskStatus : m.Entities.Partners.WorkStatusType.New,
			Assignee: taskStateDto ? taskStateDto.Assignee : undefined,
			DueDate: taskStateDto ? taskStateDto.DueDate : undefined,
			ClientCompanyId: companyDetailsId ?? undefined,
			IsClientAssignee: false,
			QuoteRequestId: quoteRequestId,
			Title: taskStateDto ? renderTaskTitle(taskStateDto.Title, taskStateDto.Text) : "",
			Priority: taskStateDto
				? taskStateDto.Priority == m.Entities.Priority.NotSet
					? m.Entities.Priority.Medium
					: taskStateDto.Priority
				: m.Entities.Priority.Medium,
			EditAccess: taskStateDto
				? taskStateDto.EditAccess == m.Entities.EditAccess.NotSet
					? m.Entities.EditAccess.Anyone
					: taskStateDto.EditAccess
				: m.Entities.EditAccess.Anyone,
			AssignToMeCheck: taskStateDto ? taskStateDto.Assignee?.Id == user?.Id : false,
		};
	};

	const [client, setClient] = React.useState(companyDetailsId);

	const [getClientUser] = useLazyApi(proxy.BackOffice.CompanyDetailsController.GetClientCompanyUsers.Get);
	const [showTaskWarning, setTaskshowWarning] = React.useState<boolean>(false);
	const [showNotice, setShowNotice] = React.useState<boolean>(false);
	const [clientAssignee, setClientAssignee] = React.useState<AssigneeDataItem[]>();
	const [dueDateCheck, setDueDateCheck] = React.useState(taskStateDto ? !!taskStateDto.DueDate : false);

	const handleOnSuccess = () => {
		onSuccess();
		onClose();
	};
	const [addTask, addTaskRequest] = useLazyApi(proxy.BackOffice.CompanyDetailsController.AddTask.Post, {
		onSuccess: handleOnSuccess,
	});

	const [updateTask, updateTaskRequest] = useLazyApi(proxy.BackOffice.CompanyDetailsController.UpdateTask.Put, {
		onSuccess: handleOnSuccess,
	});

	const handleCancel = () => {
		onClose();
	};

	const title = isEdit
		? formatMessage(backofficeLang.AddEditNote_ModalTitle_Edit, getTranslation(m.Entities.NoteKind.Task).toLowerCase())
		: formatMessage(
				backofficeLang.AddEditNote_ModalTitle_CreateNew,
				getTranslation(m.Entities.NoteKind.Task).toLowerCase(),
			);

	const toAssigneeSelectOption = (assignee: AssigneeDataItem): SelectOption<AssigneeDataItem> => ({
		value: assignee,
		label: assignee.label ?? "",
		key: assignee.key,
	});

	const getClientAssignee = async (client: string | undefined) => {
		if (!!client && (agencyStatus.Access.SelfAssign || agencyStatus.Access.Partner)) {
			const clientUser = await getClientUser(partnerId, client);
			if (!!clientUser && !!clientUser.Data) {
				const clientAssignee = clientUser.Data.map(a => ({
					key: a.Id,
					label: a.Name,
					id: a.Id,
					postText: backofficeLang.Client,
				}));
				setClientAssignee(clientAssignee);
			}
		}
	};

	const getAllAssigneeOptions = (
		responsibleAccountants: m.Classes.UserDto[] | undefined,
		includeLoggedInuser: boolean,
	): SelectOption<AssigneeDataItem>[] => {
		if (!!responsibleAccountants && !!client) {
			const agencyassignee = includeLoggedInuser
				? getAssigneeOptions(responsibleAccountants)
				: getAssigneeOptions(responsibleAccountants, user?.Id);
			agencyassignee?.map(a => (a.postText = backofficeLang.Accountant));
			if (clientAssignee !== undefined && clientAssignee.length > 0 && !quoteRequestId) {
				const temp = agencyassignee.concat(clientAssignee);
				return temp.map(toAssigneeSelectOption);
			}
			return agencyassignee.map(toAssigneeSelectOption);
		}
		return [];
	};

	React.useEffect(() => {
		getClientAssignee(client);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [companyDetailsId, client]);
	const validator = new TaskModalValidator(dueDateCheck);

	return (
		<Modal
			disableBackgroundClick
			alwaysMounted={true}
			title={title}
			visible={visible}
			onClose={onClose}
			testId="AddEditTaskModal_Modal"
		>
			<FormWithValidation<AddEditTaskFormData, ValidatorResult>
				initialState={getInitialState()}
				onSubmit={async formData => {
					// eslint-disable-next-line @typescript-eslint/no-unused-vars
					const { ClientCompanyId, ...noteStateDto } = formData;
					if (isEdit && ClientCompanyId) {
						noteId && (await updateTask(partnerId, ClientCompanyId, noteId, noteStateDto, showNotice));
					} else if (ClientCompanyId) {
						if (clientAssignee !== undefined && noteStateDto.Assignee?.Id === clientAssignee[0].id) {
							noteStateDto.IsClientAssignee = true;
						}

						await addTask(partnerId, ClientCompanyId, noteStateDto);
					}
				}}
				validator={asValidatorFunction(validator)}
			>
				{({ formData, setValue, setFormData, validation }) => {
					const onSetDueDateCheck = (check: boolean) => {
						setDueDateCheck(check);
						if (!!check) {
							setFormData(f => ({ ...f, DueDate: undefined }));
						}
					};

					const setSelectedAssignee = (assigneeId: string) => {
						setShowNotice(assigneeId != getInitialState().Assignee?.Id && assigneeId != user?.Id);
						setTaskshowWarning(clientAssignee !== undefined && assigneeId === clientAssignee[0].id);

						const setAssignee = (): m.Classes.UserDto | undefined => {
							if (assigneeId && accountants && clientAssignee !== undefined && assigneeId !== clientAssignee[0].id) {
								return accountants.filter(u => u.Id === assigneeId)[0];
							} else if (
								assigneeId &&
								formData.ClientCompanyId &&
								clientAssignee !== undefined &&
								assigneeId === clientAssignee[0].id
							) {
								return {
									Id: clientAssignee[0].id,
									Email: "",
									Name: clientAssignee[0].label ?? " ",
									ShortName: "",
									IsApiUser: false,
								};
							} else {
								return undefined;
							}
						};
						setFormData(formState => ({
							...formState,
							Assignee: setAssignee(),
						}));
					};
					const onSetAssignToMeCheck = (check: boolean) => {
						setFormData(f => ({ ...f, AssignToMeCheck: check }));
						if (!!check && user?.Id) {
							setSelectedAssignee(user?.Id);
						}
						if (!check) {
							setFormData(formState => ({
								...formState,
								Assignee: undefined,
							}));
						}
					};
					const setClientId = async (clientId: string | undefined) => {
						setClient(clientId);
						if (!!clientId) {
							setFormData(formState => ({
								...formState,
								ClientCompanyId: clientId,
							}));
						} else {
							setFormData(formState => ({
								...formState,
								ClientCompanyId: undefined,
							}));
							setShowNotice(false);
						}
					};

					return (
						<>
							<ModalContent>
								<FormGroup>
									<SG gap="8">
										<TextAreaField
											value={formData.Title}
											onChange={setValue("Title")}
											label={backofficeLang.Task_TitleField_Label}
											rows={1}
											maxLength={60}
											testId="AddNoteModalTitleTextArea"
											errors={validator.filterByTypedField("Title", validation.errors)}
										/>
										<TextAreaField
											value={formData.Text}
											onChange={setValue("Text")}
											label={descriptionLabel}
											rows={4}
											testId="AddNoteModalTextArea"
											errors={validator.filterByTypedField("Text", validation.errors)}
										/>
										<SG gap="4">
											<CheckBox
												label={lang.DueDate}
												checked={dueDateCheck}
												onChange={c => onSetDueDateCheck(c)}
												testId="AddNoteModalDueDateCheck"
											/>
											<DayInput
												value={formData.DueDate}
												minDate={Day.today()}
												onChange={setValue("DueDate")}
												disabled={!dueDateCheck}
												className={styles.inputWrapper}
												testId="AddNoteModalDueDate"
												errors={validator.filterByTypedField("DueDate", validation.errors)}
											/>
										</SG>
										<SG horizontal gap="space-between">
											{!quoteRequestId && (
												<RenderApi
													endpoint={proxy.BackOffice.AgencyController.ListClients.Get}
													parameters={[partnerId]}
													whenLoading="text"
												>
													{({ data: clients }) => {
														return (
															<SearchableSelect
																label={backofficeLang.ChooseClientCompany}
																className={styles.inputWrapper}
																dropdownWrapperClassName={styles.inputWrapper}
																scrollSectionClassName={styles.inputWrapper}
																selected={clients
																	.map(c => ({
																		value: c.CompanyId,
																		key: c.CompanyId,
																		label: c.Name,
																	}))
																	.find(x => x.key === client)}
																disabled={!!companyDetailsName}
																onChange={clientId => setClientId(clientId?.value)}
																options={clients
																	.map(c => ({
																		value: c.CompanyId,
																		key: c.CompanyId,
																		label: c.Name,
																	}))
																	.sort((a, b) => a.label.localeCompare(b.label))}
																placeholder={backofficeLang.ChooseClientCompany}
																errors={validator.filterByTypedField("ClientCompanyId", validation.errors)}
																testId="AddNoteModalSelectClientCompany"
															/>
														);
													}}
												</RenderApi>
											)}
											<SG gap="8">
												<SearchableSelect
													label={backofficeLang.AddEditNote_AssignedTo_Label}
													className={styles.inputWrapper}
													selected={getAllAssigneeOptions(accountants, true)?.find(
														x => x.key === formData.Assignee?.Id,
													)}
													onChange={assignee => {
														if (!assignee) {
															setFormData(formState => ({
																...formState,
																Assignee: undefined,
															}));
															return;
														}
														setSelectedAssignee(assignee.value.id);
													}}
													options={getAllAssigneeOptions(accountants, false) ?? []}
													selectedFormatter={option => `${option.label}     ${option.value.postText}`}
													optionRenderer={option => {
														return (
															<div className={styles.resultItem}>
																{option.label} <Caption>{option.value.postText}</Caption>
															</div>
														);
													}}
													placeholder={backofficeLang.Notes_Unassigned}
													disabled={!formData.ClientCompanyId || formData.AssignToMeCheck}
													dropdownWrapperClassName={styles.inputWrapper}
													scrollSectionClassName={styles.inputWrapper}
												/>
												<CheckBox
													label={backofficeLang.AssignToMe_CheckBox}
													checked={formData.AssignToMeCheck}
													onChange={c => onSetAssignToMeCheck(c)}
													testId="AddNoteModalAssignToMeCheck"
													disabled={!formData.ClientCompanyId || (!!formData.Assignee && !formData.AssignToMeCheck)}
												/>
											</SG>
										</SG>
										<SG gap="space-between" horizontal>
											<StatusSelect
												label={lang.Priority}
												value={formData.Priority}
												onChange={setValue("Priority")}
												getStatusOption={getPriorityOption}
												options={[m.Entities.Priority.High, m.Entities.Priority.Medium, m.Entities.Priority.Low]}
												btnClassName={styles.inputWrapper}
												labelClassName={styles.label}
											/>

											<StatusSelect
												label={lang.Status}
												value={formData.TaskStatus}
												onChange={setValue("TaskStatus")}
												getStatusOption={getStatusOption}
												options={[
													WorkStatusType.Waiting,
													WorkStatusType.Overdue,
													WorkStatusType.New,
													WorkStatusType.Ongoing,
													WorkStatusType.Incomplete,
													WorkStatusType.Completed,
												]}
												btnClassName={styles.inputWrapper}
												labelClassName={styles.label}
											/>
										</SG>
										<RadioField
											label={backofficeLang.Task_EditAccess_CheckboxLabel}
											options={[
												{ value: "Anyone", label: backofficeLang.Task_EditAccess_Anyone_Option },
												{ value: "OnlyMe", label: backofficeLang.Task_EditAccess_OnlyMe_Option },
											]}
											name="type"
											value={formData.EditAccess}
											onChange={editAccess => setFormData(f => ({ ...f, EditAccess: editAccess }))}
											testId="AddNoteModal_EditAccessCheck"
											layout="horizontal"
										/>
									</SG>
								</FormGroup>
								{showNotice && formData.Assignee && <Notice>{backofficeLang.AddEditNote_AssignedTask_Notice}</Notice>}
								{showTaskWarning && formData.Assignee && (
									<Notice color="warning" margin={["top", "bottom"]}>
										{formatMessage(backofficeLang.ExternalCommunication_Warning)}
									</Notice>
								)}
							</ModalContent>
							<RenderRequestError requests={[addTaskRequest, updateTaskRequest]}></RenderRequestError>
							<ModalFooter>
								<Button appearance="secondary" onClick={handleCancel}>
									{lang.Cancel}
								</Button>
								<Button
									type="submit"
									testId="AddTaskCreateButton"
									disabled={!validation.isValid}
									loading={addTaskRequest.isLoading || updateTaskRequest.isLoading}
								>
									{getButtonText(isEdit, showNotice, m.Entities.NoteKind.Task)}
								</Button>
							</ModalFooter>
						</>
					);
				}}
			</FormWithValidation>
		</Modal>
	);
};
