import { LOCATION_CHANGE } from "connected-react-router";

import { Layout } from "./actions";

import type { Action } from "../types";
import type { LayoutState } from "./state";

const initialState: LayoutState = {
	menuExpanded: false,
	helpOpened: false,
	openModals: [],
	loadingExternalLink: undefined,
	showLogin: false,
};

export default function (state: LayoutState = initialState, action: Action): LayoutState {
	switch (action.type) {
		case Layout.TOGGLE_MENU:
			return {
				...state,
				menuExpanded: !state.menuExpanded,
			};

		case Layout.TOGGLE_HELP:
			return {
				...state,
				helpOpened: !state.helpOpened,
			};

		case LOCATION_CHANGE:
			return {
				...state,
				menuExpanded: false,
				openModals: [],
			};

		case Layout.CLOSE_MODAL:
			if (action.data) {
				return {
					...state,
					openModals: state.openModals.filter(v => {
						return v !== action.data;
					}),
				};
			} else {
				return {
					...state,
					openModals: [],
				};
			}

		case Layout.OPEN_MODAL:
			state.openModals.push(action.data);
			return {
				...state,
				openModals: state.openModals,
			};

		case Layout.LOAD_EXTERNAL_LINK:
			return {
				...state,
				loadingExternalLink: action.data,
			};

		case Layout.SHOW_LOGIN:
			return {
				...state,
				showLogin: true,
			};

		case Layout.CLEAR_SHOW_LOGIN:
			return {
				...state,
				showLogin: false,
			};

		default:
			return state;
	}
}
