import { connect } from "react-redux";

import { useRedux } from "@bokio/hooks/useRedux/useRedux";
import { Layout } from "@bokio/shared/state/layout/actions";
import { getCurrentExternalLink, isMenuExpanded } from "@bokio/shared/state/layout/selectors";
import { getCurrentCompanyId, getCurrentPath } from "@bokio/shared/state/router/selectors";
import { SessionActions } from "@bokio/shared/state/session/actions";
import { noop } from "@bokio/shared/utils";

import type { Infer } from "../connect";
import type { State } from "@bokio/shared/state/state";

export interface MenuStateProps {
	currentPath?: string;
	loadingExternalLink?: string;
	expanded: boolean;
	currentCompanyId?: string;
}

export interface MenuDispatchProps {
	signOut: () => void;
	loadExternalLink: (url: string) => void;
}

const mapStateToProps = (state: State): MenuStateProps => ({
	expanded: isMenuExpanded(state),
	currentPath: getCurrentPath(state),
	currentCompanyId: getCurrentCompanyId(state),
	loadingExternalLink: getCurrentExternalLink(state),
});

// eslint-disable-next-line @typescript-eslint/ban-types
const mapDispatchToProps = (dispatch: Function): MenuDispatchProps => ({
	signOut: () => dispatch(SessionActions.clear()),
	loadExternalLink: url => dispatch(Layout.loadExternalLink(url)),
});

const withMenuContainer = connect(mapStateToProps, mapDispatchToProps) as Infer<MenuStateProps & MenuDispatchProps>;

export const useMenuContainer = () => useRedux(mapStateToProps, mapDispatchToProps, noop);

export default withMenuContainer;
