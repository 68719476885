import * as React from "react";

import { useHelpContext } from "@bokio/contexts/HelpContext/HelpContext";
import { Link } from "@bokio/elements/Link/Link";

import { HelpWindowMode } from "../Help/helpSupportUtils";

import type { StyleTypes } from "@bokio/elements/Link/Link";
import type * as m from "@bokio/mobile-web-shared/core/model/model";

export interface ContactSupportLinkProps {
	area: m.Contracts.SupportFormArea;
	testId?: string;
	linkStyle?: StyleTypes;
	children: React.ReactNode;
	messagePrefill?: string;
	referenceId?: string;
}

export const ContactSupportLink: React.FC<ContactSupportLinkProps> = ({
	area,
	testId,
	children,
	linkStyle,
	messagePrefill,
	referenceId,
}) => {
	const { show, onNavigate, setAreaPrefilled, setModalMode, setMessagePrefilled, setReferenceId } = useHelpContext();

	return (
		<Link
			onClick={event => {
				event.preventDefault();
				setAreaPrefilled(area);
				setMessagePrefilled(messagePrefill ?? "");
				setReferenceId(referenceId ?? "");
				setModalMode(true);
				onNavigate(HelpWindowMode.Message);
				show();
			}}
			testId={testId}
			style={linkStyle}
		>
			{children}
		</Link>
	);
};
