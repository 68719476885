import * as React from "react";

import { FancyModal } from "@bokio/components/FancyModal/FancyModal";
import { usePricePlanContext } from "@bokio/contexts/PricePlanContext/PricePlanContext";
import { Button, ButtonGroup } from "@bokio/designsystem/components/Button";
import { Paragraph, TextSpan } from "@bokio/designsystem/components/TypographicElements/TypographicElements";
import { GeneralLangFactory } from "@bokio/lang";
import { useCompanyInfo } from "@bokio/mobile-web-shared/core/contexts/CompanyInfoContext/CompanyInfoContext";
import * as m from "@bokio/mobile-web-shared/core/model/model";
import { getRoute } from "@bokio/shared/route";
import { formatMessage } from "@bokio/shared/utils/format";
import { getPlanName } from "@bokio/utils/priceplanUtils";

import trialoverSVG from "./trialOverSVG.svg";

interface TrialOverModalProps {
	resolve: () => void;
}

import BokioPlan = m.Entities.BokioPlan;

export const TrialOverModal: React.FC<TrialOverModalProps> = ({ resolve }) => {
	const { companyInfo } = useCompanyInfo();
	const { plan } = usePricePlanContext();
	const generalLang = GeneralLangFactory();

	const headingText =
		plan === BokioPlan.Free || plan === undefined ? generalLang.TOM_Heading : generalLang.TOM_Heading_OnPlan;
	const bodyText =
		plan === BokioPlan.Free || plan === undefined
			? generalLang.TOM_Text
			: formatMessage(generalLang.TOM_Text_OnPlan, <TextSpan style="body-bold">{getPlanName(plan)}</TextSpan>);

	const ctaText = plan === BokioPlan.Free || plan === undefined ? generalLang.TOM_CTA : generalLang.Continue;

	return (
		<FancyModal
			testId="SMM_TO_Modal"
			visible={true}
			title={headingText}
			onClose={resolve}
			customGraphic={trialoverSVG}
			backgroundColor="burgundy"
			width="wide"
			imageSize="large"
		>
			<Paragraph>{bodyText}</Paragraph>
			<ButtonGroup margin={["top", "bottom"]}>
				<Button
					testId="SMM_TO_button"
					type="link"
					route={getRoute("billing", { company: companyInfo.Id }, { from: "tom" })}
					onNavigation={resolve}
				>
					{ctaText}
				</Button>
			</ButtonGroup>
		</FancyModal>
	);
};
