import * as React from "react";

import { Modal, ModalContent } from "@bokio/components/Modal";
import { IconButton } from "@bokio/designsystem/components/Button";

import { APPLICATION_TYPE_PDF, IMAGE_TYPE, OtherFilePreview } from "../FilePreview/FilePreview";

import type { FileWithPreview } from "../FilePreview/FilePreview";

import * as styles from "./filePreviewModal.scss";

interface FilePreviewModalProps {
	open: boolean;
	onClose: () => void;
	onRemoveFile: (preview: FileWithPreview) => void;
	previewFile?: FileWithPreview;
}

export const FilePreviewModal: React.FC<FilePreviewModalProps> = ({ open, onClose, previewFile, onRemoveFile }) => {
	if (!previewFile) {
		return null;
	}

	return (
		<Modal visible={open} onClose={onClose} title={previewFile.name}>
			<ModalContent className={styles.previewModalContent}>
				<div className={styles.previewModalButtonContainer}>
					<IconButton icon="trash-empty" onClick={() => onRemoveFile(previewFile)} size="small" />
				</div>
				{previewFile.type === APPLICATION_TYPE_PDF ? (
					<embed src={previewFile.preview} className={styles.embed} />
				) : previewFile.type.includes(IMAGE_TYPE) ? (
					<img src={previewFile.preview} className={styles.previewModalImage} />
				) : (
					<OtherFilePreview file={previewFile} />
				)}
			</ModalContent>
		</Modal>
	);
};
