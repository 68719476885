import { routerMiddleware as createRouterMiddleware } from "connected-react-router";
import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import thunk from "redux-thunk";

import { signalRStart } from "@bokio/shared/services/api/signalRStart";

import { history } from "./route";
import userMiddleware from "./state/middleware";
import { loadState, saveState } from "./state/persist";
import { getReducers } from "./state/reducers";

import type { State } from "./state/state";
import type { History } from "history";
import type { DeepPartial, StoreEnhancer } from "redux";

export const createAppStore = (ahistory: History, initialState?: DeepPartial<State>) => {
	const routerMiddleware = createRouterMiddleware(ahistory);

	// Combine all reducers
	const reducer = combineReducers(getReducers(ahistory));

	// Redux dev tools
	const devTools: StoreEnhancer<State> = window.__REDUX_DEVTOOLS_EXTENSION__
		? window.__REDUX_DEVTOOLS_EXTENSION__()
		: f => f;

	// Define middleware
	const middleware = applyMiddleware(routerMiddleware, thunk, userMiddleware);

	const options = compose(middleware, devTools);

	return createStore(reducer, initialState, options);
};

// Create the global store
const persistedState = loadState();
export const store = createAppStore(history, persistedState);

store.subscribe(() => {
	saveState(store.getState());
});

// Start signalR (delay start to not connect to SignalR before we sometimes get redirect loops, this causes a lot of requests to SignalR serivce)
// Quan - 2020-11: this timeout cause a bug on edge case for notification: before 3s passed, user won't see new notification in notifications list
// HB: We should stop initiating SignalR connections from guest pages (like `/login`).  Tracked by work item #23450
if (process.env.NODE_ENV !== "test" && !window.config.isStorybook) {
	setTimeout(() => signalRStart(), 3000);
}

// todo: on native, remove the router middleware and dev tools
