import * as React from "react";

import { CounterBadge } from "@bokio/components/CounterBadge/CounterBadge";

import type { CounterBadgeProps } from "@bokio/components/CounterBadge/CounterBadge";
import type * as m from "@bokio/mobile-web-shared/core/model/model";

type ToDoCountViewModel = m.Common.ViewModels.ToDoCountViewModel;
export enum ToDoCounterType {
	All,
	ImportantDates,
	ToDoNow,
}

interface MenuCounterProps {
	type: ToDoCounterType;
	count: ToDoCountViewModel;
	animateOnCountValueChange?: boolean;
	color?: CounterBadgeProps["color"];
	testId?: CounterBadgeProps["testId"];
}

const roundDownToNearestTen = (number: number): number => Math.floor(number / 10) * 10;

export const MenuCounter = (props: MenuCounterProps) => {
	const withMaximumCountIdentifier = (count: number, maximumTodoNowCountReached: boolean): string =>
		`${maximumTodoNowCountReached ? roundDownToNearestTen(count) : count}${maximumTodoNowCountReached ? "+" : ""}`;

	const getCount = () => {
		switch (props.type) {
			case ToDoCounterType.ToDoNow:
				return {
					count: props.count.ToDoCount,
					text: withMaximumCountIdentifier(props.count.ToDoCount, props.count.MaximumTodoCountReached),
				};
			case ToDoCounterType.ImportantDates:
				return {
					count: props.count.ImportantDatesCount,
					text: props.count.ImportantDatesCount.toString(),
				};
			case ToDoCounterType.All:
				return {
					count: props.count.ToDoTotalCount,
					text: withMaximumCountIdentifier(props.count.ToDoTotalCount, props.count.MaximumTodoCountReached),
				};
		}
	};

	const count = getCount();

	if (count.count === 0) {
		return null;
	}

	return (
		<CounterBadge
			testId={props.testId}
			color={props.color}
			size="small"
			updateCounter={props.animateOnCountValueChange ? count.text : undefined}
		>
			{count.text}
		</CounterBadge>
	);
};
