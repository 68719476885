import { HubConnectionBuilder } from "@microsoft/signalr";

import { bankFeedActivityHub } from "@bokio/contexts/BankFeedActivityContext/BankFeedActivityContext";
import { emailActivityHub } from "@bokio/contexts/EmailDeliveryContext";
import { notificationActivityHub } from "@bokio/contexts/NotificationActivityContext/NotificationActivityContext";
import { Config } from "@bokio/shared/config";

const coreSignalRStart = () => {
	// The library has a policy for automatic reconnects enabled by default.  To refine it, use one of
	// the `.withAutomaticReconnect(…)` overloads.
	const connection = new HubConnectionBuilder().withUrl(`${Config.env.apiUrl}/signalr`).build();

	connection.on("emailActivity", emailActivityHub.dispatch);
	connection.on("bankFeedActivity", bankFeedActivityHub.dispatch);
	connection.on("notificationActivity", notificationActivityHub.dispatch);

	connection.start();
};

export const signalRStart = () => {
	coreSignalRStart();
};
