import * as React from "react";

import { useDeviceQuery } from "@bokio/elements/DeviceQuery/useDeviceQuery";
import { FileUploader } from "@bokio/elements/FileUploader/FileUploader";
import { FormGroup } from "@bokio/elements/Form";
import { InputField } from "@bokio/elements/Form/InputField";
import { RadioField } from "@bokio/elements/Form/RadioField/RadioField";
import { SelectField } from "@bokio/elements/Form/SelectField";
import { TextAreaField } from "@bokio/elements/Form/TextAreaField";
import { useDisableGlobalFileDropZone } from "@bokio/hooks/useDisableGlobalFileDropZone/useDisableGlobalFileDropZone";
import GeneralLangFactory from "@bokio/lang/GeneralLangFactory";
import * as m from "@bokio/mobile-web-shared/core/model/model";
import { filterByField } from "@bokio/shared/validation/entityValidator";
import { mergeClassNames } from "@bokio/utils/classes";

import type { MenuHelpMessageFormValues } from "./Help/Help";
import type { FileWithPreview } from "@bokio/elements/FileUploader/components/FilePreview/FilePreview";
import type { FieldValidationResult, ValidatorResult } from "@bokio/shared/validation/entityValidator";

import * as styles from "./helpStyle.scss";

import HelpType = m.Contracts.HelpType;
interface HelpMessageFormProps {
	isOpened: boolean;
	modalView?: boolean;
	formData: MenuHelpMessageFormValues;
	setFormData: (reducer: (oldFormData: MenuHelpMessageFormValues) => MenuHelpMessageFormValues) => void;
	isLoggedIn: boolean;
	validation: ValidatorResult & {
		isValid: boolean;
		errors?: FieldValidationResult[] | undefined;
	};
	areas: m.Contracts.SupportArea[];
	files: FileWithPreview[];
	setFiles: React.Dispatch<React.SetStateAction<FileWithPreview[]>>;
}

const getTypeOption = (type: HelpType) => {
	const lang = GeneralLangFactory();
	switch (type) {
		case HelpType.Feedback:
			return lang.Help_Type_Feedback;
		case HelpType.Technincal:
			return lang.Help_Type_Technical;
		case HelpType.Question:
			return lang.Help_Type_Question;
		case HelpType.NotSet:
			throw new Error("Type NotSet is not supported");
	}
};

export const HelpMessageForm: React.FC<HelpMessageFormProps> = ({
	isOpened,
	modalView,
	validation,
	isLoggedIn,
	setFormData,
	formData,
	areas,
	files,
	setFiles,
}) => {
	const { isMobile } = useDeviceQuery();
	useDisableGlobalFileDropZone();

	if (!isOpened) {
		return null;
	}

	const lang = GeneralLangFactory();

	const selectedArea = areas.find(a => a.Key === formData.Area);
	const selectedCategory = selectedArea?.SupportCategories.find(c => c.Key === formData.Category);
	return (
		<div className={mergeClassNames(styles.helpFormWrapper, modalView && styles.helpFormWrapperModal)}>
			{!modalView && <b>{lang.ContactSupport_action}</b>}
			<FormGroup>
				<InputField
					onChange={name => setFormData(prev => ({ ...prev, Name: name }))}
					label={lang.YourName}
					value={formData.Name}
					errors={filterByField("Name", validation.errors)}
					testId="Help_MessageForm_Name"
				/>
			</FormGroup>
			<FormGroup>
				<SelectField
					label={lang.Help_WhatIsYourQuestion}
					placeholder={lang.Help_ChooseAnArea}
					options={areas.map(area => ({ value: area.Key, label: area.Label }))}
					onChange={area =>
						setFormData(prev => ({
							...prev,
							Area: area,
							Category: m.Contracts.SupportFormCategory.NotSet,
							SubCategory: m.Contracts.SupportFormSubCategory.NotSet,
						}))
					}
					value={formData.Area}
					errors={filterByField("Area", validation.errors)}
					labelTestId="Help_MessageForm_Area_Label"
					testId="Help_MessageForm_Area"
				/>
				<SelectField
					label={lang.Help_ChooseACategory}
					placeholder={lang.Help_ChooseACategory}
					disabled={selectedArea?.SupportCategories === undefined || selectedArea.SupportCategories.length === 0}
					options={selectedArea?.SupportCategories.map(cat => ({ value: cat.Key, label: cat.Label })) ?? []}
					onChange={category =>
						setFormData(prev => ({
							...prev,
							Category: category,
							SubCategory: m.Contracts.SupportFormSubCategory.NotSet,
						}))
					}
					value={formData.Category}
					errors={filterByField("Category", validation.errors)}
					testId="Help_MessageForm_Category"
				/>
				{selectedCategory &&
					selectedCategory.SubCategories !== undefined &&
					selectedCategory.SubCategories.length > 0 && (
						<SelectField
							label={lang.Help_ChooseASubCategory}
							placeholder={lang.Help_ChooseASubCategory}
							options={selectedCategory.SubCategories.map(cat => ({ value: cat.Key, label: cat.Label }))}
							onChange={subcategory => setFormData(prev => ({ ...prev, SubCategory: subcategory }))}
							value={formData.SubCategory}
							errors={filterByField("SubCategory", validation.errors)}
							testId="Help_MessageForm_SubCategory"
						/>
					)}
			</FormGroup>
			{!isLoggedIn && (
				<FormGroup>
					<InputField
						onChange={email => setFormData(prev => ({ ...prev, Email: email }))}
						label={lang.Email}
						value={formData.Email}
						errors={filterByField("Email", validation.errors)}
						testId="Help_MessageForm_Email"
					/>
				</FormGroup>
			)}
			<FormGroup>
				<RadioField
					name="Help_MessageForm_Type"
					testId="Help_MessageForm_Type"
					label={lang.Help_Type}
					value={formData.Type}
					options={Object.keys(HelpType)
						.filter(k => HelpType[k] !== HelpType.NotSet)
						.map(k => ({
							label: getTypeOption(HelpType[k]),
							value: HelpType[k],
							testId: `Help_MessageForm_Type_${HelpType[k]}`,
						}))}
					errors={filterByField("Type", validation.errors)}
					onChange={type => setFormData(prev => ({ ...prev, Type: type }))}
					layout={isMobile ? "vertical" : "horizontal"}
				/>
			</FormGroup>
			<FormGroup>
				<TextAreaField
					className={styles.textBox}
					value={formData.Message}
					onChange={message => setFormData(prev => ({ ...prev, Message: message }))}
					errors={filterByField("Message", validation.errors)}
					placeholder={lang.Help_HowCanWeHelpYou}
					rows={7}
					testId="Help_MessageForm_Message"
					labelTestId="Help_MessageForm_Message_Label"
				/>
			</FormGroup>
			<FileUploader
				label={lang.Help_UploadAttachments}
				small
				rejectAllOnAnyRejection
				allowMultiple
				acceptedFileTypes={["pdf", "jpg", "jpeg", "png", "gif", "webp", "si", "se", "heic", "csv", "tsv"]}
				onFileUpload={f => setFiles(prev => [...prev, ...f])}
				onFileDelete={f => setFiles(prev => prev.filter(p => p.name != f.name))}
				files={files}
				disablePreview={false}
				testId="Help_MessageForm_FileUploader"
			/>
		</div>
	);
};
