/* eslint-disable no-console */
export enum LogLevel {
	Error,
	Warning,
	Notice,
	Debug,
}

// todo: should be dynamic somehow, or replaced by some better class
const level: LogLevel = LogLevel.Debug;

export default class Log {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	public static error(...args: any[]) {
		if (level >= LogLevel.Error) {
			console.error("[ERROR]", ...args);
		}
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	public static warning(...args: any[]) {
		if (level >= LogLevel.Warning) {
			console.warn("[WARNING]", ...args);
		}
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	public static notice(...args: any[]) {
		if (level >= LogLevel.Notice) {
			console.log("[NOTICE]", ...args);
		}
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	public static debug(...args: any[]) {
		if (level >= LogLevel.Error) {
			console.log("[DEBUG]", ...args);
		}
	}
}
