import * as React from "react";

import { mergeClassNames } from "@bokio/utils/classes";

import RenderRequestError from "./RenderRequestError";

import type { LoadingProps } from "./LoadingProps";

import * as styles from "./loadingSpinner.scss";

export type LoadingSpinnerColor = "blue" | "green" | "white" | "dark";

interface LoadingSpinnerProps extends LoadingProps {
	size?: number;
	paddingTop?: boolean;
	paddingBottom?: boolean;
	color?: LoadingSpinnerColor;
	message?: string;
}

const LoadingSpinner = ({
	request,
	size = 36,
	children,
	paddingTop,
	paddingBottom,
	color = "green",
	className,
	hideSoftErrors,
	message,
}: LoadingSpinnerProps) => (
	<RenderRequestError request={request} showWhenLoaded={children} hideSoftErrors={hideSoftErrors}>
		<div
			className={mergeClassNames(
				styles.loader,
				paddingTop && styles.paddingTop,
				paddingBottom && styles.paddingBottom,
				className,
				message && styles.center,
			)}
			style={{ width: size, height: size }}
			data-testid="LoadingSpinner"
		>
			<div className={mergeClassNames(styles.circle, styles.color, styles[color])} style={{ borderWidth: size / 12 }} />
		</div>
		{message && <p className={styles.message}>{message}</p>}
	</RenderRequestError>
);

export default LoadingSpinner;
