import type { Action } from "../types";

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace SessionActions {
	export const START = "SESSION/START";
	export const CLEAR = "SESSION/CLEAR";

	export function start(token: string): Action<string> {
		return {
			type: START,
			data: token,
		};
	}

	export function clear(): Action {
		return {
			type: CLEAR,
		};
	}
}
