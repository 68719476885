/* eslint-disable */

import type { BackOfficeLang } from "./types/BackOfficeLang";
import { translateTempImpl } from "./TranslatableLanguage";

let data: BackOfficeLang | undefined;

export type { BackOfficeLang };

export default function BackOfficeLangFactory(): BackOfficeLang {
  if (!data) {
    throw new Error("Trying to load a language in the main bundle before the language bundle is loaded");
  }
  return data;
}

export let setBackOfficeLang = (newLang: string) => {
  if (newLang === "ky-KG") {
    setBackOfficeLang = (nl:string) => {
      const storedLangData = window.sessionStorage.getItem("bokioLang."+ nl + ".BackOffice");
      const rawData = JSON.parse(storedLangData || "");
      rawData.translateTemp = (key: string, neutral: string, svSE?: string, enGB?: string) => translateTempImpl(key, neutral, svSE, enGB);
      data = rawData;
      return Promise.resolve();
    }
    import(/* webpackChunkName: "en-GB.locale" */ "./en-GB/BackOfficeLangDef").then(({ BackOfficeLangDef }) => {
      let dataToStore = BackOfficeLangDef();
      window.sessionStorage.setItem("bokioLang.en-GB.BackOffice", JSON.stringify(dataToStore));
    });
    import(/* webpackChunkName: "sv-SE.locale" */ "./sv-SE/BackOfficeLangDef").then(({ BackOfficeLangDef }) => {
      let dataToStore = BackOfficeLangDef();
      window.sessionStorage.setItem("bokioLang.sv-SE.BackOffice", JSON.stringify(dataToStore));
    });
    import(/* webpackChunkName: "en-SE.locale" */ "./en-SE/BackOfficeLangDef").then(({ BackOfficeLangDef }) => {
      let dataToStore = BackOfficeLangDef();
      window.sessionStorage.setItem("bokioLang.en-SE.BackOffice", JSON.stringify(dataToStore));
    });
    import(/* webpackChunkName: "ky-KG.locale" */ "./ky-KG/BackOfficeLangDef").then(({ BackOfficeLangDef }) => {
      let dataToStore = BackOfficeLangDef();
      window.sessionStorage.setItem("bokioLang.ky-KG.BackOffice", JSON.stringify(dataToStore));
    });
  }
  if (newLang === "en-GB") {
      return import(/* webpackChunkName: "en-GB.locale" */ "./en-GB/BackOfficeLangDef").then(({ BackOfficeLangDef }) => {
      data = BackOfficeLangDef();
    });
  } else if (newLang === "sv-SE") {
      return import(/* webpackChunkName: "sv-SE.locale" */ "./sv-SE/BackOfficeLangDef").then(({ BackOfficeLangDef }) => {
      data = BackOfficeLangDef();
    });
  } else if (newLang === "en-SE") {
      return import(/* webpackChunkName: "en-SE.locale" */ "./en-SE/BackOfficeLangDef").then(({ BackOfficeLangDef }) => {
      data = BackOfficeLangDef();
    });
  } else if (newLang === "ky-KG") {
      return import(/* webpackChunkName: "ky-KG.locale" */ "./ky-KG/BackOfficeLangDef").then(({ BackOfficeLangDef }) => {
      data = BackOfficeLangDef();
    });
  }
  else {
    throw new Error("Unsupported language/culture " + newLang);
  }
};