import * as React from "react";

import { BokioPayBankIdAuthModal } from "@bokio/components/BokioPayBankIdModal/BokioPayBankIdAuthModal";
import { Notice } from "@bokio/elements/Notice/Notice";
import * as m from "@bokio/mobile-web-shared/core/model/model";
import { apiPollingTimebasedStopCheck, useApiPolling, useLazyApi } from "@bokio/mobile-web-shared/hooks/useApi/useApi";
import * as proxy from "@bokio/mobile-web-shared/services/api/proxy";

import { useReturnedFromBankId } from "../useReturnedFromBankId/useReturnedFromBankId";

import BankIdCollectionStatus = m.Bokio.Common.Contract.BankId.BankIdCollectionStatus;
import BankIdHintCode = m.Bokio.Common.Contract.BankId.BankIdHintCode;

type BankIdError = m.Bokio.Common.Contract.BankId.BankIdError;
type LoginUserResult = m.Bokio.Services.Account.LoginUserResult;

export function useBokioPayBankIdAuth(
	startFn: () => Promise<m.Envelope<m.Bokio.Backbone.Web.Controllers.Accounts.StartBankIdLoginResponse, BankIdError>>,
	pollForUpdateFn: () => Promise<
		m.Envelope<m.Bokio.Backbone.Web.Controllers.Accounts.ConfirmBankIdLoginResponse, BankIdError>
	>,
	onSuccess: (loginResult: LoginUserResult) => void,
) {
	const [autoStartToken, setAutoStartToken] = React.useState<string>();
	const [qrStartToken, setQrStartToken] = React.useState<string>();
	const [collectionStatus, setCollectionStatus] = React.useState<BankIdCollectionStatus>();
	const [hintCode, setHintCode] = React.useState<BankIdHintCode>();
	const [qrCode, setQrCode] = React.useState<string>();
	const [errorMessage, setErrorMessage] = React.useState<string>();

	const qrCodePoller = useApiPolling(proxy.Bokio.BankIdController.QrCode.Get, {
		stopCondition: context =>
			apiPollingTimebasedStopCheck()(context) ||
			context.apiError ||
			context.error !== null ||
			!context.data?.AnimatedQrCode,
		effect: (data, error) => {
			if (data) {
				setQrCode(data.AnimatedQrCode);
			}

			if (error) {
				setErrorMessage(error.UserVisibleMessage);
			}
		},
	});

	const { startPolling, stopPolling, isPolling } = useApiPolling(pollForUpdateFn, {
		stopCondition: context =>
			apiPollingTimebasedStopCheck()(context) ||
			context.apiError ||
			context.error !== null ||
			(context.data !== null && context.data.BankIdStatus !== BankIdCollectionStatus.Pending),
		effect: (data, error, apiError) => {
			if (data) {
				setHintCode(data.HintCode);
				setCollectionStatus(data.BankIdStatus);

				if (data.BankIdStatus == BankIdCollectionStatus.Complete) {
					onSuccess(data.SuccessfulLoginResult);
					setErrorMessage(undefined);
				}
			}

			if (error) {
				setErrorMessage(error.UserVisibleMessage);
			}

			if (apiError) {
				//If we get, for example, a HTTP 500 from the update polling we should stop with the QR code polling since otherwise we will just keep on polling that in vain
				qrCodePoller.stopPolling();
			}
		},
		interval: 2000, //From BankID: RP should keep on calling collect every two seconds
	});

	const pollForUpdate = () => {
		if (!isPolling) {
			startPolling([]);
		}
	};

	const { clearReturnedFromBankId } = useReturnedFromBankId({ onReturnedFromBankId: pollForUpdate });

	const [start, startRequest] = useLazyApi(startFn, {
		onSuccess: response => {
			setAutoStartToken(response.AutoStartToken);
			setQrStartToken(response.QrStartToken);
			startPolling([]);
		},
		onError: error => {
			setErrorMessage(error.UserVisibleMessage);
		},
	});

	const [cancel] = useLazyApi(proxy.Bokio.BankIdController.Cancel.Post, {
		onError: error => {
			setErrorMessage(error.UserVisibleMessage);
		},
	});

	const closeBankIdModal = () => {
		// We stop all things on our side before cancelling the order in BankID to avoid sync issues
		// where we would try to poll again after BankID has cancelled the order but before we've gotten the response
		// from our backend
		stopPolling();
		qrCodePoller.stopPolling();

		setAutoStartToken(undefined);
		setQrStartToken(undefined);
		setQrCode(undefined);
		setHintCode(undefined);
		setCollectionStatus(undefined);

		cancel();
	};

	const startPollingForQrCode = () => {
		if (qrStartToken && !qrCodePoller.isPolling) {
			qrCodePoller.startPolling([qrStartToken]);
		}
	};

	const startAuth = () => {
		clearReturnedFromBankId();
		start();
	};

	const renderAuthModal = () => (
		<BokioPayBankIdAuthModal
			autoStartToken={autoStartToken}
			qrStartToken={qrStartToken}
			collectionStatus={collectionStatus}
			hintCode={hintCode}
			isPolling={isPolling}
			qrCode={qrCode}
			onStartPollingQrCode={startPollingForQrCode}
			onStopPollingQrCode={() => qrCodePoller.stopPolling()}
			onClose={closeBankIdModal}
		/>
	);

	const renderErrorMessage = () => {
		if (errorMessage) {
			return <Notice color="warning">{errorMessage}</Notice>;
		}

		return null;
	};

	return {
		startAuth,
		startAuthRequest: startRequest,
		isPolling,
		renderAuthModal,
		renderErrorMessage,
	};
}

export function useLoginBokioPayBankIdAuth(email: string, onSuccess: (loginResult: LoginUserResult) => void) {
	return useBokioPayBankIdAuth(
		() => proxy.Bokio.AccountController.StartBankIdLogin.Post(email),
		() => proxy.Bokio.AccountController.ConfirmBankIdLogin.Post(email),
		onSuccess,
	);
}

export function useActivateBokioPayBankIdAuth(password: string, totpCode: string, onSuccess: () => void) {
	return useBokioPayBankIdAuth(
		() =>
			proxy.Settings.BokioPayBankIdAuthSettingsController.StartEnableBokioPayBankIdAuth.Post({
				Password: { Value: password },
				TotpCode: { Value: totpCode },
			}),
		() => proxy.Settings.BokioPayBankIdAuthSettingsController.ConfirmEnableBokioPayBankIdAuth.Post(),
		onSuccess,
	);
}
