import { GetAgencyCount } from "./agency/GetAgencyCount";
import { GetAgencyStatus } from "./agency/GetAgencyStatus";
import { GetTodoStatus } from "./todo/GetTodoStatus";
import { GetCompanyUser } from "./user/GetCompanyUser";
import { GetMemberships } from "./user/GetMemberships";
import { GetUser } from "./user/GetUser";
import { SignIn } from "./user/SignIn";

// User
export const getUserMemberships = GetMemberships();
export const useUserMemberships = getUserMemberships.useProps;

export const getUser = GetUser();
export const useUser = getUser.useProps;

export const getCompanyUser = GetCompanyUser();
export const useCompanyUser = getCompanyUser.useProps;

export const signIn = SignIn();
export const useSignIn = signIn.useProps;

// Todo
export const getTodoStatus = GetTodoStatus();
export const useTodoStatus = getTodoStatus.useProps;

// Partner/Agency
export const getAgencyStatus = GetAgencyStatus();
export const useAgencyStatus = getAgencyStatus.useProps;

export const getAgencyCount = GetAgencyCount();
export const useAgencyCount = getAgencyCount.useProps;
