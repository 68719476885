import * as React from "react";

import { Button, ButtonGroup } from "@bokio/designsystem/components/Button";
import { Heading, Paragraph, Section } from "@bokio/designsystem/components/TypographicElements/TypographicElements";
import { GeneralLangFactory } from "@bokio/lang";
import { trackEvent } from "@bokio/utils/t";

interface RatingReviewStepProps {
	onClose: () => void;
}

export const RatingReviewStep: React.FC<RatingReviewStepProps> = ({ onClose }) => {
	const generalLang = GeneralLangFactory();

	return (
		<Section bumpHeadingLevel>
			<Heading textCenter>{generalLang.NPS_Review_Title}</Heading>
			<Paragraph>{generalLang.NPS_Review_Description}</Paragraph>
			<Paragraph>
				<ButtonGroup direction="row" align="center">
					<Button
						appearance="secondary"
						onClick={() => {
							onClose();
							trackEvent("NPS", "Click", "RatingReviewStep_Close");
						}}
						testId="RatingReviewStep_Close"
					>
						{generalLang.Close}
					</Button>
					<Button
						external
						type="link"
						route={generalLang.NPS_ReviewOnTrustpilot_Link}
						target="_blank"
						testId="RatingReviewStep_Link"
						onNavigation={() => trackEvent("NPS", "Click", "ReviewOnTrustpilot")}
					>
						{generalLang.NPS_ReviewOnTrustpilot}
					</Button>
				</ButtonGroup>
			</Paragraph>
		</Section>
	);
};
