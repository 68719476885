import * as React from "react";

import { BankLangFactory } from "@bokio/lang";
import {
	getWizardStep,
	getWizardStepNeedsSession,
} from "@bokio/mobile-web-shared/areas/bank/utils/bokioBusinessAccountUtils";
import { useCompanyInfo } from "@bokio/mobile-web-shared/core/contexts/CompanyInfoContext/CompanyInfoContext";
import * as m from "@bokio/mobile-web-shared/core/model/model";
import { useRouter } from "@bokio/shared/containers/router/useRouter";
import { getRoute } from "@bokio/shared/route";

import { BokioPlanFeatureButton } from "../../../BokioPlanFeatureButton/BokioPlanFeatureButton";
import { PricePlanFeature } from "../../../BokioPlanLockBadge/BokioPlanLockBadge";

import OnboardingState = m.Bokio.Bank.Contract.Mir.Onboarding.OnboardingState;
type OnboardingStatusViewModel = m.Bokio.Bank.Contract.Mir.Onboarding.OnboardingStatusViewModel;
interface ContinueBbaMirOnboardingCtaProps {
	onboardingStatus: OnboardingStatusViewModel;
	onNavigateAway?: () => void;
	usingBbaButton?: boolean;
}

export const ContinueBbaMirOnboardingCta: React.FC<ContinueBbaMirOnboardingCtaProps> = ({
	onboardingStatus,
	onNavigateAway,
	usingBbaButton,
}) => {
	const { companyInfo } = useCompanyInfo();
	const router = useRouter();
	const bankLang = BankLangFactory();

	const icon =
		getWizardStepNeedsSession(onboardingStatus.State) && !onboardingStatus.HasSession
			? "bankid-solid"
			: usingBbaButton
				? "bba-filled"
				: undefined;

	const onClick = () => {
		if (!onboardingStatus.PersonalId) {
			throw new Error("Missing PersonalId from onboardingStatusRequest");
		}
		const step = getWizardStep(onboardingStatus.State);
		router.redirect(getRoute("bokioBusinessAccountOnboardingSteps", { company: companyInfo.Id, step: step }));
		onNavigateAway?.();
	};

	const buttonText =
		onboardingStatus.State === OnboardingState.WaitingForOrderCard
			? bankLang.OrderCard
			: onboardingStatus.HasSession || !getWizardStepNeedsSession(onboardingStatus.State)
				? bankLang.MirOnboardingPrompt_CompleteTheSignUp
				: bankLang.MirOnboardingPrompt_CompleteTheSignUpWithBankId;

	return (
		<BokioPlanFeatureButton
			feature={PricePlanFeature.StartBokioBusinessAccountOnboarding}
			iconAlign="right"
			icon={icon}
			testId="ContinueBusinessAccountOnboarding_Proceed_Button"
			onClick={onClick}
		>
			{buttonText}
		</BokioPlanFeatureButton>
	);
};
