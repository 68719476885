import layout from "./layout/reducer";
import logins from "./logins/reducer";
import requests from "./requests/reducer";
import { createReducer as createRouterReducer } from "./router/reducer";
import todo from "./todo/reducer";

import type { State } from "./state";
import type { History } from "history";
import type { ReducersMapObject } from "redux";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getReducers = (history: History): ReducersMapObject<State, any> => ({
	todo,
	requests,
	layout,
	router: createRouterReducer(history),
	logins,
});
