import { connect } from "react-redux";

import { useRedux } from "@bokio/hooks/useRedux/useRedux";
import { Layout } from "@bokio/shared/state/layout/actions";

import type { Infer } from "../connect";
import type { State } from "@bokio/shared/state/state";

export interface ShowLoginStateProps {
	showLogin: boolean;
}

export interface ShowLoginDispatchProps {
	doShowLogin: () => void;
	clearShowLogin: () => Promise<void>;
}

export interface ShowLoginProps extends ShowLoginStateProps, ShowLoginDispatchProps {}

const mapStateToProps = (state: State): ShowLoginStateProps => {
	return {
		showLogin: state.layout.showLogin,
	};
};

// eslint-disable-next-line @typescript-eslint/ban-types
const mapDispatchToProps = (dispatch: Function): ShowLoginDispatchProps => ({
	doShowLogin: () => dispatch(Layout.showLogin()),
	clearShowLogin: () => dispatch(Layout.clearShowLogin()),
});

const withShowLogin = connect(mapStateToProps, mapDispatchToProps) as Infer<ShowLoginProps>;

// eslint-disable-next-line @typescript-eslint/no-empty-function
export const useShowLogin = () => useRedux(mapStateToProps, mapDispatchToProps, () => {});

export default withShowLogin;
