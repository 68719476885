import * as React from "react";

import Icon from "@bokio/elements/Icon/Icon";
import { LoadingText } from "@bokio/elements/Loading";
import { GeneralLangFactory } from "@bokio/lang";
import { getUser } from "@bokio/shared/state/requests";

import type { MenuCurrentUserProps } from "./MenuCurrentUser";

import * as styles from "./menuCurrentUser.scss";

export interface MenuCurrentUserMobileProps extends MenuCurrentUserProps {
	showManageUser: () => void;
}

class MenuCurrentUserMobile extends React.Component<MenuCurrentUserMobileProps> {
	render() {
		const { isLoadingUser, user } = this.props;
		const lang = GeneralLangFactory();
		if (isLoadingUser) {
			return (
				<div>
					<LoadingText width="70%" />
				</div>
			);
		}

		if (!user) {
			return <div>{lang.NoAccount}</div>;
		}

		return (
			<div className={styles.userSettingsMobile}>
				<span className={styles.label}>{lang.Menu_Profile}</span>
				<a onClick={this.props.showManageUser}>
					{user.FirstName ? `${user.FirstName} ${user.LastName}` : lang.MyAccount}
					<Icon name="right-open-big" size="12" />
				</a>
			</div>
		);
	}
}

export default getUser.connect()(MenuCurrentUserMobile);
