import type * as m from "@bokio/mobile-web-shared/core/model/model";
import type { State } from "@bokio/shared/state/state";

export const isEnabledIn = (feature: m.Core.Features, features: m.Core.FeatureState[] | null | undefined): boolean => {
	if (!features) {
		return false;
	}

	const toggle = features.find(f => f.Key === feature);
	if (!toggle) {
		return false;
	}
	return toggle.Active;
};

export const getCurrentFeatures = (currentState: State | undefined): m.Core.FeatureState[] | null => {
	if (!currentState) {
		return null;
	}
	const companyInfo = currentState.requests.getCompanyUser.data;
	const agencyInfo = currentState.requests.getAgencyStatus.data?.Data;
	if (!companyInfo) {
		if (agencyInfo) {
			return agencyInfo.Features;
		}
		return null;
	}
	return companyInfo.Features;
};

export const isEnabled = (feature: m.Core.Features, state: State) => isEnabledIn(feature, getCurrentFeatures(state));
