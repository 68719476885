import * as m from "@bokio/mobile-web-shared/core/model/model";
import { store } from "@bokio/shared/store";

import * as FeatureHelpers from "./FeatureHelpers";

import Features = m.Core.Features;

export default {
	IsEnabled: (feature: Features): boolean => FeatureHelpers.isEnabled(feature, store.getState()),
	UnderDevelopmentEnabled: (): boolean => FeatureHelpers.isEnabled(Features.UnderDevelopment, store.getState()),
};
