import * as React from "react";

import { useCashbackContext } from "@bokio/contexts/CashbackContext/CashbackContext";
import { Checklist } from "@bokio/designsystem/components/Checklist/Checklist";
import { Heading, Paragraph, Section } from "@bokio/designsystem/components/TypographicElements/TypographicElements";
import Markdown from "@bokio/elements/Markdown/Markdown";
import { Notice } from "@bokio/elements/Notice/Notice";
import { OnboardingLangFactory } from "@bokio/lang";
import { CURRENCY } from "@bokio/mobile-web-shared/SharedRedLibrary/data/src/currency";
import { useRouter } from "@bokio/shared/containers/router/useRouter";
import { getRoute } from "@bokio/shared/route";
import { formatMessage, formatNumberCurrency } from "@bokio/shared/utils/format";

import { PromotionModalButtons } from "../PromotionModalButtons/PromotionModalButtons";
import { getPromotionModalPremiumPlusChecklist, premiumPlusPrice } from "../PromotionModalHelper";

import type { PromotionModalInnerProps } from "../promotionModalModels";

import * as styles from "../promotionModal.scss";

export const HighDepositFree: React.FC<PromotionModalInnerProps> = ({ resolve, dismiss, companyId }) => {
	const onboardingLang = OnboardingLangFactory();

	const router = useRouter();
	const cashbacks = useCashbackContext();
	if (!cashbacks.initialized) {
		return null;
	}

	const onResolve = () => {
		resolve();
		router.redirect(getRoute("billing", { company: companyId }));
	};

	const onDissmiss = () => {
		dismiss();
		router.redirect(getRoute("exportData", { company: companyId }));
	};

	const premiumPlusChecklist = getPromotionModalPremiumPlusChecklist(cashbacks.defaultContract);

	return (
		<div className={styles.promotionModal} data-testid="PromotionModal_HighDepositFree">
			<Section resetHeadingLevelTo={2}>
				<Heading>
					{formatMessage(
						onboardingLang.PromotionModal_HightDepositFree_heading,
						<span className={styles.premiumPlus}>Plus</span>,
						formatNumberCurrency(cashbacks.estimatedCashback, CURRENCY.SEK, 0),
					)}
				</Heading>
				<Paragraph>{onboardingLang.PromotionModal_HightDepositFree_preamble}</Paragraph>
			</Section>

			<ul className={styles.promotionBoxes}>
				<li>
					<h3>
						{formatMessage(
							onboardingLang.PromotionModal_HighDepositFree_boxHeader,
							<span className={styles.premiumPlus}>Plus</span>,
						)}
					</h3>

					<p>
						{formatMessage(
							onboardingLang.PromotionModal_HighDepositFree_boxText,
							formatNumberCurrency(cashbacks.estimatedCashback, CURRENCY.SEK, 0),
							<span className={styles.premiumPlus}>Plus</span>,
							formatNumberCurrency(premiumPlusPrice || 0, CURRENCY.SEK, 0),
							<a onClick={onResolve}>{onboardingLang.PromotionModal_HighDepositFree_linkText}</a>,
						)}
					</p>

					<Checklist
						heading={`${onboardingLang.PromotionModal_HighDepositFree_listHeader}:`}
						list={premiumPlusChecklist}
					/>
				</li>
				<li className={styles.coveredPromotionBox}>
					<Notice
						margin={[]}
						color="promotion"
						collapsible={false}
						title={onboardingLang.PromotionModal_HightDepositFree_notice_title}
					>
						<Markdown
							className={styles.promotionBoxMarkdown}
							markdownContent={onboardingLang.PromotionModal_HightDepositFree_notice_content}
						/>
					</Notice>
				</li>
			</ul>

			<PromotionModalButtons
				horizontal={true}
				ctaButtonText={onboardingLang.PromotionModal_LowDepositFree_cta_button}
				resolve={onResolve}
				dismiss={onDissmiss}
				cancelButtonText={onboardingLang.PromotionModal_LowDepositFree_cancel_button}
			/>
		</div>
	);
};
