import * as React from "react";

/**
 * Utility component that tells spacing group to not wrap this with a child wrapper.
 *
 * SS 2021-09-17
 * It's easier for a developer to put modal next to the component invoking it,
 * but since there isn't an easy and stable way to inspect names in minified bundle,
 * and it's not that maintable having to add everything needs to be ignored into a list of names like in ButtonGroup,
 * because the scale is diffrent here.
 * Therefore we need an util component for ignoring things.
 *
 * @example
 * <SG>
 *   <Button onClick={openModal} />
 *   <Button onClick={doSomethingElse} />
 *   <SGIgnore>
 *     <Modal>...</Modal>
 *   </SGIgnore>
 * </SG>
 */
export const SGIgnore: React.FC = props => {
	return <>{props.children}</>;
};
