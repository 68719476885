import * as React from "react";

import Icon from "@bokio/elements/Icon/Icon";
import { Tooltip } from "@bokio/elements/Tooltip";
import * as m from "@bokio/mobile-web-shared/core/model/model";

import type { IconColor } from "@bokio/elements/Icon/Icon";

import * as styles from "./paymentWarnings.scss";

import PaymentWarningLevel = m.Bokio.Bank.Contract.Dtos.PaymentWarningLevel;
type PaymentWarning = m.Bokio.Bank.Contract.Dtos.PaymentWarning;
type PaymentGroupForListDto = m.Bokio.Bank.Contract.Dtos.PaymentGroupForListDto;

export const getWarningsFromPaymentGroup = (group: PaymentGroupForListDto) =>
	group.IsBatch ? group.Warnings : group.Payments[0].Warnings;

interface PaymentWarningsProps {
	warnings: m.Bokio.Bank.Contract.Dtos.PaymentWarning[] | undefined;
	isGroup: boolean;
	isSigning: boolean;
}

const WARNING_LEVEL: Record<PaymentWarningLevel, number> = {
	[PaymentWarningLevel.Info]: 1,
	[PaymentWarningLevel.Warning]: 2,
	[PaymentWarningLevel.Error]: 3,
};

const WARNING_LEVEL_COLORS: Record<PaymentWarningLevel, IconColor> = {
	[PaymentWarningLevel.Info]: "blue",
	[PaymentWarningLevel.Warning]: "yellow",
	[PaymentWarningLevel.Error]: "red",
};

export const PaymentWarnings = (props: PaymentWarningsProps) => {
	const { warnings } = props;

	if (!warnings || warnings.length === 0) {
		return null;
	}

	const highestWarningLevel = warnings
		.map(w => w.Level)
		.reduce((highest, current) => {
			return WARNING_LEVEL[current] > WARNING_LEVEL[highest] ? current : highest;
		});

	const getWarningText = (w: PaymentWarning) => (props.isSigning ? w.SigningMessageOverride || w.Message : w.Message);

	const generateWarningContent = () => {
		return (
			<div className={styles.warningContent}>
				{warnings.length > 1 ? (
					<ul className={styles.list}>
						{warnings.map(w => (
							<li key={w.Type}>{getWarningText(w)}</li>
						))}
					</ul>
				) : (
					<span>{getWarningText(warnings[0])}</span>
				)}
			</div>
		);
	};

	return (
		<Tooltip contentGenerator={generateWarningContent}>
			<Icon name="attention-alt" color={WARNING_LEVEL_COLORS[highestWarningLevel]} size="20" className={styles.icon} />
		</Tooltip>
	);
};
