import * as React from "react";

import { useCashbackContext } from "@bokio/contexts/CashbackContext/CashbackContext";
import { Checklist } from "@bokio/designsystem/components/Checklist/Checklist";
import { Heading, Paragraph, Section } from "@bokio/designsystem/components/TypographicElements/TypographicElements";
import { Notice } from "@bokio/elements/Notice/Notice";
import { OnboardingLangFactory } from "@bokio/lang";
import { CURRENCY } from "@bokio/mobile-web-shared/SharedRedLibrary/data/src/currency";
import { useRouter } from "@bokio/shared/containers/router/useRouter";
import { getRoute } from "@bokio/shared/route";
import { formatMessage, formatNumberCurrency } from "@bokio/shared/utils/format";

import { PromotionModalButtons } from "../PromotionModalButtons/PromotionModalButtons";
import { getPromotionModalPremiumPlusChecklist, premiumPlusPrice, premiumPrice } from "../PromotionModalHelper";

import type { PromotionModalInnerProps } from "../promotionModalModels";

import * as styles from "../promotionModal.scss";

export const LowDepositFree: React.FC<PromotionModalInnerProps> = ({ resolve, dismiss, companyId }) => {
	const onboardingLang = OnboardingLangFactory();
	const router = useRouter();
	const cashbacks = useCashbackContext();
	if (!cashbacks.initialized) {
		return null;
	}

	// PA, 2023-09-20: This translation is a | separated string that is made into an Array of strings
	const premiumChecklist = onboardingLang.PromotionModal_LowDepositFree_Premium_checklist.toString()
		.split("|")
		.map(listItemText => listItemText.trim());

	const premiumPlusChecklist = getPromotionModalPremiumPlusChecklist(cashbacks.defaultContract);

	const onResolve = () => {
		resolve();
		router.redirect(getRoute("billing", { company: companyId }));
	};

	const onDissmiss = () => {
		dismiss();
		router.redirect(getRoute("exportData", { company: companyId }));
	};

	return (
		<div className={styles.promotionModal} data-testid="PromotionModal_LowDepositFree">
			<Section resetHeadingLevelTo={2}>
				<Heading>{onboardingLang.PromotionModal_LowDepositFree_heading}</Heading>
				<Paragraph>{onboardingLang.PromotionModal_LowDepositFree_preamble}</Paragraph>
			</Section>

			<Notice title={onboardingLang.PromotionModal_LowDepositFree_Notice_header} collapsible={false} color="promotion">
				{onboardingLang.PromotionModal_LowDepositFree_Notice_text}
			</Notice>

			<ol className={styles.promotionBoxes}>
				<li>
					<h3 className={styles.noBottomMargin}>Premium</h3>
					<p>
						{formatMessage(
							onboardingLang.PromotionModal_LowDepositFree_Premium_price,
							formatNumberCurrency(premiumPrice, CURRENCY.SEK, 0),
						)}
					</p>
					<Checklist list={premiumChecklist} />
				</li>
				<li>
					<h3 className={styles.noBottomMargin}>
						Premium <span className={styles.highlightPlus}>Plus</span>
					</h3>
					<p>
						{formatMessage(
							onboardingLang.PromotionModal_LowDepositFree_PremiumPlus_price,
							formatNumberCurrency(premiumPlusPrice, CURRENCY.SEK, 0),
						)}
					</p>
					<Checklist list={premiumPlusChecklist} />
				</li>
			</ol>

			<PromotionModalButtons
				horizontal={true}
				ctaButtonText={onboardingLang.PromotionModal_LowDepositFree_cta_button}
				resolve={onResolve}
				dismiss={onDissmiss}
				cancelButtonText={onboardingLang.PromotionModal_LowDepositFree_cancel_button}
			/>
		</div>
	);
};
