import * as React from "react";

import ReleaseNotesToast from "@bokio/backoffice/src/components/ReleaseNotesToast/ReleaseNotesToast";
import { WhatsNewFlyout } from "@bokio/components/Help/Help/components/WhatsNewFlyout/WhatsNewFlyout";
import { AppMessageType, useAppContext } from "@bokio/contexts/AppContext/AppContext";
import { useReleaseNotesContext } from "@bokio/contexts/ReleaseNotesContext/ReleaseNotesContext";
import { AgencyAnalyticsEventCategory, useAgencyMetric } from "@bokio/hooks/useAgencyMetric/useAgencyMetric";
import { useModalStack } from "@bokio/hooks/useModalStack/useModalStack";
import { AccountingLangFactory, GeneralLangFactory } from "@bokio/lang";
import * as m from "@bokio/mobile-web-shared/core/model/model";
import { getRoute } from "@bokio/shared/route";
import { formatMessage } from "@bokio/shared/utils/format";
import htmlDecode from "@bokio/shared/utils/htmlDecode";
import { mergeClassNames } from "@bokio/utils/classes";

import { Link } from "../Link/Link";
import { RatingToast } from "../RatingToast/RatingToast";
import DetailedMessage from "./DetailedMessage";
import Toaster from "./Toaster";

import type { AppMessage } from "@bokio/contexts/AppContext/AppContext";

import * as styles from "./appMessagesOverlay.scss";

interface AppMessagesOverlayProps {
	className?: string;
	isAlignRight?: boolean;
}

const inReverseOrder = <T,>(arr: T[]) => {
	const newarr = [...arr];
	newarr.reverse();
	return newarr;
};

interface MessageToasterProps {
	message: AppMessage;
}
import RatingType = m.Entities.Feedback.RatingType;
const MessageToaster = ({ message }: MessageToasterProps): JSX.Element => {
	const lang = GeneralLangFactory();
	const accountingLang = AccountingLangFactory();
	const { whatsNewData } = useReleaseNotesContext();
	const modals = useModalStack();
	const [addMetric] = useAgencyMetric();

	const isOpen = React.useRef(true);

	const app = useAppContext();
	const onClose = () => app.dismissMessage(message.key);

	const onModalClose = () => {
		app.dismissMessage(message.key);
		isOpen.current = false;
	};

	const onClickWhatNew = () => {
		modals.addModal(() => (
			<WhatsNewFlyout onClose={() => onModalClose()} isOpen={isOpen.current} whatsNewData={whatsNewData} />
		));
	};

	switch (message.type) {
		case AppMessageType.DetailedError:
			return (
				<Toaster testId="AppMessage_DetailedError" type="error" className={styles.slideIn} onClose={onClose}>
					<DetailedMessage title={message.title} content={message.content} referenceId={message.refrenceId} />
				</Toaster>
			);
		case AppMessageType.ErrorMessage:
			return (
				<Toaster type="error" className={styles.slideIn} onClose={onClose} testId={message.testId}>
					{/* eslint-disable-next-line react/no-danger */}
					<span dangerouslySetInnerHTML={{ __html: htmlDecode(message.message) }} />
				</Toaster>
			);
		case AppMessageType.SuccessMessage:
			return (
				<Toaster type="success" className={styles.slideIn} onClose={onClose} testId={message.testId}>
					<DetailedMessage title={message.title} content={message.content} />
				</Toaster>
			);
		case AppMessageType.WarningMessage:
			return (
				<Toaster type="warning" className={styles.slideIn} onClose={onClose} testId={message.testId}>
					<span>{message.message}</span>
				</Toaster>
			);
		case AppMessageType.InfoMessage:
			return (
				<Toaster type="info" className={styles.slideIn} onClose={onClose} testId={message.testId}>
					<span>{message.message}</span>
				</Toaster>
			);
		case AppMessageType.NewAppVersion:
			return (
				<Toaster testId="AppMessage_NewAppVersion" type="info" className={styles.slideIn} onClose={onClose}>
					<span>
						{lang.NewUpdate_Text} <Link onClick={() => app.reloadForNewVersion("ToasterClick")}>{lang.Refresh}</Link>
					</span>
				</Toaster>
			);
		case AppMessageType.BankSyncNotification:
			return (
				<Toaster testId="BankFeed_SyncNotification" type="info" className={styles.slideIn} onClose={onClose}>
					<span>
						{formatMessage(accountingLang.BankFeed_SyncMessage, text => (
							<Link onClick={onClose} route={getRoute("todo", { company: message.message.CompanyId })}>
								{text}
							</Link>
						))}
					</span>
				</Toaster>
			);

		case AppMessageType.StripeNotification:
			return (
				<Toaster type="info" className={styles.slideIn} onClose={onClose}>
					<span>{message.message}</span>
				</Toaster>
			);
		case AppMessageType.BookkeepingRating:
			return (
				<RatingToast
					className={mergeClassNames(styles.slideIn, styles.wrapper)}
					onClose={onClose}
					onCancel={message.onCancel}
					type={message.ratingType}
					renderStepTitle={message.renderStepTitle}
					renderStepDescription={message.renderStepDescription}
					additionalData={message.additionalData}
				/>
			);
		case AppMessageType.InvoicingRating:
			return (
				<RatingToast
					testId={"InvoiceRating"}
					className={mergeClassNames(styles.slideIn, styles.wrapper)}
					onClose={onClose}
					onCancel={message.onCancel}
					type={message.ratingType}
					renderStepTitle={message.renderStepTitle}
					renderStepDescription={message.renderStepDescription}
					additionalData={message.additionalData}
				/>
			);
		case AppMessageType.ClosureRating:
			return (
				<RatingToast
					testId={"ClosureRating"}
					className={mergeClassNames(styles.slideIn, styles.wrapper)}
					onClose={onClose}
					onCancel={message.onCancel}
					type={message.ratingType}
					renderStepTitle={message.renderStepTitle}
					renderStepDescription={message.renderStepDescription}
					additionalData={message.additionalData}
				/>
			);
		case AppMessageType.VacationYearEndRating:
			return (
				<RatingToast
					testId={"VacationYearEndRating"}
					className={mergeClassNames(styles.slideIn, styles.wrapper)}
					onClose={onClose}
					onCancel={message.onCancel}
					type={message.ratingType}
					renderStepTitle={message.renderStepTitle}
					renderStepDescription={message.renderStepDescription}
					additionalData={message.additionalData}
				/>
			);
		case AppMessageType.QuoteRating:
			return (
				<RatingToast
					testId={"QuotingRating"}
					className={mergeClassNames(styles.slideIn, styles.wrapper)}
					onClose={onClose}
					onCancel={message.onCancel}
					type={message.ratingType}
					renderStepTitle={message.renderStepTitle}
					renderStepDescription={message.renderStepDescription}
					additionalData={message.additionalData}
				/>
			);
		case AppMessageType.ReceivingEInvoiceRating:
			return (
				<RatingToast
					className={mergeClassNames(styles.slideIn, styles.wrapper)}
					onClose={onClose}
					onCancel={message.onCancel}
					type={message.ratingType}
					renderStepTitle={message.renderStepTitle}
					renderStepDescription={message.renderStepDescription}
					additionalData={message.additionalData}
				/>
			);
		case AppMessageType.CESFeedbackRating:
			return (
				<RatingToast
					testId={"CESFeedbackRating"}
					className={mergeClassNames(styles.slideIn, styles.wrapper)}
					onClose={onClose}
					onCancel={message.onCancel}
					type={message.ratingType}
					renderStepTitle={message.renderStepTitle}
					renderStepDescription={message.renderStepDescription}
					additionalData={message.additionalData}
				/>
			);
		case AppMessageType.AgencyCESFeedbackRating:
			return (
				<RatingToast
					testId={"AgencyCESFeedbackRating"}
					className={mergeClassNames(styles.slideIn, styles.wrapper)}
					onClose={onClose}
					onCancel={message.onCancel}
					type={RatingType.Unknown}
					partnerRatingType={message.ratingType}
					renderStepTitle={message.renderStepTitle}
					renderStepDescription={message.renderStepDescription}
					additionalData={message.additionalData}
				/>
			);
		case AppMessageType.TrialPlanNotification:
			return (
				<Toaster testId={"TrialPlanToast"} type="info" className={styles.slideIn} onClose={onClose}>
					<span>{message.message}</span>
				</Toaster>
			);
		case AppMessageType.ReleaseNoteUpdatesInAgency:
			return (
				<ReleaseNotesToast
					testId={"AgencyReleaseNoteOverLay"}
					noOfUpdates={message.noOfUpdates}
					className={styles.slideIn}
					message={message.message}
				>
					<Link
						onClick={async () => {
							await message.onCloseWhatsNewFlyout();
							onClickWhatNew();
							addMetric(
								AgencyAnalyticsEventCategory.WhatsNewAgencyOverview,
								"Click read more",
								"WhatsNewAgencyOverview",
								1,
							);
						}}
						className={styles.link}
					>
						{lang.ReadMore}
					</Link>
				</ReleaseNotesToast>
			);
		case AppMessageType.ReleaseNoteUpdatesInCompany:
			return (
				<ReleaseNotesToast
					testId={"AgencyReleaseNoteOverLay"}
					noOfUpdates={message.noOfUpdates}
					className={styles.slideIn}
					message={message.message}
				>
					<Link
						onClick={() => {
							addMetric(
								AgencyAnalyticsEventCategory.WhatsNewCompanyOverview,
								"Click visit backoffice",
								"WhatsNewCompanyOverview",
								1,
								message.agencyId,
							);
						}}
						route={getRoute("agencyOverview", { agencyId: message.agencyId })}
						className={styles.link}
					>
						{lang.Visit_Backoffice}
					</Link>
				</ReleaseNotesToast>
			);
	}
};

export const AppMessagesOverlay = ({ className, isAlignRight }: AppMessagesOverlayProps) => {
	const app = useAppContext();

	const filteredApp = isAlignRight
		? app.messages.filter(m => m.position === "right")
		: app.messages.filter(m => m.position !== "right");

	return (
		<div className={mergeClassNames(styles.container, className)}>
			{inReverseOrder(filteredApp).map(message => (
				<MessageToaster key={message.key} message={message} />
			))}
		</div>
	);
};
