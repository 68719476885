import { GeneralLangFactory, SettingsLangFactory } from "@bokio/lang";
import * as m from "@bokio/mobile-web-shared/core/model/model";
import { CURRENCY } from "@bokio/mobile-web-shared/SharedRedLibrary/data/src/currency";
import { getRoute } from "@bokio/shared/route";
import { formatMessage, formatNumberCurrency } from "@bokio/shared/utils/format";

import { trackfunnel_completedPurchasePlan } from "./funnel";
import { trackEvent } from "./t";

import BokioPlan = m.Entities.BokioPlan;
import SubscriptionInterval = m.Bokio.Common.Billing.Model.SubscriptionInterval;

type CashbackContract = m.Bokio.Common.Billing.Model.CashbackContract;
export const planValueOrder: BokioPlan[] = [BokioPlan.Free, BokioPlan.Premium, BokioPlan.Pro];
export const planIntervalOrder: SubscriptionInterval[] = [
	SubscriptionInterval.Month,
	SubscriptionInterval.Quarter,
	SubscriptionInterval.Year,
];

export const getPlanName = (plan: BokioPlan): string => {
	const generalLang = GeneralLangFactory();

	switch (plan) {
		case BokioPlan.FreeShared: // This shouldn't happen but we need to keep it for backwards compatibility
		case BokioPlan.Free:
		case BokioPlan.Beta:
			return generalLang.Free;
		case BokioPlan.Balance:
			return generalLang.BokioPlan_Name_Balance;
		case BokioPlan.Business:
			return generalLang.BokioPlan_Name_Business;
		case BokioPlan.Premium:
			return generalLang.BokioPlan_Name_Premium;
		case BokioPlan.Pro:
			return generalLang.BokioPlan_Name_PremiumPlus;
		case BokioPlan.Invalid:
			return "";
	}
};

export const getIntervalName = (interval: SubscriptionInterval | undefined) => {
	const settingsLang = SettingsLangFactory();
	switch (interval) {
		case SubscriptionInterval.Year:
			return settingsLang.Plans_BuyModal_Yearly;
		case SubscriptionInterval.Quarter:
			return settingsLang.Plans_BuyModal_Quarterly;
		case SubscriptionInterval.Month:
			return settingsLang.Plans_BuyModal_Monthly;
		default:
			return "";
	}
};

export type IntervalTextType = "billing" | "billing-cycle";
export const getIntervalText = (interval: SubscriptionInterval, type: IntervalTextType = "billing") => {
	const settingsLang = SettingsLangFactory();

	switch (true) {
		case interval === SubscriptionInterval.Month && type === "billing":
			return settingsLang.Plans_BuyModal_MonthlyBilling;
		case interval === SubscriptionInterval.Month && type === "billing-cycle":
			return settingsLang.Plans_Monthly;

		case interval === SubscriptionInterval.Quarter && type === "billing":
			return settingsLang.Plans_BuyModal_QuarterlyBilling;
		case interval === SubscriptionInterval.Quarter && type === "billing-cycle":
			return settingsLang.Plans_Quaterly;

		case interval === SubscriptionInterval.Year && type === "billing":
			return settingsLang.Plans_BuyModal_YearlyBilling;
		case interval === SubscriptionInterval.Year && type === "billing-cycle":
			return settingsLang.Plans_Yearly;

		default:
			return "";
	}
};

export const getPerIntervalText = (interval: SubscriptionInterval) => {
	const settingsLang = SettingsLangFactory();
	switch (interval) {
		case SubscriptionInterval.Month:
			return settingsLang.Plans_BuyModal_PerMonth;
		case SubscriptionInterval.Quarter:
			return settingsLang.Plans_BuyModal_PerQuarter;
		case SubscriptionInterval.Year:
			return settingsLang.Plans_BuyModal_PerYear;
		default:
			return "";
	}
};

export const getIntervalPaymentText = (interval: SubscriptionInterval) => {
	const settingsLang = SettingsLangFactory();
	switch (interval) {
		case SubscriptionInterval.Month:
			return settingsLang.Plans_EPB_Text_Monthly;
		case SubscriptionInterval.Quarter:
			return settingsLang.Plans_EPB_Text_Quarterly;
		case SubscriptionInterval.Year:
			return settingsLang.Plans_EPB_Text_Yearly;
		default:
			return "";
	}
};

export const getIntervalSubscriptionCostText = (interval: SubscriptionInterval) => {
	const settingsLang = SettingsLangFactory();
	switch (interval) {
		case SubscriptionInterval.Month:
			return settingsLang.Plans_Checkout_SubscriptionCostMonthly;
		case SubscriptionInterval.Quarter:
			return settingsLang.Plans_Checkout_SubscriptionCostQuarterly;
		case SubscriptionInterval.Year:
			return settingsLang.Plans_Checkout_SubscriptionCostYearly;
		default:
			return "";
	}
};

export const getPlanDescription = (plan: BokioPlan): string => {
	const settingsLang = SettingsLangFactory();

	switch (plan) {
		case BokioPlan.FreeShared: // This shouldn't happen bug we need to keep it for backwards compatibility
		case BokioPlan.Free:
		case BokioPlan.Beta:
			return settingsLang.Plans_BuyModal_Free_Description;
		case BokioPlan.Premium:
			return settingsLang.Plans_BuyModal_Premium_Description;
		case BokioPlan.Pro:
			return settingsLang.Plans_BuyModal_Pro_Description;
		case BokioPlan.Business:
		case BokioPlan.Balance:
		case BokioPlan.Invalid:
			return "";
	}
};

export const getIntervalOptions = (
	plan: m.Bokio.Backbone.Web.Controllers.Settings.PriceplansPlanModel,
	interval: SubscriptionInterval,
): m.Bokio.Common.Billing.Model.BillingIntervalOption => {
	switch (interval) {
		case SubscriptionInterval.Month:
			return plan.IntervalOptions.Monthly;
		case SubscriptionInterval.Quarter:
			return plan.IntervalOptions.Quarterly;
		case SubscriptionInterval.Year:
			return plan.IntervalOptions.Yearly;
		default:
			throw new Error(`Unknown interval ${interval}.`);
	}
};

export const isIntervalAvailableInterval = (
	plan: m.Bokio.Backbone.Web.Controllers.Settings.PriceplansPlanModel,
	interval: SubscriptionInterval | undefined,
) => {
	switch (interval) {
		case SubscriptionInterval.Month:
			return (
				plan.IntervalOptions.Monthly.Visibility === m.Bokio.Common.Billing.Model.BillingIntervalVisibility.Available
			);
		case SubscriptionInterval.Quarter:
			return (
				plan.IntervalOptions.Quarterly.Visibility === m.Bokio.Common.Billing.Model.BillingIntervalVisibility.Available
			);
		case SubscriptionInterval.Year:
			return (
				plan.IntervalOptions.Yearly.Visibility === m.Bokio.Common.Billing.Model.BillingIntervalVisibility.Available
			);
		default:
			return false;
	}
};

export const getSelectedTierFromPlans = (
	currentPlan: m.Bokio.Backbone.Web.Controllers.Settings.ActivatedPricePlanModel,
	nextPlan: m.Bokio.Backbone.Web.Controllers.Settings.ActivatedPricePlanModel | undefined,
): m.Entities.BokioPlan => {
	if (nextPlan && nextPlan?.Key === BokioPlan.Free && currentPlan.Key === BokioPlan.Pro) {
		return m.Entities.BokioPlan.Pro;
	}

	if ((nextPlan && nextPlan?.Key === BokioPlan.Free) || (!nextPlan && currentPlan.Key === BokioPlan.Free)) {
		return m.Entities.BokioPlan.Premium;
	}

	return nextPlan && nextPlan?.Key !== BokioPlan.Free ? nextPlan.Key : currentPlan.Key;
};

export const getSelectedIntervalFromPlans = (
	currentPlan: m.Bokio.Backbone.Web.Controllers.Settings.ActivatedPricePlanModel,
	nextPlan: m.Bokio.Backbone.Web.Controllers.Settings.ActivatedPricePlanModel | undefined,
) => {
	return nextPlan && nextPlan?.Key !== BokioPlan.Free
		? nextPlan.SubscriptionInterval
		: currentPlan.SubscriptionInterval;
};

export const getDefaultSelectedInterval = (plan: m.Bokio.Backbone.Web.Controllers.Settings.PriceplansPlanModel) => {
	if (isIntervalAvailableInterval(plan, SubscriptionInterval.Quarter)) {
		return SubscriptionInterval.Quarter;
	} else if (isIntervalAvailableInterval(plan, SubscriptionInterval.Year)) {
		return SubscriptionInterval.Year;
	} else if (isIntervalAvailableInterval(plan, SubscriptionInterval.Month)) {
		return SubscriptionInterval.Month;
	}

	return undefined;
};

export const getBasePrice = (plan: m.Bokio.Backbone.Web.Controllers.Settings.PriceplansPlanModel) => {
	if (plan.IntervalOptions.Monthly.Visibility === m.Bokio.Common.Billing.Model.BillingIntervalVisibility.Available) {
		return plan.IntervalOptions.Monthly.PriceExclVat;
	} else if (
		plan.IntervalOptions.Quarterly.Visibility === m.Bokio.Common.Billing.Model.BillingIntervalVisibility.Available
	) {
		return plan.IntervalOptions.Quarterly.PriceExclVat;
	} else if (
		plan.IntervalOptions.Yearly.Visibility === m.Bokio.Common.Billing.Model.BillingIntervalVisibility.Available
	) {
		return plan.IntervalOptions.Yearly.PriceExclVat;
	}
	return 0;
};

export const getNrOfDecimals = (currency: string) => {
	return currency === CURRENCY.GBP ? 2 : 0;
};

export const calculateCashback = (
	contract: m.Bokio.Common.Billing.Model.CashbackContract,
	capital: number,
	cardspend: number,
) => {
	const maxCardSpend = 100000000; //We don't have any max limit on card spend, but just to avoid overflow lets count with 100M

	const cardSpendCashback =
		(Math.min(Math.max(cardspend, 0), maxCardSpend) * contract.CardSpendCashbackPercentage) / 100;

	const cappedDepositedCapital =
		contract.DepositedCapitalCashbackCap !== undefined
			? Math.min(Math.max(capital, 0), contract.DepositedCapitalCashbackCap)
			: Math.max(capital, 0);

	const depositedCapitalCashback = ((cappedDepositedCapital / 12) * contract.DepositedCapitalCashbackPercentage) / 100;
	const totalCashback = cardSpendCashback + depositedCapitalCashback;

	return { totalCashback, cardSpendCashback, depositedCapitalCashback };
};

export const formatPriceplanPercentage = (percentage: number) => {
	return (percentage ? percentage : 0) + "%";
};

export const getMonthsFromInterval = (interval: SubscriptionInterval) => {
	switch (interval) {
		case SubscriptionInterval.Month:
			return 1;
		case SubscriptionInterval.Quarter:
			return 3;
		case SubscriptionInterval.Year:
			return 12;
		default:
			throw new Error(`Unknown interval ${interval}.`);
	}
};

export const getBilledMessage = (subscriptionInterval: SubscriptionInterval) => {
	const settingsLang = SettingsLangFactory();
	switch (subscriptionInterval) {
		case SubscriptionInterval.Month:
			return settingsLang.Plans_BilledMonthly;
		case SubscriptionInterval.Quarter:
			return settingsLang.Plans_BilledQuarterly;
		case SubscriptionInterval.Year:
			return settingsLang.Plans_BilledYearly;
		default:
			return "";
	}
};

export const getCurrentPlanPaymentText = (
	interval: SubscriptionInterval,
	isDowngrading: boolean,
	paymentAmount: number | undefined,
	dateOfInterest: m.Day | undefined,
	currency: string,
) => {
	const settingsLang = SettingsLangFactory();

	let formatedPaymentAmount;
	if (paymentAmount !== undefined) {
		formatedPaymentAmount = formatNumberCurrency(paymentAmount, currency, getNrOfDecimals(currency));
	} else {
		throw new Error(`Didn't receive a paymentAmount.`);
	}

	if (!isDowngrading) {
		switch (interval) {
			case SubscriptionInterval.Month:
				return `${formatMessage(
					settingsLang.Plans_CurrentPlanBox_MonthlyPayments,
					formatedPaymentAmount,
				)} ${formatMessage(settingsLang.Plans_CurrentPlanBox_NextPayment, dateOfInterest?.toISOString())}`;
			case SubscriptionInterval.Quarter:
				return `${formatMessage(
					settingsLang.Plans_CurrentPlanBox_QuarterlyPayments,
					formatedPaymentAmount,
				)} ${formatMessage(settingsLang.Plans_CurrentPlanBox_NextPayment, dateOfInterest?.toISOString())}`;
			case SubscriptionInterval.Year:
				return `${formatMessage(
					settingsLang.Plans_CurrentPlanBox_YearlyPayments,
					formatedPaymentAmount,
				)} ${formatMessage(settingsLang.Plans_CurrentPlanBox_NextPayment, dateOfInterest?.toISOString())}`;
			default:
				throw new Error(`Unknown interval ${interval}.`);
		}
	} else {
		switch (interval) {
			case SubscriptionInterval.Month:
				return `${formatMessage(
					settingsLang.Plans_CurrentPlanBox_MonthlyPayments,
					formatedPaymentAmount,
				)} ${formatMessage(settingsLang.Plans_CurrentPlanBox_PlanIsEnding, dateOfInterest?.toISOString())}`;
			case SubscriptionInterval.Quarter:
				return `${formatMessage(
					settingsLang.Plans_CurrentPlanBox_QuarterlyPayments,
					formatedPaymentAmount,
				)} ${formatMessage(settingsLang.Plans_CurrentPlanBox_PlanIsEnding, dateOfInterest?.toISOString())}`;
			case SubscriptionInterval.Year:
				return `${formatMessage(
					settingsLang.Plans_CurrentPlanBox_YearlyPayments,
					formatedPaymentAmount,
				)} ${formatMessage(settingsLang.Plans_CurrentPlanBox_PlanIsEnding, dateOfInterest?.toISOString())}`;
			default:
				throw new Error(`Unknown interval ${interval}.`);
		}
	}
};

export const hasFailedPaymentState = (paymentState: m.Entities.BillingSubscriptionPaymentState | undefined) => {
	return (
		paymentState === m.Entities.BillingSubscriptionPaymentState.BlockedDueToMissingPayment ||
		paymentState === m.Entities.BillingSubscriptionPaymentState.PastDue
	);
};

export type PlanChange = "Upgrade" | "Downgrade" | "IntervalChange" | "Cancel";
export type PlanChangeInfo = {
	plan: m.Entities.BokioPlan;
	interval: m.Bokio.Common.Billing.Model.SubscriptionInterval;
	inTrial: boolean;
};
export type PlanChangeType = {
	type: PlanChange;
	upgrade: boolean;
	downgrade: boolean;
};
const planChangeType = (newPlan: PlanChangeInfo, oldPlan: PlanChangeInfo, planDowngrade: boolean): PlanChange => {
	switch (true) {
		case newPlan.plan === m.Entities.BokioPlan.Free:
			return "Cancel";
		case !oldPlan.inTrial && newPlan.plan === oldPlan.plan && oldPlan.interval !== newPlan.interval:
			return "IntervalChange";
		case planDowngrade:
			return "Downgrade";

		default:
			return "Upgrade";
	}
};
export const getPlanChangeType = (oldPlan: PlanChangeInfo, newPlan: PlanChangeInfo): PlanChangeType => {
	const planUpgrade = oldPlan.inTrial || planValueOrder.indexOf(oldPlan.plan) < planValueOrder.indexOf(newPlan.plan);
	const intervalUpgrade =
		newPlan.plan === oldPlan.plan &&
		planIntervalOrder.indexOf(oldPlan.interval) < planIntervalOrder.indexOf(newPlan.interval);

	const planDowngrade = !planUpgrade;
	const intervalDowngrade =
		newPlan.plan === oldPlan.plan &&
		planIntervalOrder.indexOf(newPlan.interval) < planIntervalOrder.indexOf(oldPlan.interval);

	const pcType: PlanChange = planChangeType(newPlan, oldPlan, planDowngrade);

	return {
		type: pcType,
		upgrade: pcType === "Cancel" ? false : pcType === "IntervalChange" ? intervalUpgrade : planUpgrade,
		downgrade: pcType === "Cancel" ? false : pcType === "IntervalChange" ? intervalDowngrade : planDowngrade,
	};
};

export const pricePlanChangeIsUpgrade = (oldPlan: BokioPlan, newPlan: BokioPlan, oldPlanIsTrial = false): boolean => {
	if (oldPlan === BokioPlan.Premium && newPlan === BokioPlan.Premium && oldPlanIsTrial) {
		return true;
	}

	return planValueOrder.indexOf(newPlan) > planValueOrder.indexOf(oldPlan);
};

export const pricePlanChangeIsDowngrade = (oldPlan: BokioPlan, newPlan: BokioPlan, oldPlanIsTrial = false): boolean => {
	if (oldPlanIsTrial || oldPlan === newPlan) {
		return false;
	}

	return planValueOrder.indexOf(newPlan) < planValueOrder.indexOf(oldPlan);
};

export type PerkList = {
	Heading?: string;
	Perks: {
		Perk: string;
		Route?: string;
		bbaIcon?: boolean;
	}[];
};

export const getPlanPerks = (
	plan: BokioPlan,
	companyId: string,
	bokioBusinessAccountAvailable: boolean,
	cashback: CashbackContract,
): PerkList | undefined => {
	const settingsLang = SettingsLangFactory();

	if (plan === BokioPlan.Premium) {
		if (bokioBusinessAccountAvailable) {
			return {
				Heading: settingsLang.Plans_RPU_Perks_Premium_Heading,
				Perks: [
					{ Perk: settingsLang.Plans_RPU_Perks_Premium_SmarterBookkeeping },
					{ Perk: settingsLang.Plans_RPU_Perks_Premium_MoreProfessionalInvoicing },
					{ Perk: settingsLang.Plans_RPU_Perks_Premium_SalaryServices },
					{ Perk: settingsLang.Plans_RPU_Perks_Premium_BankServices },
					{
						Perk: settingsLang.Plans_RPU_Perks_Premium_BokioBusinessAccount,
						Route: getRoute("bankList", { company: companyId }),
						bbaIcon: true,
					},
				],
			};
		} else {
			return {
				Heading: settingsLang.Plans_RPU_Perks_Premium_Heading,
				Perks: [
					{ Perk: settingsLang.Plans_RPU_Perks_Premium_SmarterBookkeeping },
					{ Perk: settingsLang.Plans_RPU_Perks_Premium_MoreProfessionalInvoicing },
					{ Perk: settingsLang.Plans_RPU_Perks_Premium_SalaryServices },
					{ Perk: settingsLang.Plans_RPU_Perks_Premium_BankServices },
				],
			};
		}
	} else if (plan === BokioPlan.Pro) {
		return {
			Heading: settingsLang.Plans_RPU_Perks_PremiumPlus_Heading,
			Perks: [
				{
					Perk: formatMessage(
						settingsLang.Plans_RPU_Perks_PremiumPlus_CashbackCardSpend,
						formatPriceplanPercentage(cashback.CardSpendCashbackPercentage),
					),
				},
				{
					Perk: formatMessage(
						settingsLang.Plans_RPU_Perks_PremiumPlus_CashbackCapital,
						formatPriceplanPercentage(cashback.DepositedCapitalCashbackPercentage),
					),
				},
				{ Perk: settingsLang.Plans_RPU_Perks_PremiumPlus_FreeAccountingAssistance },
				{ Perk: settingsLang.Plans_RPU_Perks_PremiumPlus_PrioSupport },
			],
		};
	}

	return undefined;
};

export const trackPlanEvent = async (
	oldPlan: BokioPlan,
	newPlan: BokioPlan,
	newInterval?: SubscriptionInterval,
	currency?: string,
	planPriceExVat?: number,
) => {
	const oldIndex = planValueOrder.indexOf(oldPlan);
	const newIndex = planValueOrder.indexOf(newPlan);

	if (oldIndex < newIndex) {
		trackEvent("BokioPlan", "Upgrade", `SelectedPlan${newPlan}`);
		if (newInterval && currency) {
			trackfunnel_completedPurchasePlan(newPlan, newInterval, currency, planPriceExVat);
		}
	}
	if (oldIndex === newIndex) {
		trackEvent("BokioPlan", "Same", `SelectedPlan${newPlan}`);
	}
	if (oldIndex > newIndex) {
		trackEvent("BokioPlan", "Downgrade", `SelectedPlan${newPlan}`);
	}
};

export const monthsInInterval = (interval: SubscriptionInterval) => {
	switch (interval) {
		case m.Bokio.Common.Billing.Model.SubscriptionInterval.Month:
			return 1;
		case m.Bokio.Common.Billing.Model.SubscriptionInterval.Quarter:
			return 3;
		case m.Bokio.Common.Billing.Model.SubscriptionInterval.Year:
			return 12;
		default:
			throw new Error("The interval doesn't exist.");
	}
};

// GF 2024-03-28: Returns the monetary value compared to the base offer. Always positive (negative discount would mean negative value).
export const calculateDiscount = (
	plan: m.Bokio.Backbone.Web.Controllers.Settings.PriceplansPlanModel | undefined,
	interval: m.Bokio.Common.Billing.Model.SubscriptionInterval,
) => {
	if (!plan) {
		return 0;
	}

	const intervalOptions = getIntervalOptions(plan, interval);
	const discountPrice = intervalOptions.Discount?.DiscountedPriceExclVat;
	const currentMonthlyPrice = discountPrice ?? intervalOptions.PriceExclVat;

	const baseMonthlyPrice = getBasePrice(plan);
	const discountValue = (baseMonthlyPrice - currentMonthlyPrice) * monthsInInterval(interval);

	if (discountValue < 0) {
		throw new Error("Discount value is negative.");
	}

	return discountValue;
};

export const calculateDiscountPercentage = (
	plan: m.Bokio.Backbone.Web.Controllers.Settings.PriceplansPlanModel | undefined,
	interval: m.Bokio.Common.Billing.Model.SubscriptionInterval,
) => {
	if (!plan) {
		return null;
	}
	const intervalOptions = getIntervalOptions(plan, interval);
	const discountPrice = intervalOptions.Discount?.DiscountedPriceExclVat;
	const currentPrice = discountPrice ?? intervalOptions.PriceExclVat;
	const basePrice = getBasePrice(plan);
	const percentage = (1 - currentPrice / basePrice) * 100;
	return percentage.toFixed(0) + "%";
};

export type PlanState = "starts" | "renews" | "ends" | "none";

const currentAndNextPlanIsPremium = (
	currentPlan: m.Bokio.Backbone.Web.Controllers.Settings.ActivatedPricePlanModel,
	nextPlan: m.Bokio.Backbone.Web.Controllers.Settings.ActivatedPricePlanModel,
): boolean => {
	return currentPlan.Key === m.Entities.BokioPlan.Premium && nextPlan.Key === m.Entities.BokioPlan.Premium;
};
const currentPlanIsPremiumAndTheSubIntervalIsSelected = (
	currentPlan: m.Bokio.Backbone.Web.Controllers.Settings.ActivatedPricePlanModel,
	selectedInterval?: SubscriptionInterval,
): boolean => {
	return currentPlan.Key === m.Entities.BokioPlan.Premium && selectedInterval === currentPlan.SubscriptionInterval;
};

export const getPlanState = (
	plan: m.Bokio.Backbone.Web.Controllers.Settings.PriceplansPlanModel,
	currentPlan: m.Bokio.Backbone.Web.Controllers.Settings.ActivatedPricePlanModel,
	nextPlan?: m.Bokio.Backbone.Web.Controllers.Settings.ActivatedPricePlanModel,
	selectedInterval?: m.Bokio.Common.Billing.Model.SubscriptionInterval,
): PlanState => {
	const isCurrentPlan = plan.Key === currentPlan.Key;
	const notInTrial = !currentPlan.IsTrialing;

	switch (true) {
		case isCurrentPlan &&
			notInTrial &&
			nextPlan &&
			currentPlan.Key === nextPlan.Key &&
			currentPlan.SubscriptionInterval === nextPlan.SubscriptionInterval &&
			currentPlan.SubscriptionInterval === selectedInterval:
			return "renews";

		case isCurrentPlan &&
			notInTrial &&
			nextPlan &&
			nextPlan.Key !== currentPlan.Key &&
			(currentPlan.Key !== m.Entities.BokioPlan.Premium ||
				currentPlanIsPremiumAndTheSubIntervalIsSelected(currentPlan, selectedInterval)):
			return "ends";

		case isCurrentPlan &&
			notInTrial &&
			nextPlan &&
			currentAndNextPlanIsPremium(currentPlan, nextPlan) &&
			selectedInterval === currentPlan.SubscriptionInterval:
			return "ends";

		case nextPlan &&
			currentPlan.Key === m.Entities.BokioPlan.Pro &&
			nextPlan.Key === m.Entities.BokioPlan.Premium &&
			selectedInterval !== nextPlan.SubscriptionInterval:
			return "none";

		case isCurrentPlan &&
			nextPlan &&
			currentAndNextPlanIsPremium(currentPlan, nextPlan) &&
			selectedInterval === nextPlan.SubscriptionInterval:
			return "starts";

		case isCurrentPlan &&
			notInTrial &&
			nextPlan &&
			nextPlan?.Key !== m.Entities.BokioPlan.Free &&
			nextPlan?.Key !== currentPlan?.Key &&
			selectedInterval === currentPlan.SubscriptionInterval:
			return "starts";

		case !isCurrentPlan && plan.Key === nextPlan?.Key:
			return "starts";

		case isCurrentPlan &&
			notInTrial &&
			currentPlan.Key === m.Entities.BokioPlan.Pro &&
			nextPlan?.Key === m.Entities.BokioPlan.Premium:
			return "starts";

		case isCurrentPlan &&
			notInTrial &&
			currentPlan.PaidUntil &&
			selectedInterval === currentPlan.SubscriptionInterval &&
			!nextPlan:
			return "renews";

		default:
			return "none";
	}
};

export type SubscriptionButtonState =
	| "GetStarted"
	| "Upgrade"
	| "Downgrade"
	| "Change"
	| "Cancel"
	| "UndoCancel"
	| null;
export const getSubscriptionButtonState = (
	plan: m.Bokio.Backbone.Web.Controllers.Settings.PriceplansPlanModel,
	currentPlan: m.Bokio.Backbone.Web.Controllers.Settings.ActivatedPricePlanModel,
	nextPlan?: m.Bokio.Backbone.Web.Controllers.Settings.ActivatedPricePlanModel,
	selectedInterval?: m.Bokio.Common.Billing.Model.SubscriptionInterval,
): SubscriptionButtonState | null => {
	const planState = getPlanState(plan, currentPlan, nextPlan, selectedInterval);

	switch (true) {
		case planState === "ends":
			return "UndoCancel";

		case planState === "starts" || planState === "renews":
			return "Cancel";

		case (currentPlan.IsTrialing && nextPlan?.Key === m.Entities.BokioPlan.Free) ||
			(currentPlan.Key === m.Entities.BokioPlan.Free && !nextPlan):
			return "GetStarted";

		case (plan.Key === currentPlan.Key &&
			currentPlan.Key !== m.Entities.BokioPlan.Free &&
			!currentPlan.IsTrialing &&
			pricePlanChangeIsUpgrade(currentPlan.Key, plan.Key, currentPlan.IsTrialing)) ||
			((!currentPlan.IsTrialing ||
				(plan.Key === m.Entities.BokioPlan.Pro &&
					currentPlan.IsTrialing &&
					nextPlan?.Key === m.Entities.BokioPlan.Premium)) &&
				plan.Key === m.Entities.BokioPlan.Pro &&
				currentPlan.Key !== m.Entities.BokioPlan.Pro &&
				currentPlan.Key !== m.Entities.BokioPlan.Free):
			return "Upgrade";

		case currentPlan.IsTrialing &&
			plan.Key === m.Entities.BokioPlan.Premium &&
			nextPlan &&
			nextPlan.Key === m.Entities.BokioPlan.Pro:
			return "Downgrade";

		case plan.Key === m.Entities.BokioPlan.Premium &&
			currentPlan.Key === m.Entities.BokioPlan.Premium &&
			(!currentPlan.IsTrialing || (currentPlan.IsTrialing && nextPlan?.Key !== plan.Key)) &&
			selectedInterval &&
			currentPlan.SubscriptionInterval !== selectedInterval:
			return "Change";

		case plan.Key === m.Entities.BokioPlan.Premium &&
			currentPlan.Key === m.Entities.BokioPlan.Premium &&
			nextPlan &&
			nextPlan.Key === m.Entities.BokioPlan.Premium &&
			selectedInterval &&
			selectedInterval !== nextPlan.SubscriptionInterval:
			return "Change";

		case plan.Key === m.Entities.BokioPlan.Premium &&
			currentPlan.Key === m.Entities.BokioPlan.Pro &&
			nextPlan &&
			nextPlan.Key === m.Entities.BokioPlan.Premium &&
			selectedInterval &&
			selectedInterval !== nextPlan.SubscriptionInterval:
			return "Change";

		case plan.Key === m.Entities.BokioPlan.Premium &&
			currentPlan.Key === m.Entities.BokioPlan.Pro &&
			!currentPlan.IsTrialing:
			return "Downgrade";

		default:
			return null;
	}
};

export const priceForSubscriptionInterval = (
	plan: m.Entities.BokioPlan,
	interval: m.Bokio.Common.Billing.Model.SubscriptionInterval,
	plans: m.Bokio.Backbone.Web.Controllers.Settings.PriceplansPlanModel[],
): number => {
	const intervalOpt = () => {
		switch (true) {
			case interval === m.Bokio.Common.Billing.Model.SubscriptionInterval.Year:
				return "Yearly";
			case interval === m.Bokio.Common.Billing.Model.SubscriptionInterval.Quarter:
				return "Quarterly";
			default:
				return "Monthly";
		}
	};
	const timesMonths =
		interval === m.Bokio.Common.Billing.Model.SubscriptionInterval.Year
			? 12
			: interval === m.Bokio.Common.Billing.Model.SubscriptionInterval.Quarter
				? 3
				: 1;

	const price = [...plans]
		.filter(p => {
			return plan === p.Key;
		})
		.map(p => {
			return p.IntervalOptions[intervalOpt()].PriceExclVat;
		})
		.shift();

	return (price && price * timesMonths) || -1;
};
