import * as React from "react";

interface MultiProviderProps {
	providers: React.FunctionComponent[];
	children: React.ReactNode;
}

export const MultiContextProvider = ({ providers, children }: MultiProviderProps) => (
	<>
		{/* Reverse so they render in the order you specify them*/}
		{providers
			.slice()
			.reverse()
			.reduce(
				(acc, Provider) => (
					<Provider>{acc}</Provider>
				),
				children,
			)}
	</>
);
