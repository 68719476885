import * as React from "react";

import Icon from "@bokio/elements/Icon/Icon";

import BokioLogo from "../BokioLogo/BokioLogo";
import { MenuTopBar } from "../MenuTopBar/MenuTopBar";

import * as styles from "./topBar.scss";

export enum TopBarMode {
	Company,
	BackOffice,
	User,
}

interface TopBarProps {
	toggleMenu: () => void;
	mode: TopBarMode;
}

const TopBar = ({ toggleMenu, mode }: TopBarProps) => {
	const barClassName = mode === TopBarMode.Company || mode === TopBarMode.User ? styles.top : styles.backofficeTop;
	return (
		<>
			<header className={barClassName}>
				{mode !== TopBarMode.User && (
					<button type="button" className={styles.hamburger} onClick={toggleMenu}>
						<Icon name="menu" />
					</button>
				)}
				<BokioLogo className={styles.logo} inverted={mode === TopBarMode.BackOffice} />
				<MenuTopBar userMode={mode === TopBarMode.User} backOfficeMode={mode === TopBarMode.BackOffice} />
			</header>
		</>
	);
};

export default TopBar;
