import * as React from "react";

import { useUploadContext } from "@bokio/contexts/UploadContext/UploadContext";
import { LoadingProgress } from "@bokio/elements/Loading";
import { SplitButton } from "@bokio/elements/SplitButton/SplitButton";
import { GeneralLangFactory } from "@bokio/lang";
import * as m from "@bokio/mobile-web-shared/core/model/model";
import { useCompanyUser, useUserMemberships } from "@bokio/shared/state/requests";
import { mergeClassNames } from "@bokio/utils/classes";
import { trackEvent } from "@bokio/utils/t";

import { DropdownContent } from "./components/DropdownContent/DropdownContent";

import * as styles from "./bookkeepingMenuButton.scss";

export interface BookkeepingMenuButtonProps {
	onSubmit: (receiptId: string, companyId: string) => void;
	className?: string;
	disabledOnBoarding?: boolean;
	onItemClick?: () => void;
	onPrimaryButtonClick?: () => void;
}

export const BookkeepingMenuButton = ({
	onSubmit,
	className,
	disabledOnBoarding,
	onItemClick,
}: BookkeepingMenuButtonProps) => {
	const { companyUserPermissions: access, userStatus, isLoadingCompany, company } = useCompanyUser();
	const { invokeUpload: globalInvokeUpload, requests } = useUploadContext();
	const { memberships } = useUserMemberships();

	// This will close the menu after a single upload is completed
	React.useEffect(() => {
		if (requests && requests.length === 1) {
			onItemClick?.();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [requests?.[0]?.request.data]);

	// This will close the menu after multiple uploads have started
	React.useEffect(() => {
		if (requests && requests.length > 1) {
			onItemClick?.();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [requests?.length]);

	const labelTrackingPrefix = "blue_";
	const trackingCategory = "exp2_Menu";

	if (!(access && userStatus && company) || !access.UploadReceipt) {
		return null;
	}

	const generalLang = GeneralLangFactory();

	const isPartnerAccountant = memberships?.partners.some(p => p.PartnerId === company?.PartnerStatus?.PartnerId);
	const rerouteOnSingleUpload =
		access.Attest &&
		access.Bookkeep &&
		(isPartnerAccountant ||
			!(
				company.HasActivePartner &&
				company.PartnerStatus?.PartnerCompanyType === m.Entities.PartnerCompanyType.FullPartner
			));

	return (
		<React.Fragment>
			<div className={mergeClassNames(styles.container, className)}>
				<SplitButton
					className={styles.uploadButton}
					onlyDropdownMode={true}
					primaryText={generalLang.MenuButton_Upload_CTA}
					testId={"PrimaryUploadButton"}
					onClickSecondary={() => trackEvent(trackingCategory, "Click", `${labelTrackingPrefix}MainSplitCta_Secondary`)}
					menuContent={handle => (
						<DropdownContent
							eventCateogry={trackingCategory}
							labelTrackingPrefix={labelTrackingPrefix}
							company={company}
							companyUserPermissions={access}
							isLoadingCompany={isLoadingCompany}
							userStatus={userStatus}
							onSubmit={onSubmit}
							className={className}
							disabledOnBoarding={disabledOnBoarding}
							onUpload={() => globalInvokeUpload(rerouteOnSingleUpload)}
							onClick={() => {
								handle.onClose();
								onItemClick && onItemClick();
							}}
						/>
					)}
				/>
				{requests && requests.length === 1 && <LoadingProgress paddingTop={true} request={requests[0].request} />}
			</div>
		</React.Fragment>
	);
};
