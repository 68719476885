import * as React from "react";

import Icon from "@bokio/elements/Icon/Icon";
import { Link } from "@bokio/elements/Link/Link";
import { LoadingText } from "@bokio/elements/Loading";
import { GeneralLangFactory } from "@bokio/lang";
import withMenuContainer from "@bokio/shared/containers/layout/withMenuContainer";
import withRouterControls from "@bokio/shared/containers/router/withRouterControls";
import { getRoute } from "@bokio/shared/route";
import { getUser } from "@bokio/shared/state/requests";
import { mergeClassNames } from "@bokio/utils/classes";

import LanguageSwitcherModal from "./LanguageSwitcherModal";

import type { MenuCurrentUserProps } from "./MenuCurrentUser";
import type { MenuDispatchProps, MenuStateProps } from "@bokio/shared/containers/layout/withMenuContainer";
import type { RouterControlsProps } from "@bokio/shared/containers/router/withRouterControls";

// prettier-ignore
import * as styles from "./menuCurrentUser.scss"; // SS 2024-01-24 Our Prettier config breaks the style import order, and in some legacy code we depended on this import order a lot, a mix of tech debt here IMO, ignoring for now.

// prettier-ignore
import * as flagStyles from "./languageSwitcherModal.scss"; // SS 2024-01-24 Our Prettier config breaks the style import order, and in some legacy code we depended on this import order a lot, a mix of tech debt here IMO, ignoring for now.

export interface MenuCurrentUserMobileShowProps extends MenuCurrentUserProps {
	onCloseMenu: () => void;
}

interface MenuCurrentUserMobileShowState {
	showLangSwitcher: boolean;
}

class MenuCurrentUserMobileShow extends React.Component<
	MenuCurrentUserMobileShowProps & MenuDispatchProps & MenuStateProps & RouterControlsProps,
	MenuCurrentUserMobileShowState
> {
	state: MenuCurrentUserMobileShowState = {
		showLangSwitcher: false,
	};

	render() {
		const { isLoadingUser, user, signOut, onCloseMenu } = this.props;
		const lang = GeneralLangFactory();
		if (isLoadingUser) {
			return (
				<div>
					<LoadingText width="70%" />
				</div>
			);
		}

		if (!user) {
			return <div>{lang.NoAccount}</div>;
		}

		return (
			<React.Fragment>
				<div className={mergeClassNames(styles.userSettings, styles.showUserSettings)}>
					<div className={styles.mobileNavWrapper}>
						<Link style="none" className={styles.backButton} onClick={onCloseMenu}>
							<Icon name="left-open-big" size="14" /> {lang.Back}
						</Link>
						<Link style="none" className={styles.closeButton} onClick={onCloseMenu}>
							<Icon name="cancel" size="22" />
						</Link>
					</div>
					<div className={styles.loggedInAs}>
						<p className={styles.name}>{user.FirstName ? `${user.FirstName} ${user.LastName}` : lang.MyAccount}</p>
						<em className={styles.email}>{user.Email}</em>
					</div>
					<Link
						className={styles.linkItem}
						style="none"
						route={getRoute("personalSettings", { returnUrl: window.location.pathname })}
					>
						<Icon name="pencil" size="18" />
						{lang.PersonalSettings}
					</Link>
					{user.Languages.length > 1 && (
						<Link
							className={mergeClassNames(
								styles.linkItem,
								flagStyles.flag,
								flagStyles[`flag${user.Language.substring(0, 2)}`],
							)}
							style="none"
							onClick={this.toggleLangSwitcher}
						>
							<Icon name="language" size="18" />
							{lang.ChangeLanguage_action}
						</Link>
					)}
					<Link className={mergeClassNames(styles.linkItem, styles.logoutMobile)} style="none" onClick={signOut}>
						<Icon name="cancel" size="16" />
						{lang.LogOut}
					</Link>
				</div>
				<LanguageSwitcherModal visible={this.state.showLangSwitcher} onClose={this.closeLangSwitcher} />
			</React.Fragment>
		);
	}

	toggleLangSwitcher = () => {
		this.setState({
			showLangSwitcher: !this.state.showLangSwitcher,
		});
	};

	closeLangSwitcher = () => {
		this.setState({
			showLangSwitcher: false,
		});
	};
}

export default getUser.connect()(withMenuContainer(withRouterControls(MenuCurrentUserMobileShow)));
