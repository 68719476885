import { combineReducers } from "redux";

import {
	getAgencyCount,
	getAgencyStatus,
	getCompanyUser,
	getTodoStatus,
	getUser,
	getUserMemberships,
	signIn,
} from "./";

import type { Action } from "./../types";
import type { RequestsState } from "./state";
import type { ReducersMapObject } from "redux";

const reducers: ReducersMapObject<RequestsState, Action> = {
	signIn: signIn.getReducer(),
	getUser: getUser.getReducer(),
	getCompanyUser: getCompanyUser.getReducer(),
	getMemberships: getUserMemberships.getReducer(),
	getTodoStatus: getTodoStatus.getReducer(),
	getAgencyStatus: getAgencyStatus.getReducer(),
	getAgencyCount: getAgencyCount.getReducer(),
};

export default combineReducers(reducers);
