import * as React from "react";

import { Button, ButtonGroup } from "@bokio/designsystem/components/Button";
import { Heading, Paragraph, Section } from "@bokio/designsystem/components/TypographicElements/TypographicElements";
import { LinkButton } from "@bokio/elements/LinkButton/LinkButton";
import { GeneralLangFactory } from "@bokio/lang";
import { formatMessage } from "@bokio/shared/utils/format";

import * as styles from "./ratingScoreStep.scss";

const RATING_SCORES = Array.from({ length: 10 }, (_, i) => i + 1);

interface RatingScoreStepProps {
	score: number | undefined;
	onRatingScore: (score: number) => void;
	onNoRating: () => void;
	loading: boolean;
}

export const RatingScoreStep: React.FC<RatingScoreStepProps> = ({ score, onRatingScore, onNoRating, loading }) => {
	const generalLang = GeneralLangFactory();

	return (
		<Section bumpHeadingLevel>
			<Heading textCenter>{formatMessage(generalLang.NPS_Heading, <strong>Bokio</strong>)}</Heading>
			<Paragraph>
				<div className={styles.ratingList}>
					<span className={styles.label}>{generalLang.NPS_NotAtAll}</span>
					<ButtonGroup direction="row" align="center">
						{RATING_SCORES.map(s => (
							<Button
								key={s}
								onClick={() => onRatingScore(s)}
								appearance={s === score ? "primary" : "secondary"}
								disabled={loading}
								loading={s === score && loading}
								title={s.toString()}
							>
								{s}
							</Button>
						))}
					</ButtonGroup>
					<span className={styles.label}>{generalLang.NPS_VeryLikley}</span>
				</div>
			</Paragraph>
			<Paragraph>
				<LinkButton onClick={onNoRating} disabled={loading}>
					{generalLang.MaybeLater}
				</LinkButton>
			</Paragraph>
		</Section>
	);
};
