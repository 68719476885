import * as React from "react";

import { useLazyApi } from "@bokio/mobile-web-shared/hooks/useApi/useApi";
import * as proxy from "@bokio/mobile-web-shared/services/api/proxy";
import { useCompanyUser } from "@bokio/shared/state/requests";

import { bbaMigrationContext } from "./BbaMigrationContext";

import type * as m from "@bokio/mobile-web-shared/core/model/model";

type BbaMigrationStateDto = m.Bokio.Bank.Contract.BbaMigration.BbaMigrationStateDto;

export const BbaMigrationContextProvider: React.FC = ({ children }) => {
	const { company, companyUserPermissions } = useCompanyUser();
	const cacheStateRef = React.useRef<BbaMigrationStateDto>();

	const [execGetBbaMigrationState, bbaMigrationStateRequest] = useLazyApi(
		proxy.Bank.BbaMigrationController.GetBbaMigrationState.Get,
		{
			onSuccess: newState => {
				cacheStateRef.current = newState;
			},
			onError: () => {
				cacheStateRef.current = undefined;
			},
			onApiError: () => {
				cacheStateRef.current = undefined;
			},
		},
	);

	const refreshBbaMigrationState = React.useCallback(() => {
		if (!company?.Id || !companyUserPermissions?.CompanySettings) {
			return;
		}

		execGetBbaMigrationState(company.Id);
	}, [company?.Id, companyUserPermissions?.CompanySettings, execGetBbaMigrationState]);

	React.useEffect(() => {
		refreshBbaMigrationState();
	}, [refreshBbaMigrationState]);

	return (
		<bbaMigrationContext.Provider
			value={{
				// Fallback to cache to prevent the UI flash too much,
				// for the UIs that care about loading state e.g. need to disable buttons, they should look at bbaMigrationStateIsLoading.
				bbaMigrationState: bbaMigrationStateRequest.data?.Data ?? cacheStateRef.current,
				bbaMigrationStateIsLoading: !bbaMigrationStateRequest.data,
				refreshBbaMigrationState,
			}}
		>
			{children}
		</bbaMigrationContext.Provider>
	);
};
