import * as React from "react";

import { mergeClassNames } from "@bokio/utils/classes";

import * as styles from "./table.scss";

type visibilityTypes =
	| "visibleM"
	| "visibleLM"
	| "visibleTP"
	| "visibleTL"
	| "visibleDN"
	| "visibleDL"
	| "hiddenM"
	| "hiddenLM"
	| "hiddenTP"
	| "hiddenTL"
	| "hiddenDN"
	| "hiddenDL";

export interface ThProps {
	className?: string;
	colSpan?: number;
	align?: "left" | "right" | "center";
	testId?: string;
	isGroupHeading?: boolean;
	shrink?: boolean;
	/**
	 * visibleM = visible on mobile
	 * visibleLM = visible on large mobile
	 * visibleTP = visible on tablet portrait
	 * visibleTL = visible on tablet landscape
	 * visibleDN = visible on desktop normal
	 * visibleDL = visible on desktop large
	 */
	visibility?: visibilityTypes[];
}

class Th extends React.Component<ThProps> {
	render() {
		const { colSpan, isGroupHeading, className, align, shrink, visibility, testId } = this.props;
		const visibilityClasses = visibility ? visibility.map(v => styles[v]) : [];
		return (
			<th
				colSpan={colSpan}
				className={mergeClassNames(
					isGroupHeading ? styles.thGroupHeading : styles.th,
					className,
					align && styles[align],
					shrink && styles.shrink,
					...visibilityClasses,
				)}
				data-testid={testId}
			>
				{this.props.children}
			</th>
		);
	}
}

export default Th;
