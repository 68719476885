import type { State } from "../state";

export const isMenuExpanded = (state: State): boolean => state.layout.menuExpanded;

export const isHelpOpened = (state: State): boolean => state.layout.helpOpened;

export const getCurrentModal = (state: State): string | undefined =>
	state.layout.openModals.length === 0 ? undefined : state.layout.openModals[state.layout.openModals.length - 1];

export const getCurrentExternalLink = (state: State): string | undefined => state.layout.loadingExternalLink;
