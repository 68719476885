import * as React from "react";

import { theme } from "@bokio/designsystem/theme";

import * as styles from "./progressBar.scss";

interface ProgressBarProps {
	percent?: number;
	max?: number;
	barColor?: string;
	showNumericalProgress?: boolean;
	overRidingPixelHeight?: number;
}

const getPercent = (percent: number, max: number) => {
	if (percent > max) {
		return max;
	}
	if (percent < 0) {
		return 0;
	}

	return percent;
};

export const ProgressBar: React.FC<ProgressBarProps> = ({
	percent = 0,
	max = 100,
	barColor = theme.color.primary[500],
	showNumericalProgress = true,
	overRidingPixelHeight = 0,
}) => {
	const usePercent = getPercent(percent, max);
	const width = `${Math.floor(usePercent)}%`;

	return (
		<div className={styles.progressBar}>
			{showNumericalProgress && <span data-testid="progressBarIndicator_text">{width}</span>}
			<div>
				{overRidingPixelHeight ? (
					<span style={{ height: overRidingPixelHeight }}>
						<span
							data-testid="progressBarIndicator_width"
							style={{ width: width, height: overRidingPixelHeight, backgroundColor: barColor }}
						></span>
					</span>
				) : (
					<span style={overRidingPixelHeight ? { height: overRidingPixelHeight } : undefined}>
						<span data-testid="progressBarIndicator_width" style={{ width: width, backgroundColor: barColor }}></span>
					</span>
				)}
			</div>
		</div>
	);
};
