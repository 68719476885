// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface TranslatableLanguage  {} //Marker interface for language extractor

export function translateTempImpl(key: string, neutral: string, svSE?: string, enGB?: string) {
    if (!window) {
      return neutral;
    }

    switch (window.currentLang) {
      case "en-SE":
        return neutral;
      case "sv-SE":
        return svSE || key;
      case "en-GB":
        return enGB || neutral || key;
    }
    return neutral;
  }