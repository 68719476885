import Log from "../log";
import { createRequestReducer } from "./createRequestReducer";

import type { State } from "../../state/state";
import type { Action, Reducer } from "../../state/types";
import type { ApiRequestState } from "./ApiRequestState";

export interface RequestActionTypes {
	CLEAR: string;
	EXECUTE: string;
	SUCCESS: string;
	FAILURE: string;
}

class ApiRequest<TResults, TArgs = undefined, TError = undefined> {
	public static CLEAR_ALL = "CLEAR_ALL_REQUESTS";
	public static CLEAR_USER = "CLEAR_USER_REQUESTS";
	public actions: RequestActionTypes;
	protected stateSelector: (state: State) => ApiRequestState<TResults, TArgs, TError>;
	private lastRequest?: () => Promise<TResults>;

	public static clearAll(): Action {
		return {
			type: ApiRequest.CLEAR_ALL,
		};
	}

	public static clearUser(): Action {
		return {
			type: ApiRequest.CLEAR_USER,
		};
	}

	/**
	 * Create a new API request
	 */
	public constructor(actionPrefix: string, stateSelector: (state: State) => ApiRequestState<TResults, TArgs, TError>) {
		this.stateSelector = stateSelector;
		this.actions = {
			EXECUTE: `${actionPrefix}_EXECUTE`,
			SUCCESS: `${actionPrefix}_SUCCESS`,
			FAILURE: `${actionPrefix}_FAILURE`,
			CLEAR: ApiRequest.CLEAR_ALL,
		};
	}

	/**
	 * Create action for triggering this request
	 */
	loading(args?: TArgs) {
		return {
			type: this.actions.EXECUTE,
			data: args,
		};
	}

	/**
	 * Create action for successful request
	 */
	success(response?: TResults): Action {
		return {
			type: this.actions.SUCCESS,
			data: response,
		};
	}

	/**
	 * Create action for failed request
	 */
	failure(error?: TError): Action {
		return {
			type: this.actions.FAILURE,
			data: error,
		};
	}

	/**
	 * Create a reducer
	 * @returns A request reducer
	 */
	getReducer(): Reducer<ApiRequestState<TResults, TArgs, TError>> {
		return createRequestReducer<TResults, TArgs, TError>(this.actions);
	}

	/**
	 * Whether this request is currently loading or not
	 */
	isLoading(state: State): boolean {
		return this.selector(state).isRequesting;
	}

	/**
	 * Get request error
	 */
	getError(state: State): TError | undefined {
		return this.selector(state).error;
	}

	/**
	 * Get the last given action creator parameters, if any
	 */
	getParameters(state: State): TArgs | undefined {
		return this.selector(state).params;
	}

	/**
	 * Get the request results
	 */
	getResults(state: State): TResults | undefined {
		return this.selector(state).data;
	}

	wasRequestedRecently(state: State) {
		if (this.isLoading(state)) {
			return true;
		}

		// This would be nice for caching reasons, but there were issues with Redux connect() where
		// the component would not have "componentWillReceiveProps" called enough times, while still
		// calling render (with all props). This caused issues in Payslip.tsx where the getPayslip
		// needs to be loaded first, and getEmployee using the result of getPayslip.

		// If there was a request with same parameters with in 1 sec, don't fire another one
		/*if (now - then < 1000 && isEqual(this.getParameters(state), params)) {
          return true;
        }*/

		return false;
	}

	// eslint-disable-next-line @typescript-eslint/ban-types
	performRequest(dispatch: Function, request: () => Promise<TResults>): Promise<TResults>;
	// eslint-disable-next-line @typescript-eslint/ban-types
	performRequest(dispatch: Function, args: TArgs, request: () => Promise<TResults>): Promise<TResults>;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/ban-types
	performRequest(dispatch: Function, ...args: any[]): Promise<TResults> {
		let request: () => Promise<TResults>;
		let params: TArgs | undefined;

		if (args.length === 2) {
			request = args[1];
			params = args[0];
		} else {
			request = args[0];
		}

		dispatch(this.loading(params));

		// Save the request function so that it can be replayed later
		this.lastRequest = request;

		// Perform the request and handle it's response
		return this.handleRequest(dispatch, request);
	}

	/**
	 * Silently refresh the last request, if any
	 */
	// eslint-disable-next-line @typescript-eslint/ban-types
	public async refresh(dispatch: Function, state: State) {
		if (this.lastRequest && this.selector(state).data) {
			return this.handleRequest(dispatch, this.lastRequest);
		}

		return false;
	}

	// eslint-disable-next-line @typescript-eslint/ban-types
	handleRequest(dispatch: Function, request: () => Promise<TResults>): Promise<TResults> {
		return request()
			.then(response => {
				dispatch(this.success(response));
				return response;
			})
			.catch(err => {
				Log.error(err);
				dispatch(this.failure(err));

				return err;
			});
	}

	/**
	 * Give a component access to the request state via props (redux connect)
	 */
	// todo: uncomment this later to force a connect() on every request class
	// public abstract connect();

	/**
	 * Get the request state for this API request
	 */
	protected selector(state: State): ApiRequestState<TResults, TArgs, TError> {
		return this.stateSelector(state);
	}
}

export default ApiRequest;
