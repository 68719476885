import * as React from "react";

import { useCompanyInfo } from "@bokio/mobile-web-shared/core/contexts/CompanyInfoContext/CompanyInfoContext";
import { useLazyApi } from "@bokio/mobile-web-shared/hooks/useApi/useApi";
import * as proxy from "@bokio/mobile-web-shared/services/api/proxy";

import { tutorialContext } from "./TutorialContext";

import type * as m from "@bokio/mobile-web-shared/core/model/model";

export const TutorialContextProvider: React.FC = ({ children }) => {
	const { companyInfo } = useCompanyInfo();
	const companyId = companyInfo?.Id;
	const [tutorial, setTutorial] =
		React.useState<m.Bokio.Accounting.Contract.BoookkeepingTutorial.Dtos.ActiveTutorialDto>();

	// TODO: Handle onError
	const [startBookkeepingSchool, startBookkeepingSchoolRequest] = useLazyApi(
		proxy.Accounting.BookkeepingTutorialController.StartBookkeepingTutorial.Post,
		{ onSuccess: tutorial => setTutorial(tutorial) },
	);
	const [getTutorial, getTutorialRequest] = useLazyApi(
		proxy.Accounting.BookkeepingTutorialController.GetOngoingTutorial.Get,
		{ onSuccess: tutorial => setTutorial(tutorial) },
	);

	const startTutorial = async (
		key: string,
	): Promise<m.Bokio.Accounting.Contract.BoookkeepingTutorial.Dtos.ActiveTutorialDto | undefined> => {
		if (!tutorial) {
			//ME: This feels somewhat wonky, we might be loading
			return (await startBookkeepingSchool(companyId, { TutorialKey: key })).Data;
		}
		return undefined;
	};

	const refreshTutorial = React.useCallback(async () => {
		if (companyId) {
			getTutorial(companyId);
		}
	}, [companyId, getTutorial]);

	React.useEffect(() => {
		refreshTutorial();
	}, [refreshTutorial]);

	return (
		<tutorialContext.Provider
			value={{
				startTutorial,
				currentTutorial: tutorial,
				isLoadingTutorial: startBookkeepingSchoolRequest.isLoading || getTutorialRequest.isLoading,
			}}
		>
			{children}
		</tutorialContext.Provider>
	);
};
