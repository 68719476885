import * as React from "react";

import { Modal, ModalContent, ModalFooter } from "@bokio/components/Modal";
import { Button } from "@bokio/designsystem/components/Button";
import { InputField } from "@bokio/elements/Form/InputField";
import { TextAreaField } from "@bokio/elements/Form/TextAreaField";
import { RenderRequestError } from "@bokio/elements/Loading";
import { SettingsLangFactory } from "@bokio/lang";
import GeneralLangFactory from "@bokio/lang/GeneralLangFactory";
import { useCompanyInfo } from "@bokio/mobile-web-shared/core/contexts/CompanyInfoContext/CompanyInfoContext";
import * as m from "@bokio/mobile-web-shared/core/model/model";
import { useLazyApi } from "@bokio/mobile-web-shared/hooks/useApi/useApi";
import * as proxy from "@bokio/mobile-web-shared/services/api/proxy";
import { useAgencyStatus, useCompanyUser, useUser } from "@bokio/shared/state/requests";

interface EnquiryModalProps {
	visible: boolean;
	title?: string;
	subject?: string;
	messagePlaceholder?: string;
	area?: m.Contracts.SupportFormArea;
	category?: m.Contracts.SupportFormCategory;
	sub?: m.Contracts.SupportFormSubCategory;
	onClose: (reload: boolean) => void;
	onSuccess?: () => void;
}

export const sendFeedbackEndpoint = async (params: {
	name: string;
	email: string;
	message?: string;
	subject?: string;
	area?: m.Contracts.SupportFormArea;
	category?: m.Contracts.SupportFormCategory;
	sub?: m.Contracts.SupportFormSubCategory;
	companyId: string;
}) => {
	const { name, email, message, subject, area, companyId, sub, category } = params;
	const request: m.Contracts.HelpRequest = {
		Name: name || "",
		Email: email || "",
		Subject: subject || "Feedback from Bokio web app",
		Message: message || "",
		Area: area ?? m.Contracts.SupportFormArea.Other,
		Category: category ?? m.Contracts.SupportFormCategory.NotSet,
		SubCategory: sub ?? m.Contracts.SupportFormSubCategory.NotSet,
		CurrentPage: window.location.href,
		Type: m.Contracts.HelpType.Feedback,
		ReferenceId: "",
	};
	return area === m.Contracts.SupportFormArea.BackOffice
		? proxy.BackOffice.AgencyHelpController.Create.Post(companyId, request, [])
		: proxy.Settings.HelpController.Create.Post(companyId, request, []);
};

/**
 * Sends a message through helpscout (HelpCreateRequest) for general or specific product feedback, feature enquiries etc
 *
 * If none of the optional props are filled then the modal is targeted as general-feedback
 *
 */
const EnquiryModal = (props: EnquiryModalProps) => {
	const { visible, title, subject, area, messagePlaceholder } = props;
	const generalLang = GeneralLangFactory();
	const settingsLang = SettingsLangFactory();
	const getUser = useCompanyUser();
	const { companyInfo } = useCompanyInfo();
	const getAgencyUser = useUser();
	const { agencyStatus } = useAgencyStatus();
	const [nameField, setNameField] = React.useState(
		area == m.Contracts.SupportFormArea.BackOffice
			? `${getAgencyUser.user?.FirstName} ${getAgencyUser.user?.LastName}`
			: getUser.userStatus?.Name,
	);
	const [messageField, setMessageField] = React.useState("");

	const [sendFeedback, sendFeedbackRequest] = useLazyApi(sendFeedbackEndpoint, {
		onSuccess: () => {
			setMessageField("");
			props.onClose(false);
			props.onSuccess && props.onSuccess();
		},
	});

	const handleCancelClick = () => {
		props.onClose(false);
	};

	const handleSubmitClick = () => {
		const name = nameField ?? "";
		const message = messageField;
		const email =
			area == m.Contracts.SupportFormArea.BackOffice ? agencyStatus.AgencyOwner.Email : getUser.userStatus?.Email;
		const id = area == m.Contracts.SupportFormArea.BackOffice ? agencyStatus.Id : companyInfo.Id;
		if (email) {
			sendFeedback({
				name,
				email,
				message,
				subject,
				area,
				category: props.category,
				sub: props.sub,
				companyId: id,
			});
		}
	};

	const handleNameChange = (value: string) => {
		setNameField(value);
	};

	const handleMessageChange = (value: string) => {
		setMessageField(value);
	};

	return (
		<Modal
			alwaysMounted={true}
			visible={visible}
			onClose={handleCancelClick}
			title={title || settingsLang.AddOns_Feedback_Modal_Title}
		>
			<ModalContent>
				<InputField onChange={handleNameChange} label={generalLang.YourName} value={nameField} />
				<TextAreaField
					onChange={handleMessageChange}
					label={generalLang.Message}
					value={messageField}
					placeholder={messagePlaceholder || generalLang.BookkeepingRating_Feedback_Placeholder}
					rows={7}
				/>
				<RenderRequestError request={sendFeedbackRequest} />
			</ModalContent>
			<ModalFooter>
				<Button appearance="secondary" onClick={handleCancelClick} disabled={sendFeedbackRequest.isLoading}>
					{generalLang.Cancel}
				</Button>
				<Button
					onClick={handleSubmitClick}
					appearance="primary"
					loading={sendFeedbackRequest.isLoading}
					disabled={messageField.trim() == ""}
				>
					{generalLang.Submit}
				</Button>
			</ModalFooter>
		</Modal>
	);
};

export default EnquiryModal;
