import { connect } from "react-redux";

import { getCurrentAgencyId, getCurrentCompanyId } from "../state/router/selectors";

import type { State } from "../state/state";
import type { Infer } from "./connect";

export enum ActiveOrganisationType {
	Company,
	Agency,
	User, //When the user is on personal pages
}

export interface ActiveOrganisationProps {
	activeOrganisationType: ActiveOrganisationType;
	organisationId?: string;
	organisationName?: string;
}

export const mapStateToProps = (state: State): ActiveOrganisationProps => {
	const companyId = getCurrentCompanyId(state);
	const { getCompanyUser, getAgencyStatus } = state.requests;
	if (companyId) {
		return {
			activeOrganisationType: ActiveOrganisationType.Company,
			organisationId: companyId,
			organisationName: getCompanyUser.data ? getCompanyUser.data.Company.Name : undefined,
		};
	}
	const agencyId = getCurrentAgencyId(state);
	if (agencyId) {
		return {
			activeOrganisationType: ActiveOrganisationType.Agency,
			organisationId: agencyId,
			organisationName: getAgencyStatus.data?.Data ? getAgencyStatus.data.Data.Name : undefined,
		};
	}

	return {
		activeOrganisationType: ActiveOrganisationType.User,
	};
};

// @ts-expect-error TODO MQ 2019-02-15 I don't know to fix this type's issue, using react's context
const withActiveOrganisationInfo: Infer<ActiveOrganisationProps> = connect(mapStateToProps, {});

export default withActiveOrganisationInfo;
