import * as React from "react";

import { ModalFooter } from "@bokio/components/Modal";
import { Button, ButtonGroup } from "@bokio/designsystem/components/Button";
import { BankLangFactory, GeneralLangFactory } from "@bokio/lang";
import { useCompanyInfo } from "@bokio/mobile-web-shared/core/contexts/CompanyInfoContext/CompanyInfoContext";
import { formatMessage, formatNumberCurrency } from "@bokio/shared/utils/format";

import * as styles from "./signAndPayFooter.scss";

interface SignAndPayFooterProps {
	selectedPayments: number;
	disabled: boolean;
	multipleCurrencies: boolean;
	totalSum: number;
	onSignAndPayClick: () => void;
	style: "popover" | "modal";
	isLoading: boolean;
}

export const SignAndPayFooter = ({
	selectedPayments,
	disabled,
	multipleCurrencies,
	totalSum,
	onSignAndPayClick,
	style,
	isLoading,
}: SignAndPayFooterProps) => {
	const bankLang = BankLangFactory();
	const generalLang = GeneralLangFactory();
	const { companyInfo } = useCompanyInfo();
	const domesticCurrency = companyInfo.CountryMetadata.Currency;

	const renderContent = () => (
		<>
			<div className={styles.totalSummaryContainer}>
				<div className={styles.totalItems}>
					{formatMessage(
						bankLang.SignAndPayModal_TotalSelectedPayments,
						selectedPayments,
						() => (selectedPayments > 1 ? generalLang.Menu_Articles : generalLang.Item),
						t => (multipleCurrencies ? t : null),
					)}
				</div>
				<div className={styles.totalSum} data-testid={"SignAndPay_Total"}>
					{`${multipleCurrencies ? "~" : ""}${formatNumberCurrency(totalSum, domesticCurrency)}`}
				</div>
			</div>
			<ButtonGroup stretch align="end">
				<Button
					icon="bankid-solid"
					iconAlign="right"
					onClick={onSignAndPayClick}
					testId="SignAndPayModal_Submit"
					disabled={disabled}
					loading={isLoading}
				>
					{bankLang.SignAndPayModalTitle}
				</Button>
			</ButtonGroup>
		</>
	);

	return style === "modal" ? (
		<ModalFooter>{renderContent()}</ModalFooter>
	) : (
		<div className={styles.popoverStyle}>{renderContent()}</div>
	);
};
