import * as React from "react";

import { cssVariableForSpacing } from "@bokio/designsystem/theme";
import Icon from "@bokio/elements/Icon/Icon";
import { mergeClassNames } from "@bokio/utils/classes";

import type { StandardSpacingNames } from "@bokio/designsystem/theme/theme";
import type { IconProps } from "@bokio/elements/Icon/Icon";

import * as styles from "./unorderedList.scss";

export type UnorderedListItem = string | React.ReactNode;
export type UnorderedListProps = {
	className?: string;
	gap?: StandardSpacingNames;
	iconProps?: "default" | IconProps;
	items: UnorderedListItem[];
	itemGap?: StandardSpacingNames;
	hideBullets?: boolean;
};

export const UnorderedList: React.FC<UnorderedListProps> = ({
	iconProps = "default",
	items,
	className,
	hideBullets = false,
	itemGap = "0",
}) => {
	const hideBulletsClass = hideBullets ? styles.hideBullets : "";
	const listClassName = className
		? mergeClassNames(
				styles.ul,
				className,
				iconProps !== "default" && styles.removeListStyle,
				hideBulletsClass,
				styles.useVariableMarginBottom,
			)
		: mergeClassNames(
				styles.ul,
				iconProps !== "default" && styles.removeListStyle,
				hideBulletsClass,
				styles.useVariableMarginBottom,
			);

	return (
		<ul style={cssVariableForSpacing(itemGap, "--theme-spacing")} className={listClassName}>
			{items.map((content, index) =>
				iconProps === "default" ? (
					<li key={index}>{content}</li>
				) : (
					<li key={index}>
						<Icon {...iconProps} noMargin />
						<span>{content}</span>
					</li>
				),
			)}
		</ul>
	);
};
