import type { Action } from "../types";

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace Layout {
	export const TOGGLE_MENU = "TOGGLE_MENU";
	export const TOGGLE_HELP = "TOGGLE_HELP";
	export const CLOSE_MODAL = "CLOSE_MODAL";
	export const OPEN_MODAL = "OPEN_MODAL";
	export const LOAD_EXTERNAL_LINK = "LOAD_EXTERNAL_LINK";
	export const SHOW_LOGIN = "SHOW_LOGIN";
	export const CLEAR_SHOW_LOGIN = "CLEAR_SHOW_LOGIN";

	export function toggleHelp(): Action {
		return {
			type: TOGGLE_HELP,
		};
	}

	export function closeAllModals(): Action {
		return {
			type: CLOSE_MODAL,
			data: undefined,
		};
	}

	export function closeModal(id: string): Action {
		return {
			type: CLOSE_MODAL,
			data: id,
		};
	}

	export function openModal(id: string): Action<string> {
		return {
			type: OPEN_MODAL,
			data: id,
		};
	}

	export function loadExternalLink(url: string): Action<string> {
		return {
			type: LOAD_EXTERNAL_LINK,
			data: url,
		};
	}

	export function showLogin(): Action {
		return {
			type: SHOW_LOGIN,
		};
	}

	export function clearShowLogin(): Action {
		return {
			type: CLEAR_SHOW_LOGIN,
		};
	}
}
